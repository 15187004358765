import React, { useState, useEffect } from "react";
import { useUserStore } from "../store/UserStore";
import Swal from "sweetalert2";
import Loader from "./Loader";
import { useForm } from "react-hook-form";
import { useFormStep } from "../store/FormStepStore";
import { useAuth } from "../store/Auth";
import { useRepStore } from "../store/RepresentativeStore";
import {
  base,
  states as stateUrl,
  lgs as lgsUrl,
  register_cmris_agent,
} from "../constants/urls";
import validator from "validator";
import EyeOpen from "./svg/eyeopen.svg";
import EyeClose from "./svg/eyeclose.svg";

export default function Agent() {
  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const setStep = useFormStep((state) => state.setStep);
  const getStep = useFormStep((state) => state.step);
  const [lgs, setLocalGovernments] = useState([]);
  const setAuth = useAuth((state) => state.setAuth);
  const [sameAddress, setConfirmAddress] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const userDetails = source === "rep" ? repDetailss : userDetails;
  // const setUserInfo = source === "rep" ? setRepInfo : setUserInfos;

  const handleChange = () => {};
  const [states, setStates] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  useEffect(() => {
    if (sameAddress === "yes") {
      let address =
        userDetails.registeredAddress +
        " " +
        userDetails.currentResidentialState;

      setUserInfo({
        ...userDetails,
        address: address,
      });
    } else {
      setUserInfo({
        ...userDetails,
        address: "",
      });
    }
  }, [sameAddress]);

  useEffect(() => {
    getLgs();
  }, [userDetails.currentResidentialState]);

  const submitUser = async () => {
    setIsLoading(true);
    let url = base + register_cmris_agent;
    if (!validator.isURL(url)) {
      return;
    }
    // console.log(url);
    // return;
    let data = {
      first_name: userDetails.firstName,
      middle_name: userDetails.middleName,
      last_name: userDetails.lastName,
      email: userDetails.email,
      phone: userDetails.phone,
      pulled_email: userDetails.pulledEmail,
      pulled_phone: userDetails.pulledPhone,
      pulled_address: userDetails.registeredAddress,
      current_state: userDetails.currentResidentialState,
      gender: userDetails.gender,
      current_lg: userDetails.currentResidentialLGA,
      current_address: userDetails.address,
      id_type: userDetails.idType,
      id_number: userDetails.idNumber,
      password: userDetails.password,
      confirm_password: userDetails.confirmPassword,
      operation_state: userDetails.stateOfOperation,
    };

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    let responseJson = await response.json();
    console.log(responseJson);
    switch (responseJson.statusCode) {
      case 0:
        setIsLoading(false);
        window.location = "/agent_notify";
        break;
      case 4:
        Swal.fire({
          title: "Error!",
          text: "Username or Email already in use",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsLoading(false);
        break;
      default:
        Swal.fire({
          title: "Error!!!",
          text: "Password mismatch",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsLoading(false);
    }
  };

  const getLgs = async () => {
    const url = base + lgsUrl + userDetails.currentResidentialState;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setLocalGovernments(resJson.data);
        }
      });
  };

  if (getStep < 3) {
    window.location = "/signup";
  }
  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>

      <div className="bg-gray-100 py-7">
        <div className="user-reg-cont px-7 py-7 mt-4 sm:mx-28 md:mx-16 md:px-10 lg:mx-36 lg:my-10 lg:px-16">
          <div className="text-3xl md:text-4xl text-text-basic font-bold">
            Personal Information
          </div>
          <div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="firstName"
                  className="subtitle-2 font-semibold text-secondary"
                >
                  {userDetails.profileType === "individual"
                    ? "First Name"
                    : "Organization name"}
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.firstName || ""}
                  placeholder="First Name"
                />
              </div>

              {userDetails.profileType === "individual" && (
                <>
                  <div className="md:mr-4 mt-4 col-span-1">
                    <label
                      htmlFor="middleName"
                      className="subtitle-2 font-semibold text-secondary "
                    >
                      Middle Name
                    </label>
                    <input
                      className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                      type="text"
                      readOnly
                      value={userDetails.middleName || ""}
                      placeholder="Middle Name"
                    />
                  </div>
                  <div className="md:mr-4 mt-4 col-span-1">
                    <label
                      htmlFor="lastName"
                      className="subtitle-2 font-semibold text-secondary "
                    >
                      Last Name
                    </label>
                    <input
                      className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                      type="text"
                      readOnly
                      value={userDetails.lastName || ""}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="md:mr-4 mt-4 col-span-1">
                    <label
                      htmlFor="type"
                      className="subtitle-2 font-semibold text-secondary "
                    >
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={userDetails.gender || ""}
                      disabled={true}
                      className="mt-2 w-full bg-gray-200 rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </>
              )}

              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="pulled_phone"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Phone No.
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.pulledPhone || userDetails.phone}
                  name="phone"
                  placeholder="080xxxxxxxx"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="email"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Email
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="email"
                  name="email"
                  readOnly={true}
                  //   {...register("pulled_email", { required: false })}
                  // aria-invalid={errors.r_email ? "true" : "false"}
                  value={userDetails.pulledEmail || userDetails.email}
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1 md:col-span-2 lg:col-span-3">
                <label
                  htmlFor="address"
                  className="subtitle-2 font-semibold text-secondary"
                >
                  Registered Address
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  name="address"
                  readOnly={true}
                  placeholder="Residential Address"
                  //   {...register("r_address", {
                  // required: "Address is required",
                  // minLength: {
                  //   value: 10,
                  //   message: "Address is too short",
                  // },
                  //   })}
                  value={userDetails.registeredAddress || ""}
                  onChange={handleChange}
                />
                {/* {errors.r_address ? (
                  <>
                    {errors.r_address?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        {errors.r_address.message}
                      </p>
                    )}
                    {errors.r_address?.type === "minLength" && (
                      <p role="alert" className="text-sm text-red-500">
                        {errors.r_address.message}
                      </p>
                    )}
                  </>
                ) : null} */}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="r_state"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Current State of Residence
                </label>
                <select
                  name="r_state"
                  {...register("r_state", { required: true })}
                  aria-invalid={errors.r_state ? "true" : "false"}
                  value={userDetails.currentResidentialState || ""}
                  onChange={(e) => {
                    setUserInfo({
                      ...userDetails,
                      currentResidentialState: e.target.value,
                    });
                  }}
                  className="mt-2 w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12"
                >
                  <option value="">Select State</option>
                  {states.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                {errors.r_state?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    State is required
                  </p>
                )}
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="lga"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Current LGA of Residence
                </label>
                <select
                  {...register("lga", { required: true })}
                  value={userDetails.currentResidentialLGA || ""}
                  onChange={(e) => {
                    setUserInfo({
                      ...userDetails,
                      currentResidentialLGA: e.target.value,
                    });
                  }}
                  aria-invalid={errors.r_lga ? "true" : "false"}
                  className="mt-2 w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12 text-lg"
                >
                  <option value="">Select Local Government</option>
                  {lgs?.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                {errors.r_lga?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Local Government is required
                  </p>
                )}
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="r_state"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  State of Operation
                </label>
                <select
                  name="o_state"
                  {...register("o_state", { required: true })}
                  aria-invalid={errors.o_state ? "true" : "false"}
                  value={userDetails.stateOfOperation || ""}
                  onChange={(e) => {
                    setUserInfo({
                      ...userDetails,
                      stateOfOperation: e.target.value,
                    });
                  }}
                  className="mt-2 w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12"
                >
                  <option value="">Select State</option>
                  {states.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
                {errors.r_state?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    State of operation is required
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div
                className={`${
                  sameAddress === "no"
                    ? "hidden"
                    : "block md:mr-4 mt-4 col-span-1 md:col-span-2 lg:col-span-3"
                }`}
              >
                <label
                  htmlFor="token"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Is your current address the same as registered address?
                  <div
                    onChange={(e) => {
                      setConfirmAddress(e.target.value);
                    }}
                    className="text-left"
                  >
                    <label>
                      <input
                        type="radio"
                        className="mr-2 mt-2"
                        name="the_same_address"
                        value="yes"
                        required={true}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="ml-8 mt-2 mr-2 mb-4"
                        name="the_same_address"
                        value="no"
                        required={true}
                      />
                      No
                    </label>
                  </div>
                </label>
              </div>
              {sameAddress === "no" && (
                <>
                  <div className="md:mr-4 mt-2 col-span-1 md:col-span-2 lg:col-span-3">
                    <label
                      htmlFor="address"
                      className="subtitle-2 font-semibold text-secondary "
                    >
                      Current Residential Address
                    </label>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        name="address"
                        placeholder="Current Residential Address"
                        required
                        //   {...register("r_address", {
                        // required: "Address is required",
                        // minLength: {
                        //   value: 10,
                        //   message: "Address is too short",
                        // },
                        //   })}
                        value={userDetails.address}
                        {...register("r_phone", { required: true })}
                        onChange={(e) => {
                          setUserInfo({
                            ...userDetails,
                            address: e.target.value,
                          });
                        }}
                        className="mt-2 w-[80%] rounded-lg border-2 bg-gray-100 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      />
                      <button
                        className="bg-blue-500 rounded-lg mb-6 h-12 text-center w-[20%] cursor-pointer mt-2 text-gray-100 text-[12px] md:text-[16px] lg:text-lg ml-2"
                        onClick={() => setConfirmAddress("")}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="password"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Create Login Password
                </label>
                <div className="flex justify-between mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3">
                  <input
                    value={userDetails.password}
                    type={showPassword ? "text" : "password"}
                    className="bg-field text-sm w-3/4"
                    placeholder="Enter your password"
                    {...register("password", { required: true })}
                    onChange={(e) => {
                      setUserInfo({
                        ...userDetails,
                        password: e.target.value,
                      });
                    }}
                    aria-invalid={errors.password ? "true" : "false"}
                  />
                  {showPassword ? (
                    <img
                      src={EyeClose}
                      onClick={() => {
                        setShowPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={EyeOpen}
                      onClick={() => {
                        setShowPassword(true);
                      }}
                    />
                  )}
                </div>
                {errors.password?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Password is required
                  </p>
                )}
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor=""
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Confirm Login Password
                </label>
                <div className="flex justify-between mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3">
                  <input
                    value={userDetails.confirmPassword}
                    type={showPassword ? "text" : "password"}
                    className="bg-field text-sm w-3/4"
                    placeholder="Confirm your password"
                    {...register("confirmPassword", { required: true })}
                    onChange={(e) => {
                      setUserInfo({
                        ...userDetails,
                        confirmPassword: e.target.value,
                      });
                    }}
                    aria-invalid={errors.password ? "true" : "false"}
                  />
                  {showPassword ? (
                    <img
                      src={EyeClose}
                      onClick={() => {
                        setShowPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={EyeOpen}
                      onClick={() => {
                        setShowPassword(true);
                      }}
                    />
                  )}
                </div>
                {errors.confirmPassword?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Confirm your password
                  </p>
                )}
              </div>
            </div>
            <div className="flex mt-3">
              <div className="flex-1 w-1/2">
                {/* <button
                  className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                  onClick={() => {
                    setIsLoading(true);
                    setStep(getStep - 1);
                    setIsLoading(false);
                  }}
                >
                  {!isLoading && "<< Previous"}
                  {isLoading && <Loader />}
                </button> */}
              </div>
              <div className="flow-left md:mr-4">
                <button
                  disabled={userDetails.verified === 1 ? false : true}
                  className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                  onClick={handleSubmit(submitUser)}
                >
                  {!isLoading && "Submit >>"}
                  {isLoading && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
