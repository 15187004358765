import React, { useState, useEffect } from "react";
import { useAuth } from "../store/Auth";
import Loader from "./Loader";
import { useProductStore } from "../store/ProductStore";
import { useFormStep } from "../store/FormStepStore";
import {
  base,
  services_pull,
  edit_vehicle_by_id,
  get_my_vehicles,
} from "../constants/urls";
import Swal from "sweetalert2";
import { useVehicleDetails } from "../store/VehicleStore";
import { checkToken } from "./CheckToken";

export default function AgentTokenConfirm() {
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const getAuth = useAuth((state) => state.auth);
  const getProduct = useProductStore((state) => state.product);
  const selectProduct = useProductStore((state) => state.selectProduct);
  const [loading, setIsloading] = useState(false);
  const resetProduct = useProductStore((state) => state.reset);
  const [userToken, setUserToken] = useState("");

  const setVehicleDetails = useVehicleDetails(
    (state) => state.setVehicleDetails
  );

  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);

  const [viewVehicleDetails, setViewVehicleDetails] = useState(false);

  let token = getAuth.token;

  const verifyVehicle = async (userToken) => {
    setIsloading(true);
    checkToken(userToken);
  };

  const onContinue = async (e, userToken) => {
    e.preventDefault();
    await verifyVehicle(userToken);
    setStep(5);
    setViewVehicleDetails(true);
    setIsloading(false);
  };

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckboxChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const confirmDetails = (e) => {
    e.preventDefault();
    switch (getProduct) {
      case "cow":
        window.location = "/changeofownership";
        break;
      case "cop":
        window.location = "/changeofplate";
        break;
      case "coch":
        window.location = "/changeofchassis";
        break;
      case "coc":
        window.location = "/changeofcolor";
      case "tint":
        window.location = "/tint";
      default:
        setIsloading(true);
    }
  };

  if (
    getAuth.token === undefined ||
    getAuth.token === "" ||
    getAuth.authenticated === false
  ) {
    window.location = "/login";
    return;
  }

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div
        className={`lg:pt-8 pt-4 bg-gray-200 ${
          viewVehicleDetails ? "h-max" : "h-screen"
        }`}
      >
        <div className="max-w-lg mx-auto items-center">
          <button
            onClick={() => {
              window.history.back();
            }}
            className={`bg-blue-500 ml-4 flex items-center rounded-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-1 py-1`}
          >
            {"<"} Back
          </button>
          <form className="px-10 mx-4 py-12 rounded-lg shadow-lg backdrop-blur-xl bg-white/50">
            <div className="text-center text-xl pb-2 font-bold border-b-2">
              Verify Token
            </div>

            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              Enter Token Sent to User
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <input
              type="text"
              name="token"
              id="token"
              value={userToken}
              onChange={(e) => {
                setUserToken(e.target.value);
              }}
              className="w-full rounded-lg py-2 px-2 border"
            />
            {!userToken && (
              <div className="text-xs text-red-500">enter user token</div>
            )}
            <button
              onClick={(e) => {
                if (!userToken) {
                  e.preventDefault();
                  return;
                } else {
                  onContinue(e, userToken);
                }
              }}
              className="bg-blue-500 w-full py-2 font-bold mt-8 rounded-lg text-gray-100 text-md"
            >
              {!loading && "Continue"}
              {loading && <Loader />}
            </button>
            {viewVehicleDetails === true && (
              <>
                <div className="mt-5 flex justify-center">
                  <table id="reconTable">
                    <thead>
                      <tr className="font-bold">
                        <td>Property</td>
                        <td>Value</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(vehicleDetails).map(
                        ([key, value]) =>
                          key !== "id" && (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>{value}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mt-5">
                  <label className="text-red-500">
                    <input
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      checked={isConfirmed}
                      className="mr-2"
                    />
                    Confirm details
                  </label>
                </div>
                <div className="flex flex-row gap-5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.history.back();
                    }}
                    className="border border-blue-500 w-full h-12 font-bold mt-8 rounded-lg text-blue-500 text-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => confirmDetails(e)}
                    disabled={!isConfirmed}
                    className="bg-blue-500 w-full h-12 font-bold mt-8 rounded-lg text-gray-100 text-md"
                  >
                    {!loading && "Continue"}
                    {loading && <Loader />}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
