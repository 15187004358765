import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  agentData: {
    id: "",
    agent: "",
  },
  SetAgentData: (agentData) =>
    set(
      produce((temp) => {
        temp.agentData = {
          id: agentData.id,
          agent: agentData.agent,
        };
      })
    ),

  reset: (agentData) => set(agentData),
});
// store = devtools(store);
store = persist(store, { name: "agent" });
export const useAgent = create(store);
