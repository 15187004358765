import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAuth } from "../store/Auth";
import { useUserStore } from "../store/UserStore";
import { base, logout_cmris_agent } from "../constants/urls";
import { useVehicleDetails } from "../store/VehicleStore";
import { useProductStore } from "../store/ProductStore";
import { useOwnerStore } from "../store/OwnerStore";
import { useFormStep } from "../store/FormStepStore";
import { useReportDetails } from "../store/ReportStore";
import { useInvoiceStore } from "../store/InvoiceStore";

import { RiArrowDropDownLine } from "react-icons/ri";
import { useCompanyVehicleDetails } from "../store/CompanyVehicleStore";

export default function NavBar() {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const getAuth = useAuth((state) => state.auth);
  const setAuth = useAuth((state) => state.setAuth);
  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);

  //reset all states
  const resetAuth = useAuth((state) => state.reset);
  const resetUserDetails = useUserStore((state) => state.reset);
  const resetVehicleDetails = useVehicleDetails((state) => state.reset);
  const resetProductDetails = useProductStore((state) => state.reset);
  const resetOwnerDetails = useOwnerStore((state) => state.reset);
  const resetFormStep = useFormStep((state) => state.reset);
  const resetReportDetails = useReportDetails((state) => state.reset);
  const resetInvoice = useInvoiceStore((state) => state.reset);
  const clearVehicles = useCompanyVehicleDetails(
    (state) => state.clearAllItems
  );

  const env = process.env.REACT_APP_ENV;
  const openMenu = () => {
    setOpenMobileNav(!openMobileNav);
  };

  const Logout = async () => {
    let token = getAuth.token;
    let url = base + logout_cmris_agent;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        console.log("Logged Out");
        resetUserDetails();
        resetVehicleDetails();
        resetProductDetails();
        resetOwnerDetails();
        resetFormStep();
        resetReportDetails();
        resetAuth();
        resetInvoice();
        clearVehicles();
        window.location = "/";
      });
  };

  let first_letter = userDetails?.firstName?.slice(0, 1).toUpperCase();
  let others = userDetails?.firstName?.slice(1).toLowerCase();
  let first_name = first_letter + others;

  let last_letter = userDetails?.lastName?.slice(0, 1).toUpperCase();
  let others_letters = userDetails?.lastName?.slice(1).toLowerCase();
  let last_name = last_letter + others_letters;

  return (
    <div className="print:hidden sticky top-0 z-50">
      <nav className="bg-gray-700 px-4 lg:px-16 py-2 w-full">
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center md:w-2/4">
            <div className="flex items-center text-white">
              <a href="/">
                <img
                  src="/logo.png"
                  alt="Police Logo"
                  className="h-16 w-auto"
                />
              </a>
              <div className="font-semibold mt-1 ml-2 md:ml-4 text-gray-100">
                <a className="" href="/">
                  <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                    Nigeria Police Force
                  </p>
                  <p className="text-sm lg:text-3xl">
                    {" "}
                    Central Motor Registry (CMR)
                  </p>
                </a>
              </div>
            </div>
          </div>

          {/* Mobile navbar */}

          {/* headless mobile navmenu */}

          <Menu className="flex md:hidden">
            <div className="relative">
              <Menu.Button className="flex items-center px-3 py-2 text-white">
                <AiOutlineMenu className="w-6 h-6" />
              </Menu.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items>
                  <div className="grid absolute right-0 z-10 w-52 h-auto sm:w-96 px-2 py-4 mt-1 text-gray-600 bg-white border rounded-md shadow">
                    <Menu.Item>
                      <a
                        href="/"
                        className="flex items-center space-x-3 px-3 py-2.5 text-md hover:text-purple-500"
                      >
                        Home
                      </a>
                    </Menu.Item>
                    <hr />

                    <Menu.Item>
                      <a
                        href="/about"
                        className="flex items-center space-x-3 px-3 py-2.5 text-md hover:text-purple-500"
                      >
                        About
                      </a>
                    </Menu.Item>

                    <Menu.Item>
                      <a
                        href="/contact"
                        className="flex items-center space-x-3 px-3 py-2.5 text-md hover:text-purple-500"
                      >
                        Contact
                      </a>
                    </Menu.Item>
                    {getAuth.authenticated === false &&
                      getAuth.token === "" && (
                        <Menu.Item className="my-2 w-full">
                          <>
                            <a
                              href="/signup"
                              className="bg-blue-500 hover:bg-blue-700 px-2 py-2 mx-3 my-2 rounded text-md text-white"
                            >
                              Get Started
                            </a>
                          </>
                        </Menu.Item>
                      )}
                    {getAuth.authenticated === true && getAuth.token !== "" && (
                      <>
                        <Menu>
                          <div className="relative">
                            <Menu.Button className="flex w-full items-center text-gray-600  focus:outline-none bg-gray-200 py-2 px-2 rounded-xl">
                              <div className="text-md ml-2 font-semibold">
                                {`Hello, ${first_name}`}
                              </div>
                              <RiArrowDropDownLine className="w-5 h-5" />
                            </Menu.Button>
                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            ></Transition>
                            <Menu.Items>
                              <div className="absolute right-0 z-10 w-48 px-2 py-1 mt-1 text-gray-700 bg-white border rounded-md shadow">
                                <Menu.Item>
                                  <a
                                    href="/requests"
                                    className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                                  >
                                    Requests
                                  </a>
                                </Menu.Item>
                                <hr />
                                <Menu.Item>
                                  <a
                                    href="/profile"
                                    className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                                  >
                                    Profile
                                  </a>
                                </Menu.Item>
                                <Menu.Item>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      Logout();
                                    }}
                                    className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                                  >
                                    Logout
                                  </a>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </div>
                        </Menu>
                      </>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Menu>

          {/* desktop navmenu */}
          <div className="w-2/4 text-md text-white justify-end items-center hidden md:flex">
            <a href="/" className="mr-4 hover:font-semibold">
              Home
            </a>
            <a href="/about" className="mr-4 hover:font-semibold">
              About
            </a>
            <a href="/contact" className="mr-4 hover:font-semibold">
              Contact
            </a>

            {getAuth.authenticated === false && getAuth.token === "" && (
              <>
                <a
                  href="/signup"
                  className="w-28 bg-blue-500 hover:bg-blue-700 px-4 flex no-wrap py-2 rounded text-white"
                >
                  Get Started
                </a>
              </>
            )}

            {getAuth.authenticated === true && getAuth.token !== "" && (
              <>
                <Menu className="">
                  <div className="relative">
                    <Menu.Button className="flex  justify-end items-center text-gray-600  focus:outline-none bg-gray-200 py-2 px-2 rounded-xl">
                      <div className="text-md ml-2 font-semibold">
                        {`Hello, ${first_name}`}
                        {/* {`Hello, oluwatobiloba`} */}
                      </div>
                      <RiArrowDropDownLine className="w-5 h-5" />
                    </Menu.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    ></Transition>
                    <Menu.Items>
                      <div className="absolute right-0 z-10 w-48 px-2 py-1 mt-1 text-gray-700 bg-white border rounded-md shadow">
                        <Menu.Item>
                          <a
                            href="/requests"
                            className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                          >
                            Requests
                          </a>
                        </Menu.Item>
                        <hr />
                        <Menu.Item>
                          <a
                            href="/profile"
                            className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                          >
                            Profile
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <a
                            href="#"
                            onClick={() => {
                              Logout();
                            }}
                            className="flex items-center space-x-3 px-3 py-2.5 text-md hover:bg-gray-200 hover:rounded"
                          >
                            Logout
                          </a>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </div>
                </Menu>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
