import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  auth: {
    token: "",
    id: "",
  },
  setUser: (auth) =>
    set(
      produce((temp) => {
        temp.auth = {
          token: auth.token,
          id: auth.id,
        };
      })
    ),
  otpChannel: "",
  setOtpChannel: (channel) =>
    set(
      produce((temp) => {
        temp.otpChannel = channel;
      })
    ),

  userDetails: {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    idType: "",
    address: "",
    registeredAddress: "",
    pulledEmail: "",
    pulledPhone: "",
    dateOfBirth: "",
    email: "",
    phone: "",
    profileType: "",
    idNumber: "",
    currentResidentialState: "",
    currentResidentialLGA: "",
    password: "",
    confirmPassword: "",
    verified: 0,
    registered_by: "",
    agent_Id: "",
    stateOfOperation: "",
    accountTier: "3",
    superAgent: ""
  },

  setUserDetails: (user_details) =>
    set(
      produce((temp) => {
        temp.userDetails = {
          firstName: user_details.firstName,
          middleName: user_details.middleName,
          lastName: user_details.lastName,
          email: user_details.email,
          phone: user_details.phone,
          password: user_details.password,
          profileType: user_details.profileType,
          address: user_details.address,
          registeredAddress: user_details.registeredAddress,
          idType: user_details.idType,
          idNumber: user_details.idNumber,
          verified: user_details.verified,
          gender: user_details.gender,
          dateOfBirth: user_details.dateOfBirth,
          pulledPhone: user_details.pulledPhone,
          pulledEmail: user_details.pulledEmail,
          currentResidentialState: user_details.currentResidentialState,
          currentResidentialLGA: user_details.currentResidentialLGA,
          confirmPassword: user_details.confirmPassword,
          stateOfOperation: user_details.stateOfOperation,
          accountTier: user_details.accountTier,
          superAgent: user_details.superAgent
        };
      })
    ),

  reset: (userDetails) => set(userDetails),
});

// store = devtools(store);
store = persist(store, { name: "userDetails" });
export const useUserStore = create(store);
