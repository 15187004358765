import React from "react";
import User from "../components/Agent";

export default function AgentProfile() {
  return (
    <>
      <User />
    </>
  );
}
