import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  otpChannel: "",
  setOtpChannel: (channel) =>
    set(
      produce((temp) => {
        temp.otpChannel = channel;
      })
    ),

  repDetails: {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    idType: "",
    address: "",
    registeredAddress: "",
    pulledEmail: "",
    pulledPhone: "",
    dateOfBirth: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    profileType: "",
    idNumber: "",
    currentResidentialState: "",
    currentResidentialLGA: "",
    verified: 0,
  },

  setRepDetails: (rep_details) =>
    set(
      produce((temp) => {
        temp.repDetails = {
          firstName: rep_details.firstName,
          middleName: rep_details.middleName,
          lastName: rep_details.lastName,
          email: rep_details.email,
          phone: rep_details.phone,
          password: rep_details.password,
          confirmPassword: rep_details.confirmPassword,
          profileType: rep_details.profileType,
          address: rep_details.address,
          registeredAddress: rep_details.registeredAddress,
          idType: rep_details.idType,
          idNumber: rep_details.idNumber,
          verified: rep_details.verified,
          gender: rep_details.gender,
          dateOfBirth: rep_details.dateOfBirth,
          pulledPhone: rep_details.pulledPhone,
          pulledEmail: rep_details.pulledEmail,
          currentResidentialState: rep_details.currentResidentialState,
          currentResidentialLGA: rep_details.currentResidentialLGA,
        };
      })
    ),

  reset: (repDetails) => set(repDetails),
});

// store = devtools(store);
store = persist(store, { name: "repDetails" });
export const useRepStore = create(store);
