import React, { useState, useEffect } from "react";
import { useAuth } from "../store/Auth";
import Loader from "./Loader";
import { useProductStore } from "../store/ProductStore";
import { useFormStep } from "../store/FormStepStore";
import {
  base,
  services_pull,
  agent_search_vehicle,
  get_my_vehicles,
} from "../constants/urls";
import Swal from "sweetalert2";
import { useVehicleDetails } from "../store/VehicleStore";

export default function AgentSearchVehicle() {
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const getAuth = useAuth((state) => state.auth);
  const getProduct = useProductStore((state) => state.product);
  const selectProduct = useProductStore((state) => state.selectProduct);
  const [loading, setIsloading] = useState(false);
  const resetProduct = useProductStore((state) => state.reset);
  const [vehicleId, setVehicleId] = useState("");
  const [userLicensePlate, setUserLicensePlate] = useState("");
  const [userIdNumber, setUserIdNumber] = useState("");

  const setVehicleDetails = useVehicleDetails(
    (state) => state.setVehicleDetails
  );

  const getVehicleDetails = useVehicleDetails((state) => state.vehicleDetails);

  let token = getAuth.token;

  const verifyVehicle = async (userIdNumber, userLicensePlate) => {
    // let url = base + agent_search_vehicle + `${id}`;
    let url = `${
      base + agent_search_vehicle + "?"
    }id_number=${userIdNumber}&license=${userLicensePlate}`;
    setIsloading(true);
    console.log(url);
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((jsonResp) => {
        console.log(jsonResp.data);
        if (jsonResp?.message == "Error") {
          Swal.fire("Error Searching Details");
        }
        setVehicleDetails({
          id: jsonResp?.data?.owner_id,
          licencePlate: jsonResp?.data?.license_plate,
          chassisNumber: jsonResp?.data?.chassis_number,
          engineNumber: jsonResp?.data?.engine_number,
          model: jsonResp?.data?.model,
          manufacturer: jsonResp?.data?.manufacturer,
          year: jsonResp?.data?.year,
          vehicleType: jsonResp?.data?.vehicle_type,
          color: jsonResp?.data?.color,
          vehicleUsage: jsonResp?.data?.vehicle_usage,
          vehicleStatus: jsonResp?.data?.vehicle_status,
          mla: jsonResp?.data?.mla,
          mlaAddress: jsonResp?.data?.mla_address,
          sourceOfVehicle: jsonResp?.data?.source_of_vehicle,
        });
        Swal.fire({
          title: "Success!",
          text: "Retrieved User Details, Click to Verify token",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        }).then(() => (window.location.href = "/agentconfirmtoken"));
        setIsloading(false);
        return Promise.resolve(jsonResp);
      })
      .catch((err) => {
        let message;
        console.log(err);
        if (err && err.response) {
          const { data } = err.response;
          message = data.message;
        }

        Swal.fire(message || "Error Searching Details");
        setIsloading(false);
        return Promise.reject(err);
      });
  };

  const onContinue = async (e, userIdNumber, userLicensePlate) => {
    e.preventDefault();
    console.log(userIdNumber, userLicensePlate);
    await verifyVehicle(userIdNumber, userLicensePlate);
  };

  if (
    getAuth.token === undefined ||
    getAuth.token === "" ||
    getAuth.authenticated === false
  ) {
    window.location = "/login";
    return;
  }

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="lg:pt-8 pt-4 bg-gray-200 h-screen">
        <div className="max-w-lg mx-auto items-center">
          <button
            onClick={() => {
              window.history.back();
            }}
            className={`bg-blue-500 ml-4 flex items-center rounded-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-1 py-1`}
          >
            {"<"} Back
          </button>
          <form className="px-10 mx-4 py-12 rounded-lg shadow-lg backdrop-blur-xl bg-white/50">
            <div className="text-center text-xl pb-2 font-bold border-b-2">
              Search User Vehicle Details
            </div>
            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              Enter User NIN
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <input
              type="text"
              name="id_number"
              id="id_number"
              value={userIdNumber}
              onChange={(e) => {
                setUserIdNumber(e.target.value);
              }}
              className="w-full rounded-lg py-2 px-2 border"
            />
            {!userIdNumber && (
              <div className="text-xs text-red-500">NIN is Required</div>
            )}
            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              Enter Vehicle License Plate Number
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <input
              type="text"
              name="license"
              id="license"
              value={userLicensePlate}
              onChange={(e) => {
                setUserLicensePlate(e.target.value.toUpperCase());
              }}
              className="w-full rounded-lg py-2 px-2 border"
            />
            {!userLicensePlate && (
              <div className="text-xs text-red-500">License is Required</div>
            )}
            <button
              disabled={loading ? true : false}
              onClick={(e) => {
                if (!userLicensePlate || !userIdNumber) {
                  e.preventDefault();
                  return;
                } else {
                  onContinue(e, userIdNumber, userLicensePlate);
                }
              }}
              className="bg-blue-500 w-full py-2 font-bold mt-8 rounded-lg text-gray-100 text-md"
            >
              {!loading && "Continue"}
              {loading && <Loader />}
            </button>
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
