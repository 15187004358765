import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  step: 0,
  setStep: (step) =>
    set(
      produce((temp) => {
        temp.step = step;
      })
    ),
  reset: (step) => set(step),
});
// store = devtools(store);
store = persist(store, { name: "formStep" });
export const useFormStep = create(store);
