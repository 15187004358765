import React, { useState } from "react";
import { useModal } from "../store/ModalState";
import { useParams } from "react-router-dom";
import { useFormStep } from "../store/FormStepStore";
import { useUserStore } from "../store/UserStore";
import { mask_number } from "../utils/mask_number";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
import Swal from "sweetalert2";
import sendToken from "./sendToken";
import validator from "validator";
import axios from "axios";
import { useRepStore } from "../store/RepresentativeStore";
import { useProductStore } from "../store/ProductStore";
import { generate_trans_ref } from "../utils/generate_trans_ref";
import { useVehicleDetails } from "../store/VehicleStore";
import { useAgent } from "../store/AgentStore";
import { formatDate } from "../utils/date_format";
import { useInvoiceStore } from "../store/InvoiceStore";
import { useAuth } from "../store/Auth";
// import { sendToken } from "./sendToken";

import {
  states as stateUrl,
  base,
  confirm_token,
  change_of_ownership,
  cow_file_upload,
  get_product_details,
} from "../constants/urls";
import { useEffect } from "react";

export default function CowTokenVerification({
  response,
  showModal,
  setShowModal,
  newOwner,
  requestState,
}) {
  const { source } = useParams();
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const userDetail = useUserStore((state) => state.userDetails);
  const repDetails = useRepStore((state) => state.repDetails);
  const getAuth = useAuth((state) => state.auth);

  const [inputedToken, setInputedToken] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  // const setOtpChannel = useUserStore((state) => state.setOtpChannel);
  const getOtpChannel = useUserStore((state) => state.otpChannel);
  const [ready, setReady] = useState(false);
  const getProduct = useProductStore((state) => state.product);
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  // const [NewOwnerId, SetNewOwnerId] = useState("");
  const getAgent = useAgent((state) => state.agentData);
  const [request_state, setRequestState] = useState("");
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
  const [document_title, setDocumentTitle] = useState("affidavit");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onConfirmToken = (e) => {
    e.preventDefault();
    setIsVerifying(true);
    let confirmTokenUrl =
      base + confirm_token + response.phone + "/" + inputedToken;
    if (!validator.isURL(confirmTokenUrl)) {
      return;
    }
    axios({
      method: "get",
      url: confirmTokenUrl,
    }).then((resp) => {
      if (resp.status === 200) {
        if (resp.data.statusCode === 0) {
          setIsVerifying(false);
          Swal.fire({
            title: "",
            text: "Token Confirmed",
            icon: "success",
            confirmButtonText: "Continue",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            setReady(true);
          });
        } else {
          setIsVerifying(false);
          Swal.fire({
            title: "Error!",
            text: "Token Confirmation failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Error Confirming Token. Please Regenerate.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsVerifying(false);
      }
    });
  };

  useEffect(() => {
    let token = getAuth.token;
    if (ready) {
      let cowUrl = base + change_of_ownership;
      let prodUrl = base + get_product_details + getProduct;
      let fileUrl = base + cow_file_upload;

      //get the product details first
      setIsVerifying(true)
      fetch(prodUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((resp) => {
          let respData = resp.data;
          let referenceNumber =
            respData.product_code + generate_trans_ref().toString();

          let data = {
            vehicle_id: vehicleDetails.id,
            new_owner_id: newOwner,
            reference: referenceNumber,
            registered_by: getAgent.agent,
            agent_id: getAgent.id,
            amount: respData.price,
            payment_status: "Unpaid",
            request_state: requestState,
          };
          console.log(data);
          fetch(cowUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((jsonResp) => {
              console.log(jsonResp);
              if (jsonResp.statusCode == "0") {
                let cowId = jsonResp.data.COWId;
                let responseData = jsonResp.data;
                let invoice = responseData.invoice;
                setInvoice({
                  firstName: invoice.first_name,
                  middleName: invoice.middle_name,
                  lastName: invoice.last_name,
                  address: invoice.address,
                  email: invoice.email,
                  phone: invoice.phone,
                  narration: invoice.narration,
                  amount: invoice.amount,
                  transactionId: invoice.trans_ref,
                  invoiceNumber: invoice.invoice_number,
                  initiatedDate: formatDate(invoice.initiated_date),
                  status: invoice.status,
                  productCode: invoice.product_code,
                  rrr: invoice.rrr,
                  vin: invoice.vin,
                  make: invoice.make,
                  licenceNumber: invoice.license,
                });
                setStep(6);
                console.log(invoice.amount + ".00");
                const formData = new FormData();
                formData.append("id", cowId);
                formData.append("document_title", document_title);
                // formData.append("cow_document", document);
                const cow_document =
                  window.document.getElementById("cow_document");
                formData.append(
                  "cow_document",
                  cow_document?.files[0],
                  document
                );
                fetch(fileUrl, {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                  body: formData,
                })
                  .then((resp) => {
                    console.log("File upload response: ", resp);
                    return resp.json();
                  })
                  .then((respJson) => {
                    window.location = "/invoice";
                    setIsVerifying(false)
                  })
                  .catch((err) => {
                    console.log(err);
                    Swal.fire("Error Creating Transaction")
                    setIsVerifying(false)
                  });
              } else if (jsonResp.statusCode == "5") {
                Swal.fire({
                  title: "Error!",
                  text: "You already made a request to change ownership of this vehicle",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#1C89DE",
                }).then(() => {
                  window.location = "/requests";
                });
              }
            });
        });
    }
  }, [ready]);

  return (
    <div>
      <div className="backdrop-blur-lg bg-white/30 justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex justify-center text-center items-center pt-8 pb-8 bg-gray-100 px-4">
          <div>
            <div>
              <p className="mt-5 text-gray-800 text-3xl mb-4 font-bold">
                Confirm Identity
              </p>
              <p className="text-center mt-6 text-red-600 text-lg">
                Verification token sent to
                {" " + mask_number(response.phone) + " and " + response.email}
              </p>
              <label
                htmlFor="token"
                className="block text-gray-900 mt-3 text-lg"
              >
                Enter verification token
              </label>
              <input
                name="token"
                type="text"
                onChange={(e) => setInputedToken(e.target.value)}
                className=" bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
              />
              <div className="text-center text-md mt-4">
                I did not receive a token
                <span
                  onClick={() => {
                    sendToken("voice", response.phone);
                    Swal.fire({
                      title: "Successful!",
                      text: "Call initiated. Listen for the token",
                      icon: "success",
                      confirmButtonText: "Continue",
                      confirmButtonColor: "#1C89DE",
                    });
                  }}
                  className="ml-2 bg-blue-500 cursor-pointer text-gray-100 px-5 py-1 rounded-lg"
                >
                    Call Me
                  {/* {getOtpChannel === "sms" ? "Call me" : "Resend"} */}
                </span>
              </div>
              <div className="flex justify-center space-x-4 mt-4">
                <button
                  className="border border-red-500 px-4 text-lg mt-4 py-1 rounded-lg text-red-500"
                  onClick={() => setShowModal(!showModal)}
                >
                  Back
                </button>
                <button
                  onClick={(e) => {
                    onConfirmToken(e);
                  }}
                  disabled={isVerifying}
                  className="bg-blue-500 text-lg mt-4 py-1 rounded-lg text-gray-100 px-4"
                >
                  {!isVerifying && "Confirm Token"}
                  {isVerifying && <Loader />}
                </button>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
