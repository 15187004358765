import { base, checktoken } from "../constants/urls";

export const checkToken = async (token) => {
  const url = `${base}${checktoken}`;
  console.log(url);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (response.status == 200) {
      const { data, statusCode } = await response.json();
      if (statusCode == "0") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};