import React, { useState } from "react";
import validator from "validator";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import axios from "axios";
import { useRepStore } from "../store/RepresentativeStore";
import { useFormStep } from "../store/FormStepStore";
import { base, verify as verifyUrl } from "../constants/urls";
import { useOwnerStore } from "../store/OwnerStore";
import sendToken from "../components/sendToken";
import { useAuth } from "../store/Auth";

export default function CreateRepProfile() {
  const [isVerifying, setIsVerifying] = useState(false);
  const [inputs, setInputs] = useState({});
  const repDetails = useRepStore((state) => state.repDetails);
  const setRepDetails = useRepStore((state) => state.setRepDetails);
  const setStep = useFormStep((state) => state.setStep);
  const getStep = useFormStep((state) => state.step);
  const getOwner = useOwnerStore((state) => state.ownerDetails);
  const setOwnerInfo = useOwnerStore((state) => state.setOwnerDetails);
  const getAuth = useAuth((state) => state.auth);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChange = () => {};
  const [profileType, setProfileType] = useState("individual");
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const verify = (e) => {
    e.preventDefault();
    setIsVerifying(true);

    let data = {};
    if (idType === "dl") {
      data = {
        idType: idType,
        id: idNumber,
      };
    } else {
      data = {
        idType: idType,
        id: idNumber,
      };
    }

    if (idType === "" || idType === undefined) {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Identification type field is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }

    if (idNumber === undefined || idNumber === "") {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Identification number is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }
    let url = base + verifyUrl;
    if (!validator.isURL(url)) {
      return;
    }
    try {
      axios({
        method: "post",
        url: url,
        data,
      }).then((response) => {
        console.log(response);
        if (
          response.data.data.code === 5 ||
          response.data.data.code === 3 ||
          response.data.statusCode === 3
        ) {
          setIsVerifying(false);
          Swal.fire({
            title: "Error!",
            text: "Verification Failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
          return;
        } else if (response.data.statusCode === 6) {
          setIsVerifying(false);
          Swal.fire({
            title: "Profile with this ID already exists",
            text: "Click Ok to login with " + repDetails.email,
            icon: "info",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            let responseData = response.data.data;
            console.log(responseData);
            setRepDetails({
              firstName: responseData.first_name,
              middleName: responseData.middle_name,
              lastName: responseData.last_name,
              gender: responseData.gender,
              idType: responseData.id_type,
              address: responseData.current_address,
              pulledEmail: responseData.pulled_email,
              pulledPhone: responseData.pulled_phone,
              email: responseData.email,
              phone: responseData.phone,
              profileType: profileType,
              idNumber: responseData.id_number,
              verified: 1,
            });
            window.location = "/passwordreset";
          });
          return;
        } else if (response.data.data.error) {
          setIsVerifying(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong and unable to verify your ID",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
          return;
        } else {
          let responseData = response.data.data;
          if (profileType === "corporate") {
            setProfileType("corporate");
            if (idType === "tin") {
              //   sendToken("sms", phoneNumber);
              setRepDetails({
                firstName: responseData.name,
                middleName: "NA",
                lastName: "NA",
                gender: "NA",
                idType: idType,
                pulledEmail: responseData.email,
                pulledPhone: responseData.phone_number,
                pulledAddress: "NA",
                email: "NA",
                phone: "NA",
                profileType: profileType,
                idNumber: idNumber,
                registeredAddress: responseData.street_name,
                verified: 1,
              });
            } else {
              //Id type is cac
              setOwnerInfo({
                firstName: responseData.company_name,
                gender: "NA",
                pulledEmail: responseData.company_email,
                pulledPhone: "",
                phone: "",
                email: "",
                state: "NA",
                lga: "NA",
                registeredAddress: responseData.company_address,
                idType: idType,
                idNumber: idNumber,
                profileType: profileType,
              });
            }
          } else {
            if (idType === "nin") {
              setRepDetails({
                firstName: responseData.firstname,
                middleName: responseData.middlename,
                lastName: responseData.surname,
                gender: responseData.gender === "m" ? "Male" : "Female",
                idType: idType,
                address: "",
                registeredAddress:
                  responseData.residence_AdressLine1 +
                  "," +
                  responseData.residence_Town +
                  "," +
                  responseData.residence_state,
                pulledEmail: responseData.email,
                pulledPhone: responseData.telephoneno,

                dateOfBirth: "",
                email: responseData.email,
                phone: "",
                profileType: profileType,
                idNumber: idNumber,
                verified: 1,
              });
            } else if (idType === "dl") {
              //   setChannel("sms");
              //   setDestination(inputs.r_phone);
              //   sendToken("sms", phoneNumber);

              setRepDetails({
                id: responseData.id,
                firstName: responseData.first_name,
                middleName: responseData.middle_name,
                lastName: responseData.last_name,
                gender:
                  responseData.gender === "M" || responseData.gender === "m"
                    ? "Male"
                    : "Female",
                idType: idType,
                pulledPhone: responseData.phone_number,
                profileType: profileType,
                registeredAddress: responseData.address,
                idNumber: idNumber,
                verified: 1,
              });
            } else {
              //   setChannel("sms");
              //   setDestination(responseData.phone_number);
              // sendToken("sms", responseData.phone_number);
              setRepDetails({
                id: responseData.id,
                firstName: responseData.first_name,
                middleName: responseData.middle_name,
                lastName: responseData.last_name,
                gender: responseData.gender,
                idType: idType,
                profileType: profileType,
                idNumber: idNumber,
                registeredAddress: responseData.address,
                verified: 1,
              });
              console.log(repDetails);
              return;
            }
          }
          Swal.fire({
            title: "Identity Verified",
            text: " ",
            icon: "success",
            confirmButtonText: "Continue",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            window.location = "/confirmation/rep";
            setStep(1);
          });
          setIsVerifying(false);
        }
      });
    } catch (error) {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Error Verifying",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
    }
  };

  if (
    getAuth.authenticated === true ||
    getAuth.token !== "" ||
    getAuth.token !== ""
  ) {
    window.location = "/services";
  }
  return (
    <div className="">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="pt-8 bg-gray-100">
        <div>
          <form className="max-w-sm mx-auto items-center">
            <div className="border-2 border-gray-300 px-10 py-6 rounded-lg shadow-lg">
              <div className="text-center text-2xl font-bold border-b-2 pb-3">
                Representative Profile
              </div>
              <label
                htmlFor="typeOfId"
                className="block text-gray-900 text-md mt-3 font-semibold"
              >
                Identification Type{" "}
                <span className="text-red-500 text-lg font-semibold"> *</span>
              </label>
              <select
                name="r_id_type"
                {...register("r_id_type", { required: true })}
                onChange={(e) => {
                  setIdType(e.target.value);
                }}
                className="w-full mb-4 bg-white rounded-lg h-10 text-sm mt-1 hover:border-green-800 py-1 px-2 border border-gray-600"
              >
                <option value="">Select ID type</option>
                <option value="nin">
                  National Identification Number (NIN)
                </option>
              </select>
              <label
                htmlFor="idNumber"
                className="block text-gray-900 text-md font-semibold"
              >
                Identification Number
                <span className="text-red-500 text-lg font-semibold"> *</span>
              </label>
              <input
                name="r_id_number"
                type="text"
                {...register("r_id_number", { required: true })}
                onChange={(e) => {
                  setIdNumber(e.target.value);
                }}
                className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
              />

              <button
                onClick={(e) => {
                  verify(e);
                }}
                className="bg-blue-500 w-full py-2 font-bold mt-4 rounded-lg text-white  hover:bg-blue-900 text-md"
              >
                {!isVerifying && "Verify"}
                {isVerifying && <Loader />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
