import React from "react";
import Footer from "../components/Footer";

export default function About() {
  return (
    <div>
      {/* <div className="md:m-10 md:text-xl text-lg leading-10 md:w-full w-screen mx-12 md:h-full"> */}
      <div className="p-10 h-[80vh]">
        <div className="text-gray-800 mt-3 font-semibold text-2xl md:text-3xl md:mb-3">
          About Central Motor Registry Information System
        </div>
        <div className="flex">
          <div className="text-justify leading-10 flex-1 pb-5">
            <p className="mb-3">
              One of the duties of the Nigeria Police Force is to protect the
              lives and property of all persons in Nigeria. In carrying out this
              responsibility with regards to motor vehicle security, the Police
              established a Central Motor Registry (CMR) as a repository of
              information of vehicles operating on Nigerian roads. Every motor
              vehicle (cars, buses, trucks, motorcycles, etc) owner is required
              to register the motor vehicle in the Registry and issued a Motor
              Vehicle Information Certificate.
            </p>
            <p className="mb-3">
              This portal is designed to enable motor vehicle owners comply with
              regulatory requirements by providing information online about
              their vehicles, its ownership and other necessary details that
              will support the Police in maintaining information on vehicles.
              This will be very useful in the investigation of motor vehicle
              theft and recovery processes.
            </p>
          </div>
          <div className="leading-10 flex-1 ml-12 hidden lg:block">
            <img
              src="/about.jpeg"
              alt="Police Car park"
              width="600"
              height="600"
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
