import React, { useState } from "react";
import { useUserStore } from "../store/UserStore";
import { ClipLoader } from "react-spinners";
import RequestNameChange from "./RequestNameChange";
import RequestAddressChange from "./RequestAddressChange";
import { base, edit_password } from "../constants/urls";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

export default function UserProfile() {
  const userDetails = useUserStore((state) => state.userDetails);
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  // const [showChangeOfName, setShowChangeOfName] = useState(false);
  // const [showChangeOfAddress, setShowChangeOfAddress] = useState(false);
  const [passcode, setPasscode] = useState("");
  const { password } = useParams();
  const [menu, setMenu]=useState("")

  let data = {
    profile_type: userDetails.profileType,
    first_name: userDetails.firstName,
    middle_name: userDetails.middleName,
    last_name: userDetails.lastName,
    email: userDetails.email,
    phone: userDetails.phone,
    pulled_email: userDetails.pulledEmail,
    pulled_phone: userDetails.pulledPhone,
    pulled_address: userDetails.registeredAddress,
    current_state: userDetails.currentResidentialState,
    current_lg: userDetails.currentResidentialLGA,
  };

  const handleSubmit = async (e) => {
    let token = getAuth.token;
    e.preventDefault();
    try {
      fetch(base + edit_password + `${password}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(passcode),
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          setPasscode(data);
        });
    } catch (error) {
      console.log(error);
      Swal.fire("unable to update password");
    }
  };

  return (
    <div>
      {" "}
      
      <div className="pt-4">
        <div>
          <div>
            <div className="rounded-lg shadow bg-white">
              {/* <div className="flex justify-between items-center py-4 font-semibold mb-2">
                <h3 className="text-lg">CMRIS Assistant Profile</h3>
              </div> */}
              <div className="">
                {loading ? (
                  <div className="my-20 text-center">
                    <ClipLoader
                      color={"#166434"}
                      size={30}
                      aria-label="Loading Spinner"
                    />
                    <p className="mt-2 text-gray-500">Loading...</p>
                  </div>
                ) : (
                  <form>
                    <div className="pb-6">
                      <label
                        htmlFor="fullname"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Fullname
                      </label>
                      <input
                        className="
                      block           
                      w-full
                      h-auto
                      p-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                        name="name"
                        type="text"
                        value={data?.first_name + " " + data?.last_name}
                        placeholder={"full name"}
                        // onChange={handleInput}
                        disabled
                      />
                    </div>
                    <div className="pb-4">
                      <label
                        htmlFor="phonenumber"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Phone Number
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="phone"
                        type="tel"
                        value={data?.phone}
                        placeholder={"telephone"}
                      />
                    </div>
                    <div className="pb-4">
                      <label
                        htmlFor="email"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Email
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="email"
                        type="text"
                        value={data?.email}
                        placeholder={"Email"}
                      />
                    </div>
                    {/* <div className="pb-4">
                      <label
                        htmlFor="Password"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Password
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="password"
                        type="password"
                        value={data?.passcode}
                        placeholder={"Enter new password"}
                      />
                    </div> */}

                    <div className="pb-4">
                      <label
                        htmlFor="address"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Current Residential Address
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="address"
                        type="text"
                        value={data?.current_state}
                        placeholder={"Current Address"}
                      />
                    </div>
                    <div className="mt-4">
                      {/* <input onClick={handleSubmit}
                  className="w-full text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  type="submit"
                  value="Update Profile"
                  disabled
                /> */}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
