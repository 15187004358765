import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  auth: {
    agent_id: "",
    token: "",
    authenticated: false,
  },
  setAuth: (auth) =>
    set(
      produce((temp) => {
        temp.auth = {
          token: auth.token,
          authenticated: auth.authenticated,
          agent_id: auth.agent_id,
        };
      })
    ),

  reset: (auth) => set(auth),
});
// store = devtools(store);
store = persist(store, { name: "agent_auth" });
export const useAuth = create(store);
