import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  newPlateNumber: "",
  setnewPlateNumber: (newPlateNumber) =>
    set(
      produce((temp) => {
        temp.newPlateNumber = newPlateNumber;
      })
    ),
  reset: (newPlateNumber) => set(newPlateNumber),
});

store = persist(store, { name: "newPlateNumber" });
export const usenewPlateNumber = create(store);
