import React, { useEffect, useState } from "react";
import { useUserStore } from "../store/UserStore";
import { ClipLoader } from "react-spinners";
import { base, edit_password } from "../constants/urls";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import UserProfile from "../components/UserProfile";
import Account from "../components/Account";
import Earnings from "../components/Earnings";
import ChangePassword from "../components/ChangePassword";

export default function ProfilePage() {
  const userDetails = useUserStore((state) => state.userDetails);
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState("profile");
  const [showEarning, setShowEarning] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(true);

  useEffect(() => {
    if (userDetails?.accountTier == "1") {
      setShowEarning(true);
    }else if (userDetails?.accountTier == "2" && userDetails?.superAgent ==null) {
      setShowEarning(true); 
    }else{
      setShowEarning(false);
    }
  })

  return (
    <div>
      <div className="pt-4 bg-gray-100">
        <div>
          <div className="px-6 max-w-5xl mx-auto">
            <p className="p-4 rounded-lg text-lg mt-3 font-bold bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold ">
              CMR Assistant Profile
            </p>
          </div>

          <div className="max-w-5xl mx-auto bg-gray-100 w-full font-ubuntu h-screen px-6 py-8">
            <div className="rounded-lg shadow px-8 bg-white">
              <div className="flex justify-between items-center py-1 font-semibold mb-2">
                {/* <h3 className="text-lg">CMRIS Assistant Profile</h3> */}
              </div>
              <button
                onClick={() => {
                  setMenu("profile");
                }}
                className="bg-gray-400 rounded p-2 mr-2 hover:text-gray-100"
              >
                Profile
              </button>
              {/* <button onClick={()=>{setMenu("account")}} className="bg-gray-400 rounded p-2 mr-2 hover:text-gray-100">Account</button> */}
              {showChangePassword && (
                  <button
                    onClick={() => {
                      setMenu("changepassword");
                    }}
                    className="bg-gray-400 rounded p-2 hover:text-gray-100"
                  >
                    Change Password
                  </button>
                )}
              {/* <div className="flex">
                <div className="flex-1">
                    <button onClick={()=>{setMenu("profile")}} className="bg-gray-400 rounded p-2 hover:text-gray-100">Profile</button>
                </div>
                <div className="flex-1">
                    <button onClick={()=>{setMenu("account")}} className="bg-gray-400 rounded p-2 hover:text-gray-100">Account</button>
                </div>
                <div className="flex-1">
                    <button onClick={()=>{setMenu("earning")}} className="bg-gray-400 rounded p-2 hover:text-gray-100">Earnings</button>
                </div>
              </div> */}
              {menu == "profile" && <UserProfile />}
              {/* {menu=="account" && <Account />} */}
              {menu == "changepassword" && <ChangePassword />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
