import React, { useState, useEffect } from "react";
import { useOwnerStore } from "../store/OwnerStore";
import Swal from "sweetalert2";
import Loader from "./Loader";
import { useForm } from "react-hook-form";
import { useFormStep } from "../store/FormStepStore";
import { useAuth } from "../store/Auth";
import {
  base,
  states as stateUrl,
  lgs as lgsUrl,
  validate_user,
  get_new_owner_details
} from "../constants/urls";
import validator from "validator";
import InstructionModal from "./InstructionModal";
import { useModal } from "../store/ModalState";

export default function OwnerInfo() {
  // const ownerDetails = useOwnerStore((state) => state.ownerDetails);
  // const SetownerDetails = useOwnerStore((state) => state.setOwnerDetails);
  const { ownerDetails, setMultipleOwnerDetails, reset, setSingleOwnerDetails } = useOwnerStore();
  const [isLoading, setIsLoading] = useState(false);
  const setStep = useFormStep((state) => state.setStep);
  const getStep = useFormStep((state) => state.step);
  const [lgs, setLocalGovernments] = useState([]);
  const setAuth = useAuth((state) => state.setAuth);
  const getAuth = useAuth((state) => state.auth);
  const [exists, setExists] = useState(false);
  const [sameAddress, setConfirmAddress] = useState("");
  const [warning, setWarning]=useState("")
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [states, setStates] = useState([]);
  const [idNumberCharacterLength, setIdNumberCharacterLength] = useState(11)
  const showModal = useModal((state) => state.showModal);
  const setShowModal = useModal((state) => state.setShowModal);

  const handleUpdateField = (field, newValue) => {
    setSingleOwnerDetails(field, newValue);
  };

  const handleUpdateMultiple = (updatedDetails) => {
    setMultipleOwnerDetails(updatedDetails);
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    setShowModal(true);
  }, [])

  const isTitle = (value) => {
    // Common titles (Mr., Mrs., Dr. etc.)
    // const commonTitleRegex = /(^[mM][rSs]\.?|[Dd][rR]\.?|Prof\.)/i;;
    const commonTitleRegex = /^(mr|Mr|MR|mrs|Mrs|MRS|mr.|Mr.|MR.|mrs.|Mrs.|MRS.|dr|Dr|DR|dr.|Dr.|DR.|miss|Miss|MISS|miss.|Miss.|MISS.|lady|Lady|LADY|lord|Lord|LORD|Mx|MX|engr|Engr|ENGR|engr.|Engr.|ENGR.)$/i;
    // Partial list of Nigerian traditional titles (can be expanded)
    const igboTitles = /^(Obi|Eze|Igwe|Ozo|Lolo|Chief)$/i;
    const yorubaTitles = /^(Oba|Alaafin|Ooni|Iyalode|Prof)$/i;
    const hausaTitles = /^(Sarkin|Galadima|Magaji)$/i;
  
    // Religious titles
    const religiousTitles = /^(Imam|Alhaji|Mallam|Pastor|Reverend|Bishop|Deacon)$/i;
  
    // Combine all checks
    const titleRegex = new RegExp([
      commonTitleRegex.source,
      igboTitles.source,
      yorubaTitles.source,
      hausaTitles.source,
      religiousTitles.source,
    ].join('|'));
  
    return !titleRegex.test(value);
  };

  const Proceed = (e) =>{
    e.preventDefault()
    if(exists===true){
      window.location = "/services/vehicleinformation";
    }
  }

  useEffect(()=> {
    if(ownerDetails.profileType == "individual"){
      setWarning("Please Note : Vehicle owner’s name should be entered as contained in the NIN details i.e. Firstname, Middlename, Lastname. Mismatch of NIN details or using the NIN of another person will result in ID Verification failure. Titles such as Mr, Mrs, Dr, Chief, Rev, Prof, Engr, etc are not allowed.")
    }else{
      setWarning("Organization name should be the same as contained in TIN/CAC details")
    }
  }, [ownerDetails.profileType])

  const GetOwner = () => {
    let url = base + get_new_owner_details+"?use="+ownerDetails.idType + "&id=" + ownerDetails.idNumber;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
        res.json().then((resJson) => {
          // console.log(resJson)
          if (resJson.statusCode === 0) {
            const updatedDetails = {
              firstName: resJson.data.first_name,
              middleName: resJson.data.middle_name,
              lastName: resJson.data.last_name,
              email: resJson.data.email,
              phone: resJson.data.phone,
              address: resJson.data.present_address,
              state: resJson.data.state,
              lga: resJson.data.lga,
            };
            handleUpdateMultiple(updatedDetails)
            setExists(true)
          }
      });
    });
  }

  useEffect(() => {
    handleReset()
  }, []);

  useEffect(() => {
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);


  useEffect(() => {
    getLgs();
  }, [ownerDetails.currentState]);

  const SubmitOwner = async () => {
    console.log(ownerDetails);
    if (ownerDetails.idNumber == "" || ownerDetails.idNumber == undefined) {
      Swal.fire({
        title: "ID Number is missing",
        text: "",
        icon: "info",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }
    setIsLoading(true);
    if(exists===true){
      window.location = "/services/vehicleinformation";
      return
    }
    try {
      const validationResult = await validateUserInput(ownerDetails.phone, "phone");
      if (validationResult.statusCode === 0) {
          window.location = "/services/vehicleinformation";
        } else {
        // Handle validation error
        Swal.fire({
          title: "Error!",
          text: validationResult.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        })
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error during validation:", error);
      // Handle validation error
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        })
        setIsLoading(false);
    }

    // window.location = "/services/vehicleinformation";
  };

  async function validateUserInput(input, vwith) {
    if (!input && vwith==="phone") { // Check if phone number is provided
      return Promise.reject(new Error("Missing input for validation"));
    }
  
    let url = base + validate_user + "?validating_input=" + input + "&with=" + vwith;
    let token = getAuth.token;
  
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    });
    const resJson = await res.json();
    return resJson;
  }

  //   useEffect(() => {
  //     matchPassword();
  //   }, [confirmPassword]);

  //   const matchPassword = (data) => {
  //     console.log(data);
  //   };

  // const isEmpty = (obj) => {
  //   return Object.keys(obj).length === 0;
  // };
  const getLgs = async () => {
    if (ownerDetails.currentState == "") {
      setLocalGovernments([]);
      return
    }
    const url = base + lgsUrl + ownerDetails.currentState;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setLocalGovernments(resJson.data);
        }
      });
  };

  if (getStep < 3) {
    window.location = "/signup";
  }
  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL 
      </div>

      <div className="bg-gray-100 py-7">
        <div className="user-reg-cont px-7 py-7 mt-4 sm:mx-28 md:mx-16 md:px-10 lg:mx-36 lg:my-10 lg:px-16">
          <div className="text-3xl md:text-4xl text-text-basic font-bold">
            Vehicle Owner Information
          </div>
          <div className="text-red-600 font-bold">
            Please Note : {warning}
          </div>
          <div>
            <form>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="">
                <label
                  htmlFor="owner_profile_type"
                  className="font-semibold block text-gray-900 text-md font-sans"
                >
                  Owner Profile Type
                  <span className="text-red-500 text-lg font-semibold"> *</span>
                </label>
                <div
                  className="border-2 border-gray-300 rounded-lg mr-4 px-1"
                  onChange={(e) => handleUpdateField("profileType", e.target.value)}
                >
                  <label className="text-sm">
                    <input
                      type="radio"
                      className="mr-2 mb-4 mt-4"
                      name="owner_profile_type"
                      value="individual"
                      required={true}
                    />
                    Personal
                  </label>
                  <label className="text-sm">
                    <input
                      type="radio"
                      className="ml-8 mr-2 mb-4 mt-4"
                      name="owner_profile_type"
                      value="corporate"
                      required={true}
                    />
                    Organization
                  </label>
                </div>
              </div>

              <div className="mr-4">
                <label
                  htmlFor="Id type"
                  className="mt-3 lg:mt-0 font-semibold block text-gray-900 text-md font-sans"
                >
                  ID Type
                  <span className="text-red-500 text-lg font-semibold"> *</span>
                </label>
                <select
                  name="idType"
                  className="w-full bg-white rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                  {...register("id_type", { required: true })}
                  aria-invalid={errors.id_type ? "true" : "false"}

                  value={ownerDetails.idType}
                  onChange={(e) => handleUpdateField("idType", e.target.value)}
                >
                  <option value="">Select Id Type</option>
                  {ownerDetails.profileType == "individual" && (
                    <>
                    <option value="nin">National Identification Number (NIN)</option>
                    {/* <option value="dl">National Drivers Licence</option> */}
                    </>
                  )}
                  {ownerDetails.profileType == "corporate" && (
                    <option value="tin">TIN</option>
                  )}
                  {ownerDetails.profileType == "corporate" && (
                    <option value="cac">CAC</option>
                  )}
                </select>
                {errors.id_type?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Id Type is required
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="Id type"
                  className="mt-3 lg:mt-0 font-semibold block text-gray-900 text-md font-sans"
                >
                  ID Number
                  <span className="text-red-500 text-lg font-semibold"> *</span>
                </label>
                <div className="flex items-center">
                <input
                  className="flex-grow border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                  type="text"
                  // value={ownerDetails.firstName || ""}
                  placeholder="ID Number"
                  name="id_number"
                  // {...register("id_number", { required: true })}
                  aria-invalid={errors.id_number ? "true" : "false"}
                  value={ownerDetails.idNumber}
                  onChange={(e) => handleUpdateField("idNumber", e.target.value)}
                  onBlur={(e) => {
                    GetOwner();
                  }}
                />
                {isLoading && <Loader />}
                </div>
                {errors.id_number?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Id Number is required
                  </p>
                )}
              </div>
            </div>
            {/* <hr className="mt-4" /> */}
            {!exists && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="firstName"
                    className="subtitle-2 font-semibold text-secondary"
                  >
                    {ownerDetails.profileType=="individual" ? "First Name" : "Name of Organization"}
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <input
                    className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    name="first_name"
                    {...register("first_name", { required: true, validate: isTitle })}

                    aria-invalid={errors.first_name ? "true" : "false"}
                    value={ownerDetails.firstName}
                    onChange={(e) => handleUpdateField("firstName", e.target.value.toUpperCase())}
                    placeholder={ownerDetails.profileType=="individual" ? "First Name":"Name of Organization"}
                  />
                    {errors.first_name && (
                      <p role="alert" className="text-sm text-red-500">
                        Check First Name for error
                      </p>
                    )}
                </div>
                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="middleName"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Middle Name
                  </label>
                  <input
                    className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    {...register("middle_name", { required: false, validate: isTitle })}
                    aria-invalid={errors.middle_name ? "true" : "false"}
                    value={ownerDetails.middleName}
                    onChange={(e) => handleUpdateField("middleName", e.target.value.toUpperCase())}
                    placeholder="Middle Name"
                  />
                    {errors.middle_name && (
                      <p role="alert" className="text-sm text-red-500">
                        Check Middle Name for error
                      </p>
                    )}
                </div>
                )}

                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="lastName"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Last Name
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <input
                    className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    {...register("last_name", { required: ownerDetails.profileType=="individual" ? true : false, validate: isTitle })}
                    aria-invalid={errors.last_name ? "true" : "false"}
                    value={ownerDetails.lastName}
                    onChange={(e) => handleUpdateField("lastName", e.target.value.toUpperCase())}
                    placeholder="Last Name"
                  />
                    {errors.last_name && (
                      <p role="alert" className="text-sm text-red-500">
                        Check Last Name for error
                      </p>
                    )}
                </div>
                )}

                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="type"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Gender
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <select
                    name="gender"
                    {...register("o_gender", { required: true })}
                    aria-invalid={errors.o_gender ? "true" : "false"}
                    value={ownerDetails.gender}
                    onChange={(e) => handleUpdateField("gender", e.target.value)}
                    className="w-full bg-white rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {errors.o_gender?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Gender is required
                    </p>
                  )}
                </div>
                )}


                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="pulled_phone"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Phone No.
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    name="phone"
                    {...register("phone", { required: true, maxLength: 11, minLength : 11  })}
                    aria-invalid={errors.phone ? "true" : "false"}
                    value={ownerDetails.phone}
                    onChange={(e) => handleUpdateField("phone", e.target.value)}
                    placeholder="080xxxxxxxx"
                  />
                   {errors.phone && (
                    <p role="alert" className="text-sm text-red-500">
                      Check Phone number for error
                    </p>
                  )}
                </div>

                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="email"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Email
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="email"
                    name="email"
                    {...register("email", { required: false })}
                    aria-invalid={errors.email ? "true" : "false"}
                    value={ownerDetails.email}
                    onChange={(e) => handleUpdateField("email", e.target.value)}
                  />
                  {errors.email?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Email address is required
                    </p>
                  )}
                </div>
              </div>
              <div>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="r_state"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    {ownerDetails.profileType=="individual" ? "Current State of Residence" : "Current State of Business"}
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <select
                    name="r_state"
                    {...register("r_state", { required: true })}
                    aria-invalid={errors.r_state ? "true" : "false"}
                    // value={ownerDetails.currentState || ""}
                    value={ownerDetails.currentState}
                    onChange={(e) => handleUpdateField("currentState", e.target.value)}
                    className="w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12"
                  >
                    <option value="">Select State</option>
                    {states.map((val, index) => {
                      return (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                  {errors.r_state?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      State is required
                    </p>
                  )}
                </div>
                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="lga"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    {ownerDetails.profileType=="individual" ? "Current LGA of Residence" : "Current LGA of Business"}
                    <span className="text-red-500 text-lg font-semibold"> *</span>
                  </label>
                  <select
                    {...register("lga", { required: true })}
                    aria-invalid={errors.lga ? "true" : "false"}
                    value={ownerDetails.currentLG}
                    onChange={(e) => handleUpdateField("currentLG", e.target.value)}
                    className="w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12 text-lg"
                  >
                    <option value="">Select Local Government</option>
                    {lgs?.map((val, index) => {
                      return (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                  {errors.lga?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Local Government is required
                    </p>
                  )}
                </div>
                    <div className="md:mr-4 mt-2 col-span-1 md:col-span-2 lg:col-span-3">
                      <label
                        htmlFor="address"
                        className="subtitle-2 font-semibold text-secondary "
                      >
                        {ownerDetails.profileType=="individual" ? "Current Residential Address" : "Current Business Address"}
                        <span className="text-red-500 text-lg font-semibold"> *</span>
                      </label>
                      <div className="flex flex-row">
                        <input
                          type="text"
                          name="address"
                          placeholder="Current Residential Address"
                          required
                            {...register("r_address", {
                          required: "Address is required",
                          minLength: {
                            value: 10,
                            message: "Address is too short",
                          },
                            })}
                            value={ownerDetails.address}
                            onChange={(e) => handleUpdateField("address", e.target.value)}
                            className="w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12 text-lg"
                        />                        
                      </div>
                      <div>
                          {errors.r_address?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Address is required
                            </p>
                          )}
                        </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            

            {/* if the owner is found */}
            {exists==true && <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="firstName"
                    className="subtitle-2 font-semibold text-secondary"
                  >
                    {ownerDetails.profileType=="individual" ? "First Name" : "Name of Organization"}
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    // value={ownerDetails.firstName || ""}
                    value={ownerDetails.firstName || ""}
                    readOnly={true}
                    placeholder={ownerDetails.profileType=="individual" ? "First Name":"Name of Organization"}
                  />
                    {errors.first_name?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        First Name is required
                      </p>
                    )}
                </div>
                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="middleName"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Middle Name
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    value={ownerDetails.middleName || ""}
                    readOnly={true}
                    placeholder="Middle Name"
                  />
                    {errors.middle_name?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Middle Name is required
                      </p>
                    )}
                </div>
                )}

                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="lastName"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Last Name
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    value={ownerDetails.lastName || ""}
                    readOnly={true}
                    placeholder="Last Name"
                  />
                    {errors.last_name?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Last Name is required
                      </p>
                    )}
                </div>
                )}

                {ownerDetails.profileType=="individual" && (
                  <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="type"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Gender
                  </label>
                  <select
                    name="gender"
                    disabled={true}
                    className="w-full bg-white rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                  >
                    <option value="">{ownerDetails.gender}</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {errors.o_gender?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Gender is required
                    </p>
                  )}
                </div>
                )}


                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="pulled_phone"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Phone No.
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="text"
                    name="phone"
                    value={ownerDetails.phone || ""}
                    readOnly={true}
                    placeholder="080xxxxxxxx"
                  />
                   {errors.phone?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Phone number is required
                    </p>
                  )}
                </div>

                <div className="md:mr-4 mt-4 col-span-1">
                  <label
                    htmlFor="email"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    Email
                  </label>
                  <input
                    className="border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-white"
                    type="email"
                    name="email"
                    value={ownerDetails.email || ""}
                    readOnly={true}
                  />
                  {errors.email?.type === "required" && (
                    <p role="alert" className="text-sm text-red-500">
                      Email address is required
                    </p>
                  )}
                </div>
              </div>
              <div>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="md:mr-4 mt-2 col-span-1 md:col-span-2 lg:col-span-3">
                  <label
                    htmlFor="address"
                    className="subtitle-2 font-semibold text-secondary "
                  >
                    {ownerDetails.profileType=="individual" ? "Current Residential Address" : "Current Business Address"}
                  </label>
                  <div className="flex flex-row">
                    <input
                      readOnly={true}
                      type="text"
                      name="address"
                      placeholder="Current Address"
                      value={ownerDetails.address}
                      className="w-full rounded-lg border-2 bg-white hover:border-green-800 py-1 px-2 h-12 text-lg"
                    />                        
                  </div>
                </div>
               </div>
                </div>
              </>}
            <div className="flex mt-3">
              <div className="flex-1 w-1/2">
                <button
                  className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                  onClick={() => {
                    setStep(getStep - 1);
                    window.location = "/services";
                  }}
                >
                  { "Cancel"}
                  
                </button>
              </div>
              <div className="flow-left md:mr-4">
                {exists ? <>
                  <button
                  className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                  onClick={Proceed}
                >
                  {!isLoading && "Next >>"}
                  {isLoading && <Loader />}
                  </button>
                </>:
                <>
                <button
                className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                onClick={handleSubmit(SubmitOwner)}
              >
                {!isLoading && "Next >>"}
                {isLoading && <Loader />}
                </button>
              </>}
              </div>
            </div>
            </form>
          </div>
          <InstructionModal />
        </div>
      </div>
    </div>
  );
}
