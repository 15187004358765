import { IoCheckmarkCircle } from "react-icons/io5";

export default function ConfirmPayment() {
  return (
    <>
      <div className="">
        <div>
          <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
            CMR ASSISTANT PORTAL
          </div>
          <div className="flex justify-center items-center">
            <div>
              <IoCheckmarkCircle className="text-green-500 w-20 h-20" />
              <p>
                Your Payment for Motor Vehicle Change Of Ownership was
                successful
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
