import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  reportDetails: {
    reference: "",
    vehicleId: "",
    entryDate: "",
    stateOfStolen: "",
    lgOfStolen: "",
    locationOfStolen: "",
    timeOfStolen: "",
    modeOfStealing: "",
    policeFormationWhereReport: "",
    dateReported: "",
    extractNumber: "",
    description: "",
    status: "",
    reportedBy: "",
    reporterId: "",
    commandCenter: "",
  },

  setReportDetails: (report_details) =>
    set(
      produce((temp) => {
        //logic here
        temp.reportDetails = {
          reference: report_details.reference,
          vehicleId: report_details.vehicleId,
          entryDate: report_details.entryDate,
          stateOfStolen: report_details.stateOfStolen,
          lgOfStolen: report_details.lgOfStolen,
          locationOfStolen: report_details.locationOfStolen,
          timeOfStolen: report_details.timeOfStolen,
          modeOfStealing: report_details.modeOfStealing,
          policeFormationWhereReport: report_details.policeFormationWhereReport,
          dateReported: report_details.dateReported,
          extractNumber: report_details.extractNumber,
          description: report_details.description,
          status: report_details.status,
          reportedBy: report_details.reportedBy,
          reporterId: report_details.reporterId,
          commandCenter: report_details.commandCenter,
        };
      })
    ),
  reset: (reportDetails) => set(reportDetails),
});
// store = devtools(store);
store = persist(store, { name: "reportDetails" });
export const useReportDetails = create(store);
