import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RiPrinterLine } from "react-icons/ri";
import Loader from "../components/Loader";
import colors from "../constants/colors";
import { usenewPlateNumber } from "../store/changePlateNumber";
import { useProductStore } from "../store/ProductStore";
import { useVehicleDetails } from "../store/VehicleStore";
import { base, cmr_change_plate, get_product_details } from "../constants/urls";
import { generate_trans_ref } from "../utils/generate_trans_ref";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import { useAgent } from "../store/AgentStore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInvoiceStore } from "../store/InvoiceStore";
import { formatDate } from "../utils/date_format";
import { useFormStep } from "../store/FormStepStore";

export default function ChangeofPlateConfirmation() {
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  const newPlateNumber = usenewPlateNumber((state) => state.newPlateNumber);
  const setnewPlateNumber = usenewPlateNumber(
    (state) => state.setnewPlateNumber
  );
  const getProduct = useProductStore((state) => state.product);
  const agentData = useAgent((state) => state.agentData);
  const getAuth = useAuth((state) => state.auth);
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const userDetails = {
    newPlateNumber: newPlateNumber,
    oldPlateNumber: vehicleDetails.licencePlate,
    chasisNumber: vehicleDetails.chassisNumber,
    engineNumber: vehicleDetails.engineNumber,
    manufacturer: vehicleDetails.manufacturer,
    model: vehicleDetails.model,
    year: vehicleDetails.year,
    vehicleType: vehicleDetails.vehicleType,
    color: vehicleDetails.color,
    vehicleUsage: vehicleDetails.vehicleUsage,
    watchlistStatus: vehicleDetails.vehicleStatus,
    licenseState: vehicleDetails.requestState,
  };

  const submit = () => {
    setIsLoading(true);

    //get product details
    let prodUrl = base + get_product_details + getProduct;
    let token = getAuth.token;
    fetch(prodUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        let respData = resp.data;
        console.log(respData);

        let referenceNumber =
          respData.product_code + generate_trans_ref().toString();
        console.log(referenceNumber);
        let data = {
          vehicle_id: vehicleDetails.id.toString(),
          new_plate: newPlateNumber,
          request_state: vehicleDetails.requestState,
          registered_by: agentData.id === "" ? "self" : agentData.agent,
          agent_id: agentData.id,
          reference: referenceNumber,
          amount: "1000",
        };
        console.log(data);
        let url = base + cmr_change_plate;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.json();
          })
          .then((resp) => {
            let responseData = resp.data;
            console.log(responseData);
            setIsLoading(false);
            let invoice = responseData.invoice;
            setInvoice({
              firstName: invoice.first_name,
              middleName: invoice.middle_name,
              lastName: invoice.last_name,
              address: invoice.address,
              email: invoice.email,
              phone: invoice.phone,
              narration: invoice.narration,
              amount: invoice.amount,
              transactionId: invoice.trans_ref,
              invoiceNumber: invoice.invoice_number,
              initiatedDate: formatDate(invoice.initiated_date),
              status: invoice.status,
              productCode: invoice.product_code,
              rrr: invoice.rrr,
              vin: invoice.vin,
              make: invoice.make,
              licenceNumber: invoice.license,
            });
            setStep(6);
            window.location = "/invoice";
          });
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>

      <div className="bg-gray-100 py-7">
        <div className="user-reg-cont px-7 py-7 mt-4 sm:mx-28 md:mx-16 md:px-10 lg:mx-36 lg:my-10 lg:px-10">
          <div className="text-2xl md:text-4xl text-text-basic font-bold">
            Confirmation Page
          </div>
          <div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="newPlateNumber"
                  className="subtitle-2 font-semibold text-secondary"
                >
                  New Licence Plate Number
                </label>
                <input
                  className="mt-2 border w-full border-black rounded-xl h-12 bg-secondary-alternate p-3"
                  type="text"
                  value={userDetails.newPlateNumber || ""}
                  onChange={(e) =>
                    setnewPlateNumber(e.target.value.toUpperCase())
                  }
                  placeholder="New Plate Number"
                />
              </div>

              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="oldPlateNumber"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Old Licence Plate Number
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.oldPlateNumber || ""}
                  placeholder="Old Plate Number"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="chasisNumber"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Chassis Number
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.chasisNumber || ""}
                  placeholder="Chasis Number"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="engineNumber"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Engine Number
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.engineNumber || ""}
                  placeholder="Engine Number"
                />
              </div>

              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="manufacturer"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Make
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.manufacturer || ""}
                  placeholder="Manufacturer Name"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="model"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Model
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  name="model"
                  readOnly={true}
                  //   {...register("pulled_email", { required: false })}
                  // aria-invalid={errors.r_email ? "true" : "false"}
                  value={userDetails.model || ""}
                  placeholder="Model Name"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="vehicleType"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Type
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.vehicleType || ""}
                  placeholder="Vehicle Type"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="year"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Year
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.year || ""}
                  placeholder="Year"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="color"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Color
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.color || ""}
                  placeholder="Color"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="vehicleUsage"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Vehicle Usage
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.vehicleUsage || ""}
                  placeholder="Vehicle Usage"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="watchlistStatus"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Watchlist Status
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.watchlistStatus || ""}
                  placeholder="Watchlist Status"
                />
              </div>
              <div className="md:mr-4 mt-4 col-span-1">
                <label
                  htmlFor="licenseState"
                  className="subtitle-2 font-semibold text-secondary "
                >
                  Licence State
                </label>
                <input
                  className="mt-2 border w-full border-secondary-500 rounded-xl h-12 bg-secondary-alternate p-3 bg-gray-200"
                  type="text"
                  readOnly
                  value={userDetails.licenseState || ""}
                  placeholder="Licence State"
                />
              </div>
            </div>
            <div className="print:hidden text-slate-900 mt-10 pb-2 border-b border-solid border-slate-200">
              <div className="text-red-600 text-justify mb-2 font-sans font-bold text-xs">
                Please ensure that the above information is accurate before
                confirming and proceeding to make payment. The payment is
                non-refundable.
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="confirmation"
                  value={isConfirmed}
                  onChange={(e) => {
                    setIsConfirmed(e.target.checked);
                  }}
                />{" "}
                <span className="ml-3 text-sm text-justify">
                  I hereby confirm that the above information is accurate and
                  genuine.
                </span>
              </div>
            </div>
            <div className="flex justify-between mt-3">
              <div>
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                  className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-1 py-1`}
                >
                  &larr; Back
                </button>
              </div>
              <div className="flex flex-row">
                <button
                  className="flex justify-center items-center rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-black px-1 py-1 border border-red-500 mr-2"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <RiPrinterLine />
                  <p>Print</p>
                </button>
                <button
                  disabled={!isConfirmed}
                  className={`proceed-btn bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                  onClick={() => {
                    if (newPlateNumber === "") {
                      toast.error("Enter the new Vehicle Plate Number");
                      return;
                    }
                    submit();
                  }}
                >
                  {!loading && "Proceed >>"}
                  {loading && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
