const colors = [
  "Ash",
  "Aquamarine",
  "Azure",
  "Bamboo Pearl",
  "Black",
  "Blue",
  "Brown",
  "Charcoal",
  "Coral",
  "Cream",
  "Crimson",
  "Cyan",
  "Fuchsia",
  "Gold",
  "Gray",
  "Green",
  "Hot pink",
  "Ivory",
  "Khaki",
  "Lime",
  "Magenta",
  "Maroon",
  "Magnetic Grey Metalic",
  "Navy blue",
  "Olden",
  "Olive",
  "Orange",
  "Oxblood",
  "Pea green",
  "Plum",
  "Purple",
  "Red",
  "Silver",
  "Teal",
  "Wheat",
  "White",
  "Wine",
  "Yellow",
];

export default colors;
