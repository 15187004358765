import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ViewError from "../ViewError";
import { base, get_invoice, confirm_payment, regenerate } from "../../constants/urls";
import { useAuth } from "../../store/Auth";
import Swal from "sweetalert2";
import { useFormStep } from "../../store/FormStepStore";
import { useInvoiceStore } from "../../store/InvoiceStore";
import { formatDateNew, dateMonthYear } from "../../utils/date_format";
import Loader from "../Loader";
import { generate_trans_ref } from "../../utils/generate_trans_ref";

// const formatDate = (date) => {
//   const newDate = new Date(date);
//   const options = {
//     year: "numeric",
//     month: "short",
//     day: "numeric",
//   };
//   return newDate.toLocaleString("en-US", options);
// };

export const COLUMNS = [
  {
    Header: "S/No.",
    accessor: "serial",
  },
  {
    Header: "Request Date",
    accessor: "date",
    Cell: ({ cell, row }) => {
      const data = row.original;
      return <p>{formatDateNew(data.date)}</p>;
    },
  },
  {
    Header: "Request Number",
    accessor: "request_number",
  },
  {
    Header: "Request Type",
    accessor: "request_type",
  },
  {
    Header: "Licence Plate",
    accessor: "license_plate",
  },
  {
    Header: "Vehicle Make/Model",
    accessor: "make",
  },
  {
    Header: "Invoice/Receipt Number",
    accessor: "invoice_number",
    Cell: ({ cell, row }) => {
      const navigate = useNavigate();
      const handleRouting = () => {
        data.payment_status === "Paid"
          ? navigate(`/receipt/${data.reference}?source=requests`)
          : navigate(`/invoice/${data.reference}`);
      };
      const data = row.original;
      // if (data.payment_status === "Paid") {
      //   return <div onClick={() => navigate(`/receipt/${data.reference}`)} className="flex space-x-2"><span>CR{data.invoice_number}</span> <span className="border border-yellow-500 rounded-lg px-2">View</span></div>;
      // } else {
      //   return <div onClick={() => navigate(`/invoice/${data.reference}`)} className="flex space-x-2"><span>CI{data.invoice_number}</span> <span className="border border-yellow-500 rounded-lg  px-2">View</span></div>;
      // }
      return (
        <div className="flex flex-wrap space-x-2" onClick={handleRouting}>
          {" "}
          <span>
            {data.payment_status === "Paid"
              ? "CR" + data.invoice_number
              : "CI" + data.invoice_number}
          </span>{" "}
          <span className="text-xs py-1 bg-gray-600 text-white items-center rounded-lg cursor-pointer  px-2">
            View
          </span>
        </div>
      );
    },
  },
  {
    Header: "Payment Status",
    accessor: "payment_status",
  },
  {
    Header: "ID Validation",
    accessor: "owner_status",
    Cell: ({ row }) => {
      const data = row.original;
      return (
        <p
          style={{
            color:
              data.owner_status === "Verified"
                ? "green"
                : data.owner_status === "Not Verified"
                ? "orange"
                : "red",
          }}
        >
          <div>
              {data.owner_status}
          </div>
        </p>
      );
    },
  },
  {
    Header: "Vehicle Status",
    accessor: "vehicle_status",
    Cell: ({ row }) => {
      const data = row.original;
      return (
        <p
          style={{
            color:
              data.vehicle_status === "Valid"
                ? "green"
                : data.vehicle_status === "Conflict"
                ? "orange"
                : "red",
          }}
        >
          <div>
              {data.vehicle_status}
          </div>
        </p>
      );
    },
  },
  
  {
    Header: "Request Status",
    accessor: "request_status",
    Cell: ({ row }) => {
      const data = row.original;
      return (
        <p
          style={{
            color:
              data.request_status === "Approved"
                ? "green"
                : data.request_status === "Processing"
                ? "orange"
                : "red",
          }}
        >
          <div>
            {data.payment_status === "Unpaid" ? (
              <p className="py-1 text-red-500">Incomplete</p>
            ) : (
              data.request_status
            )}
          </div>
        </p>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ cell, row }) => {
      const data = row.original;
      // alert(data.reference);
      const [showError, setShowError] = useState(false);
      const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
      // const { reference } = useParams();
      const setStep = useFormStep((state) => state.setStep);
      const getAuth = useAuth((state) => state.auth);
      const [loading, setLoading] = useState(false);
      const [regenerating, setRegenerating] = useState(false);
      const navigate = useNavigate();
      const [RRR, setRRR] = useState("");
      const [confirmed, setConfirmed] = useState("");

      const handleRequest = () => {
        navigate(`/viewrequest/${data.reference}`);
      };

      const handleReview = () => {
        navigate(`/review/${data.vehicle_id}`);
      };

      const getProductType = (product_desc) => {
        let product = "";
        let code = "";
        switch (product_desc) {
          case "Motor Vehicle Information":
            product = "cmrinfo";
            code = "01";
            break;
          default:
        }
        let resp = [product, code];
        return resp;
      };
      
      const RegenerateTransaction = async (e, product) => {
        e.preventDefault();
        if (data.payment_status == "Paid"){
          Swal.fire("This transaction already paid for");
        }else{
          setRegenerating(true);
        let new_reference = product[1] + generate_trans_ref().toString();
  
        let url =
          base +
          regenerate +
          product[0] +
          "/" +
          `${data.reference}` +
          "/" +
          new_reference;
        let token = getAuth.token;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((respJson) => {
            console.log(respJson);
            if (respJson.statusCode == 0) {
              //set the invoice store
              let data = respJson.data.invoice;
              setInvoice({
                firstName: data.first_name,
                middleName: data.middle_name,
                lastName: data.last_name,
                address: data.address,
                email: data.email,
                phone: data.phone,
                narration: data.narration,
                amount: data.amount,
                rrr: data.rrr,
                transactionId: data.trans_ref,
                invoiceNumber: data.invoice_number,
                initiatedDate: data.initiate_date,
                status: "Unpaid",
                productCode: data.product_code,
                make: data.make,
                vin: data.vin,
                licenceNumber: data.license,
              });
              setStep(6);
              window.location = "/invoice";
            } else {
              Swal.fire("Unable to retrieve transaction. Click Regenerate to resubmit");
            }
          })
          .catch((err) => {
            let message;
            console.log(err)
            if (err && err.response) {
              const { data } = err.response;
              message = data.message;
            }
            // console.log(err);
            Swal.fire(message || "Unable to continue, please try again");
            setRegenerating(false);
          });
        }
        
      };

      const errorFunc = () => {
        Swal.fire({
          title: "Error!",
          text: "Unable to Confirm Transaction",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
      };
      const confirmPayment = async () => {
        setLoading(true);
        const token = getAuth.token;

        let url = base + get_invoice + `${data.reference}`;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((respJson) => {
            console.log(respJson);
            if (respJson.statusCode == 0) {
              //set the invoice store
              let trans = respJson.data.transaction;
              // setRRR(data.rrr);
              console.log(trans.rrr);
              url =
                base +
                confirm_payment +
                "cmrinfo1" +
                "/" +
                data.reference +
                "/" +
                trans.rrr;
              fetch(url, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              })
                .then((response) => response.json())
                .then((resp) => {
                  if (resp.statusCode == 0) {
                    console.log(resp.data);
                    if (resp.data.Status == "01") {
                      Swal.fire({
                        title: "",
                        text: "Payment confirmed",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#1C89DE",
                      }).then(() => {
                        setLoading(false);
                        setConfirmed();
                      });
                    } else {
                      Swal.fire({
                        title: "Error!",
                        text: "Payment Not Found",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#1C89DE",
                      }).then(() => {
                        setLoading(false);
                      });
                    }
                  }
                })
                .catch((err) => {
                  errorFunc();
                  console.log(err);
                  setLoading(false);
                });
            } else {
              Swal.fire({
                title: "Error!",
                text: "Error processing this invoice",
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#1C89DE",
              }).then(() => {
                setLoading(false);
              });
            }
          });
      };

      const handlePayment = async (e) => {
        let url = base + get_invoice + `${data.reference}`;
        let token = getAuth.token;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((respJson) => {
            console.log(respJson);
            if (respJson.statusCode == 0) {
              //set the invoice store
              let data = respJson.data.transaction;
              setInvoice({
                firstName: data.first_name,
                middleName: data.middle_name,
                lastName: data.last_name,
                address: data.address,
                email: data.email,
                phone: data.phone,
                narration: data.narration,
                amount: data.amount,
                rrr: data.rrr,
                transactionId: data.trans_ref,
                invoiceNumber: data.invoice_number,
                initiatedDate: data.initiate_date,
                status: "Unpaid",
                productCode: data.product_code,
                make: data.make,
                vin: data.vin,
                licenceNumber: data.license,
              });
              setStep(6);
              window.location = "/invoice";
            } else {
              Swal.fire("Unable to retrieve transaction");
            }
          })
          .catch((err) => {
            let message;

            if (err && err.response) {
              const { data } = err.response;
              message = data.message;
            }

            Swal.fire(message || "Unable to continue, please try again");
            setLoading(false);
          });
      };
      if (data.request_status === "Approved" && data.license_plate !== "") {
        return (
          <div>
            <button className="text-xs rounded-lg px-2 py-1 bg-green-700 text-green-100 hover:bg-green-800 duration-300">
              View Certificate
            </button>
          </div>
        );
      } else if (data.request_status === "Error") {
        return (
          <div>
            <button
              onClick={handleReview}
              className="text-xs rounded-lg py-1 px-2 bg-red-600 text-red-100 hover:bg-red-700 duration-300"
            >
              Review
            </button>
          </div>
        );
      } else if (data.payment_status === "Unpaid") {
        return (
          <div className="flex space-x-4">
            <button
              onClick={handlePayment}
              className="text-xs rounded-lg px-2 py-1 bg-blue-500 text-blue-100 hover:bg-blue-600 duration-300"
            >
              Make Payment
            </button>
            <button
              onClick={confirmPayment}
              className={`${
                confirmed && "hidden"
              } text-xs rounded-lg px-2 py-1 bg-green-500 text-green-100 hover:bg-green-600 duration-300`}
            >
              {loading == true ? <Loader /> : "Confirm Payment"}
            </button>
            <button
              onClick={(e)=>{
                let product = getProductType(data.request_type);
                RegenerateTransaction(e, product);
              }}
              className={`${
                confirmed && "hidden"
              } text-xs rounded-lg px-2 py-1 bg-yellow-500 text-green-100 hover:bg-green-600 duration-300`}
            >
              {regenerating == true ? <Loader /> : "Regenerate"}
            </button>
          </div>
        );
      } else if (data.request_status === "Processing") {
        return (
          <div>
            <button
              onClick={handleRequest}
              className="text-xs rounded-lg px-2 py-1 bg-gray-600 text-gray-100 hover:bg-gray-700 duration-300"
            >
              View Request
            </button>
          </div>
        );
      } else if (
        data.request_status === "Approved" &&
        data.license_plate === ""
      ) {
        return (
          <div>
            <button
              onClick={handleRequest}
              className="text-xs rounded-lg px-2 py-1 bg-green-700 text-green-100 hover:bg-green-800 duration-300"
            >
              View Notification
            </button>
          </div>
        );
      } else {
        return (
          <div>
            <button className=" text-xs rounded-lg py-1 px-2 bg-red-600 text-red-100 hover:bg-red-700 duration-300">
              View Certificate
            </button>
          </div>
        );
      }
    },
  },
];

export const MYSOLDVEHICLECOLUMNS = [
  // {
  //   Header: "Vehicle Id",
  //   accessor: "vehicle_id",
  // },

  {
    Header: "Chassis Number",
    accessor: "chassis_number",
  },

  {
    Header: "Make",
    accessor: "make",
  },

  {
    Header: "Sold to",
    accessor: "sold_to",
  },

  {
    Header: "Date Sold",
    accessor: "date_sold",
    Cell: ({ cell, row }) => {
      const data = row.original;
      return <p>{formatDateNew(data.date_sold)}</p>;
    },
  },
];
