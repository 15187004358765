import React from "react";
import UserLogin from "../components/UserLogin";

export default function Login() {
  return (
    <>
      <UserLogin />
    </>
  );
}
