import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  multipleVehicleDetails: [],

  setMultipleVehicleDetails: (item) =>
    set(
      produce((state) => {
        state.multipleVehicleDetails.push(item);
      })
    ),
  removeItem: (index) =>
    set(
      produce((state) => {
        state.multipleVehicleDetails.splice(index, 1);
      })
    ),
  clearAllItems: () =>
    set(
      produce((state) => {
        state.multipleVehicleDetails = [];
      })
    ),
});

// store = devtools(store);
store = persist(store, { name: "multipleVehicleDetails" });

export const useCompanyVehicleDetails = create(store);
