import React, { useState, useEffect } from "react";
import validator from "validator";
import { useForm } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import colors from "../constants/colors";
import { BsInfoCircleFill } from "react-icons/bs";
import DateTimePicker from "react-datetime-picker";
import Swal from "sweetalert2";
import Loader from "./Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addOneYearToCurrentDate,
  formatDate,
  formatDateTime,
} from "../utils/date_format";
import { stringToUpper, removeSpaces } from "../utils/data_transform";
import { mask_number } from "../utils/mask_number";
import { useVehicleDetails } from "../store/VehicleStore";
import { useCompanyVehicleDetails } from "../store/CompanyVehicleStore";
import { useUserStore } from "../store/UserStore";
import { useOwnerStore } from "../store/OwnerStore";
import { useFormStep } from "../store/FormStepStore";
import VehicleInformationPreviewModal from "./VehicleInformationPreviewModal";
import { GrFormClose } from "react-icons/gr";
import {
  base,
  states as stateUrl,
  manufacturers as manufacturesUrl,
  models as modelsUrl,
  agent_nultiple_cmrinfo_requests as cmr_multiple_pre_entry,
  get_product_details,
  vehicle_types as v_type_url
} from "../constants/urls";
import { useAuth } from "../store/Auth";
import { useModal } from "../store/ModalState";
import { useProductStore } from "../store/ProductStore";
import { generate_trans_ref } from "../utils/generate_trans_ref";
import { useInvoiceStore } from "../store/InvoiceStore";
import { checkToken } from "./CheckToken";

export default function Vehicle() {
  const vehicleCount = localStorage.getItem("vehicleCount");
  const [showInfo, setShowInfo] = useState(false);
  const userDetails = useUserStore((state) => state.userDetails);
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [plateNumber, setPlateNumber] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const getProduct = useProductStore((state) => state.product);
  const [vehicle_types, setVehicleTypes]  = useState([])
  const [vehicle_cat, setVehicleCat]  = useState()

  // const resetVehicle = useVehicleDetails((state) => state.reset);
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  const multipleVehicleDetails = useCompanyVehicleDetails(
    (state) => state.multipleVehicleDetails
  );
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const setVehicleDetails = useVehicleDetails(
    (state) => state.setVehicleDetails
  );
  const setMultipleVehicleDetails = useCompanyVehicleDetails(
    (state) => state.setMultipleVehicleDetails
  );
  const clearAllItems = useCompanyVehicleDetails(
    (state) => state.clearAllItems
  );
  const removeItem = useCompanyVehicleDetails((state) => state.removeItem);

  function allKeysHaveValues(obj) {
    return Object.values(obj).every((val) => val);
  }

  const getAuth = useAuth((state) => state.auth);
  const ownerDetails = useOwnerStore((state) => state.ownerDetails);
  // const [modalState, setModalState] = useState(false);
  const showModal = useModal((state) => state.showModal);
  const setShowModal = useModal((state) => state.setShowModal);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const review = () => {
    setShowModal(true);
  };

  const clickInfoButton = () => {
    setShowInfo(!showInfo);
  };

  const handlePlateNumberType = (e) => {
    setPlateNumber(e.target.value);
    if (e.target.value === "new") {
      setVehicleDetails({
        ...vehicleDetails,
        licencePlate: null, //stringToUpper(removeSpaces(e.target.value)),
      });
    }
  };


  const handleVehicleCategory = (e) => {
    // e.preventDefault();
    const url = base + v_type_url + e.target.value;
  fetch(url)
    .then((res) => res.json())
    .then((resJson) => {
      if (resJson.statusCode === 0) {
        console.log(resJson.data);
        setVehicleTypes(resJson.data)
        setVehicleCat(e.target.value)
      }
    });
  };

  const handleMultipleVehicleRequest = (data) => {
    console.log(data);
    const newEntry = vehicleDetails;
    const sameLicence = multipleVehicleDetails.some(
      (obj) => obj.licencePlate === vehicleDetails.licencePlate
    );
    const sameChassis = multipleVehicleDetails.some(
      (obj) => obj.chassisNumber === vehicleDetails.chassisNumber
    );
    if (sameLicence && newEntry.licencePlate != "") {
      toast.error("Licence Plate Number entered already");
    } else if (sameChassis) {
      toast.error("Chassis Number entered already");
    } else {
      setMultipleVehicleDetails(newEntry);
      setVehicleDetails({
        licencePlate: "",
        chassisNumber: "",
        engineNumber: "",
        model: "",
        manufacturer: "",
        year: "",
        vehicleType: "",
        color: "",
        vehicleUsage: "",
        vehicleStatus: "",
        mla: "",
        mlaAddress: "",
        sourceOfVehicle: "",
        requestState: newEntry.requestState,
      });
      reset({ request_state: data.request_state });
    }
  };

  const addMultipleVehicles = () => {
    setIsLoading(true);
    let prodUrl = base + get_product_details + getProduct;
    let token = getAuth.token;
    fetch(prodUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        let respData = resp.data;
        let referenceNumber =
          respData.product_code + generate_trans_ref().toString();
        let requestState = multipleVehicleDetails[0].requestState;
        let requestBody = {
          owner: {
            first_name: ownerDetails.firstName,
            middle_name: ownerDetails.middleName,
            last_name: ownerDetails.lastName,
            type: ownerDetails.profileType,
            gender: ownerDetails.gender,
            email: ownerDetails.email,
            phone: ownerDetails.phone,
            alternate_phone: "",
            state: ownerDetails.currentState,
            lga: ownerDetails.currentLG,
            registered_address: ownerDetails.pulledAddress,
            present_address: ownerDetails.address,
            id_type: ownerDetails.idType,
            id_number: ownerDetails.idNumber,
            pulled_state: ownerDetails.pulledState,
            pulled_lg: ownerDetails.pulledLG,
            status: "Verified",
          },
          pre_cmr: {
            reference: referenceNumber,
            entry_date: formatDate(new Date()),
            expiry_date: formatDate(addOneYearToCurrentDate()),
            amount: respData.price,
            payment_status: "Unpaid",
            status: "Pending",
            request_state: requestState,
          },
          vehicles: multipleVehicleDetails.map(
            ({
              licencePlate,
              chassisNumber,
              engineNumber,
              manufacturer,
              model,
              year,
              vehicleType,
              vehicleUsage,
              color,
              mla,
              mlaAddress,
              sourceOfVehicle,
            }) => {
              return {
                license_plate: licencePlate,
                chassis_number: chassisNumber,
                engine_number: engineNumber,
                manufacturer: manufacturer,
                model: model,
                year: year,
                vehicle_type: vehicleType,
                color: color,
                vehicle_usage: vehicleUsage,
                vehicle_status: "Valid",
                mla: mla,
                mla_address: mlaAddress,
                vehicle_source: sourceOfVehicle,
              };
            }
          ),
        }; 
        console.log(requestBody)
        let url = base + cmr_multiple_pre_entry;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => {
            return response.json();
          })
          .then((resp) => {
            console.log(resp);
            if (resp.data === "vehicle exists") {
              Swal.fire({
                title: "Vehicles already registered by you",
                text: " ",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1C89DE",
              }).then(() => {
                window.location = "/requests";
              });
              return;
            }
            let responseData = resp.data;
            let invoice = responseData.invoice;
            setInvoice({
              firstName: invoice.first_name,
              middleName: invoice.middle_name,
              lastName: invoice.last_name,
              address: invoice.address,
              email: invoice.email,
              phone: invoice.phone,
              narration: invoice.narration,
              amount: invoice.amount,
              transactionId: invoice.trans_ref,
              invoiceNumber: invoice.invoice_number,
              initiatedDate: formatDate(invoice.initiated_date),
              status: invoice.status,
              productCode: invoice.product_code,
              rrr: invoice.rrr,
              vin: invoice.vin,
              vat: invoice.vat,
              make: invoice.make,
              number_of_entry: responseData.number_of_entry,
              licenceNumber: invoice.license,
            });
            localStorage.setItem(
              "vehicleEntriesCount",
              responseData.number_of_entry
            );
            setStep(6);
            window.location = "/invoice";
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const goBack = () => {
    setStep(5);
    window.location.href = "/owner";
  };

  useEffect(() => {
    setVehicleDetails({});
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  useEffect(() => {
    let url = base + manufacturesUrl + "/" + vehicle_cat;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setManufacturers(resJson.data);
      });
  }, [vehicle_cat]);

  useEffect(() => {
    var max = new Date().getFullYear();
    var min = 1980;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    setYears(years);
  }, []);

  useEffect(() => {
    getModels(vehicle_cat);
  }, [vehicleDetails.manufacturer]);

  const getModels = async (cat) => {
    const url = base + modelsUrl + vehicleDetails.manufacturer + "/" + cat;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setModels(resJson.data);
        }
      });
  };

  useEffect(() => {
    if (
      getAuth.authenticated === true ||
      getAuth.token !== "" ||
      getAuth.token !== ""
    ) {
      checkToken(getAuth.token).then((auth) => {
        if (!auth) {
          localStorage.clear();
          window.location = "/login";
        }
      });
    }
  }, []);

  if (step < 5) {
    window.location = "/signup";
    return;
  }

  return (
    <div className="">
      <div className="h-screen">
        <ToastContainer />
        <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
          MOTOR VEHICLE INFORMATION PORTAL
        </div>
        <div className="h-full max-w-5xl mx-auto py-4">
          <div className="">
            <p className="p-4 mx-4 rounded-lg text-xl mt-3 font-bold bg-gray-700 font-sans md:text-2xl text-gray-100 font-semi-bold mb-3">
              Vehicle Details
            </p>
            <div className="shadow-lg px-6 py-3 mt-8 mb-6">
              <div className=" lg:flex">
              <div className="lg:flex-1">
                <label
                  htmlFor="r_profile_type"
                  className="flex font-semibold mt-3 text-gray-900 text-xl mb-1 font-sans"
                >
                  Vehicle Category
                </label>
                <div className="flex flex-wrap md:justify-left sm:space-x-8 my-4 mt-3 text-gray-900 text-lg mb-4 font-sans">
                  <div className="flex">
                      <label className="flex space-x-1" htmlFor="motorvehicle">
                        <input
                          onChange={handleVehicleCategory}
                          className=""
                          type="radio"
                          id="mv"
                          name="vcat"
                          value="motorvehicle"
                        />
                        <div className="flex items-center space-x-1">
                          Motor Vehicle{"      "}
                        </div>
                      </label>
                    </div>

                    <div className="flex">
                      <label className="flex space-x-1" htmlFor="motorcycle">
                        <input
                          onChange={handleVehicleCategory}
                          className=""
                          type="radio"
                          id="mc"
                          name="vcat"
                          value="motorcycle"
                        />
                        <div className="flex space-x-1 items-center">
                          <p>Motorcycle/Tricycle</p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              <div className="lg:flex-1">
                <label
                  htmlFor="r_profile_type"
                  className="flex font-semibold mt-3 text-gray-900 text-xl mb-1 font-sans"
                >
                  Plate Number Type{" "}
                  <BsInfoCircleFill
                    onClick={clickInfoButton}
                    className={`hover:${clickInfoButton} hover:cursor-pointer text-blue-500 mr-2 w-4 h-4`}
                  />
                  {showInfo && (
                    <p className="text-xs z-50 p-3 shadow-lg bg-white">
                      <div className="flex justify-end">
                        <GrFormClose
                          onClick={clickInfoButton}
                          className="w-5 h-5"
                        />
                      </div>
                      <strong>Registered Plate Number</strong> This option is for
                      a vehicle with Licence Plate Number.
                      <br />
                      <strong>No Plate Number</strong> This option is for a
                      vehicle that is yet to be issued a Licence Plate Number.
                    </p>
                  )}
                </label>
                <div className="flex flex-wrap md:justify-left sm:space-x-8 my-4 mt-3 text-gray-900 text-lg mb-4 font-sans">
                  <div className="flex">
                      <label className="flex space-x-1" htmlFor="old">
                        <input
                          onChange={handlePlateNumberType}
                          className=""
                          type="radio"
                          id="old"
                          name="plate"
                          value="old"
                        />
                        <div className="flex items-center space-x-2">
                          Registered Plate Number
                        </div>
                      </label>
                    </div>

                    <div>
                      <label className="flex space-x-1" htmlFor="new">
                        <input
                          onChange={handlePlateNumberType}
                          className=""
                          type="radio"
                          id="new"
                          name="plate"
                          value="new"
                        />
                        <div className="flex space-x-1 items-center">
                          <p>No Plate Number</p>
                        </div>
                      </label>
                    </div>
                    {errors.plateNumber?.type === "required" && (
                      <p role="alert" className="text-md text-red-500">
                        please Select plate number type
                      </p>
                    )}
                </div>
              </div>
              </div>
              {/* <div className="flex">
                Here again
              </div> */}
              

              {/*  Start here*/}
              {/* End here */}


              {(plateNumber === "old" || plateNumber === "new") && (
                <>
                  <div className="xl:flex w-full">
                    {plateNumber === "old" ? (
                      <div className="xl:flex-1 xl:mr-3">
                        <label
                          htmlFor="license_plate"
                          className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                        >
                          Licence Plate Number
                        </label>
                        <input
                          type="text"
                          {...register("license_plate", {
                            required: true,
                            pattern: {
                              value: /^[a-zA-Z0-9]*$/,
                              message: "Invalid License Plate Number",
                            },
                          })}
                          aria-invalid={errors.license_plate ? "true" : "false"}
                          // placeholder="Ex. LS"
                          maxLength={8} 
                          value={vehicleDetails.licencePlate}
                          onChange={(e) =>
                            setVehicleDetails({
                              ...vehicleDetails,
                              licencePlate: stringToUpper(
                                removeSpaces(e.target.value)
                              ),
                            })
                          }
                          className="w-full uppercase rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                        />
                        {errors.license_plate?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            Licence Plate Number is required
                          </p>
                        )}
                        {errors.license_plate?.type === "pattern" && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.license_plate.message}
                          </p>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="xl:flex-1 xl:mr-3">
                      <label
                        htmlFor="chassisNumber"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        VIN/Chassis Number
                      </label>
                      <input
                        type="text"
                        {...register("chassis_number", {
                          required: "Chassis Number is required",
                          pattern: {
                            value: /^[^IOQioq*,._]*$/, //removed - to allow for hypen in vin
                            message: "Invalid Chassis Number",
                          },
                          minLength: {
                            value: 10,
                            message: "Min 17 characters",
                          },
                          maxLength: {
                            value: 17,
                            message: "Max 17 characters",
                          },
                        })}
                        value={vehicleDetails.chassisNumber}
                        maxLength={17}
                        onChange={(e) => {
                          setVehicleDetails({
                            ...vehicleDetails,
                            chassisNumber: stringToUpper(
                              removeSpaces(e.target.value)
                            ),
                          });
                        }}
                        className="w-full uppercase rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      />
                      {errors.chassis_number ? (
                        <>
                          {errors.chassis_number?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              {errors.chassis_number.message}
                            </p>
                          )}
                          {errors.chassis_number?.type === "pattern" && (
                            <p role="alert" className="text-sm text-red-500">
                              {errors.chassis_number.message}
                            </p>
                          )}
                          {(errors.chassis_number?.type === "maxLength" ||
                            errors.chassis_number?.type === "minLength") && (
                            <p role="alert" className="text-sm text-red-500">
                              {errors.chassis_number.message}
                            </p>
                          )}
                        </>
                      ) : null}
                    </div>
                    <div className="xl:flex-1">
                      {" "}
                      <label
                        htmlFor="engine_number"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Engine Number
                      </label>
                      <input
                        type="text"
                        {...register("engine_number", { required: false })}
                        value={vehicleDetails.engineNumber}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            engineNumber: e.target.value.toUpperCase(),
                          })
                        }
                        className="uppercase w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      />
                      {errors.engine_number?.type === "required" && (
                        <p role="alert" className="text-md text-red-500">
                          Engine Number is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="xl:flex mt-3">
                    <div className="xl:flex-1 xl:mr-3">
                      <label
                        htmlFor="manufacturer"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Make
                      </label>
                      <select
                        {...register("manufacturer", { required: true })}
                        value={vehicleDetails.manufacturer}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            manufacturer: e.target.value,
                            model: "",
                          })
                        }
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Select Manufacturer</option>
                        {manufacturers?.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.manufacturer?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Choose a Manufacturer
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1 xl:mr-3">
                      <label
                        htmlFor="model"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Model
                      </label>
                      <select
                        {...register("model", { required: true })}
                        value={vehicleDetails.model}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            model: e.target.value,
                          })
                        }
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Select Model</option>
                        {models?.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.model?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Choose a Vehicle Model
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1">
                      <label
                        htmlFor="vehicle_type"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Type
                      </label>
                      <select
                        {...register("vehicle_type", { required: true })}
                        value={vehicleDetails.vehicleType}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            vehicleType: e.target.value,
                          })
                        }
                        className="h-12 text-lg w-full rounded-lg border-2 hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select Vehicle Type</option>
                        {vehicle_types?.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.vehicle_type?.type === "required" && (
                        <p role="alert" className="text-md text-red-500">
                          Vehicle Type is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="xl:flex xl:mt-3">
                    <div className="xl:flex-1 xl:mr-3">
                      <label
                        htmlFor="year"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Year
                      </label>
                      <select
                        {...register("year", { required: true })}
                        value={vehicleDetails.year}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            year: e.target.value,
                          })
                        }
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Year of Manufacture</option>
                        {years.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.year?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Year of Vehicle Manufacture is required
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1 mr-3">
                      <label
                        htmlFor="vehicleUsage"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Vehicle Usage
                      </label>
                      <select
                        name="vehicle_usage"
                        {...register("vehicle_usage", { required: true })}
                        value={vehicleDetails.vehicleUsage}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            vehicleUsage: e.target.value,
                          })
                        }
                        className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select Usage</option>
                        <option value="Private">Private</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Government">Government</option>
                      </select>
                      {errors.vehicle_usage?.type === "required" && (
                        <p role="alert" className="text-md text-red-500">
                          Vehicle Usage is required
                        </p>
                      )}
                    </div>

                    <div className="xl:flex-1">
                      {" "}
                      <label
                        htmlFor="color"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Color
                      </label>
                      <select
                        {...register("color", { required: true })}
                        value={vehicleDetails.color}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            color: e.target.value,
                          })
                        }
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Select Color</option>
                        {colors.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.color?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Color is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="xl:flex mt-3">
                    <div className="xl:flex-1 xl:mr-3">
                      <label
                        htmlFor="source_of_vehicle"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        Source of Vehicle
                      </label>
                      <select
                        name="source_of_vehicle"
                        {...register("source_of_vehicle", { required: true })}
                        value={vehicleDetails.sourceOfVehicle}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            sourceOfVehicle: e.target.value,
                          })
                        }
                        required
                        className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select Source </option>
                        <option value="Imported">Imported</option>
                        <option value="Locally Assembled">
                          Locally Assembled
                        </option>
                      </select>
                      {errors.source_of_vehicle?.type === "required" && (
                        <p role="alert" className="text-md text-red-500">
                          Vehicle Source is required
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1 mr-3">
                      <label
                        htmlFor="mla"
                        className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                      >
                        State Licencing Office
                      </label>
                      <select
                        {...register("mla", { required: true })}
                        value={vehicleDetails.mla}
                        onChange={(e) =>
                          setVehicleDetails({
                            ...vehicleDetails,
                            mla: e.target.value,
                          })
                        }
                        className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select State</option>
                        {states.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.mla?.type === "required" && (
                        <p role="alert" className="text-md text-red-500">
                          MLA is required
                        </p>
                      )}
                    </div>
                    {multipleVehicleDetails &&
                    multipleVehicleDetails.length < 1 ? (
                      <div className="xl:flex-1">
                        <label
                          htmlFor="request_state"
                          className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                        >
                          State Requesting From
                        </label>
                        <select
                          {...register("request_state", { required: true })}
                          value={vehicleDetails.requestState}
                          onChange={(e) =>
                            setVehicleDetails({
                              ...vehicleDetails,
                              requestState: e.target.value,
                            })
                          }
                          className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                        >
                          <option value="">Select State</option>
                          {states.map((val, index) => {
                            return (
                              <option key={index} value={val}>
                                {val}
                              </option>
                            );
                          })}
                        </select>
                        {errors.request_state?.type === "required" && (
                          <p role="alert" className="text-md text-red-500">
                            State of request is required
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="flex-1"></div>
                    )}
                  </div>

                  <div className="flex flex-wrap justify-center">
                    <div className="flex-1 w-1/2">
                      <button
                        className={`bg-blue-500 rounded-lg text-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                        onClick={() => {
                          goBack();
                        }}
                      >
                        {"<<"}Previous
                      </button>
                    </div>
                    <div className="flow-left">
                      {vehicleCount === "multiple" ? (
                        <>
                          <button
                            className={`bg-blue-500 rounded-lg text-lg mb-6 mr-2 text-center cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                            onClick={handleSubmit(handleMultipleVehicleRequest)}
                            // onClick={review}
                          >
                            Add &#43;
                          </button>
                          <button
                            className={`bg-blue-500 rounded-lg text-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                            onClick={() => addMultipleVehicles()}
                          >
                            Proceed{">>"}
                            {isLoading && <Loader />}
                          </button>
                        </>
                      ) : (
                        <button
                          className={`bg-blue-500 rounded-lg text-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                          onClick={handleSubmit(review)}
                          // onClick={review}
                        >
                          Next{">>"}
                          {isLoading && <Loader />}
                        </button>
                      )}
                    </div>
                  </div>
                  {vehicleCount === "multiple" &&
                    multipleVehicleDetails &&
                    multipleVehicleDetails.length >= 1 && (
                      <>
                        <div className="vehicle-table">
                          <table id="reconTable">
                            <thead>
                              <tr>
                                <th>S/No.</th>
                                <th>Licence Plate</th>
                                <th>Chassis Number</th>
                                <th>Engine Number</th>
                                <th>Manufacturer</th>
                                <th>Model</th>
                                <th>Vehicle Type</th>
                                <th>Year</th>
                                <th>Vehicle Usage</th>
                                <th>Color</th>
                                <th>Source Of Vehicle</th>
                                <th>Request State</th>
                                <th>Mla</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {multipleVehicleDetails.map((entry, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{entry.licencePlate}</td>
                                    <td>{entry.chassisNumber}</td>
                                    <td>{entry.engineNumber}</td>
                                    <td>{entry.manufacturer}</td>
                                    <td>{entry.model}</td>
                                    <td>{entry.vehicleType}</td>
                                    <td>{entry.year}</td>
                                    <td>{entry.vehicleUsage}</td>
                                    <td>{entry.color}</td>
                                    <td>{entry.sourceOfVehicle}</td>
                                    <td>{entry.requestState}</td>
                                    <td>{entry.mla}</td>
                                    <td>
                                      <button
                                        className={`bg-red-500 rounded-lg text-lg mb-1 text-center cursor-pointer mt-1 text-gray-100 px-3 py-1`}
                                        onClick={() => removeItem(index)}
                                      >
                                        Remove &#8722;
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <button
                            className={`bg-red-500 rounded-lg text-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                            onClick={clearAllItems}
                          >
                            Reset
                          </button>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
        <VehicleInformationPreviewModal title="CMR Motor Vehicle Information Request Review" />
      </div>
    </div>
  );
}