import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import Swal from "sweetalert2";
import axios from "axios";
import { removeSpaces } from "../utils/data_transform";
import { generate_trans_ref } from "../utils/generate_trans_ref";
import { useUploadValidator } from "../utils/customHooks/uploadValidator";
import { useAgent } from "../store/AgentStore";
import { useInvoiceStore } from "../store/InvoiceStore";
import {
  states as stateUrl,
  base,
  logout_cmris_agent,
  get_new_owner_details,
  change_of_ownership,
  cow_file_upload,
  get_product_details,
} from "../constants/urls";
import { useAuth } from "../store/Auth";
import { useUserStore } from "../store/UserStore";
import { useVehicleDetails } from "../store/VehicleStore";
import { useProductStore } from "../store/ProductStore";
import { useOwnerStore } from "../store/OwnerStore";
import { useFormStep } from "../store/FormStepStore";
import { useReportDetails } from "../store/ReportStore";
import { useCompanyVehicleDetails } from "../store/CompanyVehicleStore";
import { formatDate } from "../utils/date_format";
import { useRef } from "react";
import { useModal } from "../store/ModalState";
import CowTokenVerification from "../components/CowTokenVerificationModal";
import sendToken from "../components/sendToken";

export default function ChangeOfOwnership() {
  const [showModal, setShowModal] = useState(false);
  const validImage = useUploadValidator();
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  const getProduct = useProductStore((state) => state.product);
  const [states, setStates] = useState([]);
  const [response, setResponse] = useState("");
  const [NewOwnerId, SetNewOwnerId] = useState("");
  const getAgent = useAgent((state) => state.agentData);
  const [request_state, setRequestState] = useState("");
  const [document_title, setDocumentTitle] = useState("affidavit");
  const [imageDetails, setImageDetails] = useState({
    size: "",
    validated: false,
    message: "",
  });
  const [accountDetails, setAccountDetails] = useState({
    vehicle_id: vehicleDetails.id,
    requestState: "",
    accountType: "",
    identificationType: "",
    identificationValue: "",
    documentType: "",
    // document: "",
  });
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const getAuth = useAuth((state) => state.auth);
  const setAuth = useAuth((state) => state.setAuth);
  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);

  const resetAuth = useAuth((state) => state.reset);
  const resetUserDetails = useUserStore((state) => state.reset);
  const resetVehicleDetails = useVehicleDetails((state) => state.reset);
  const resetProductDetails = useProductStore((state) => state.reset);
  const resetOwnerDetails = useOwnerStore((state) => state.reset);
  const resetFormStep = useFormStep((state) => state.reset);
  const resetReportDetails = useReportDetails((state) => state.reset);
  const resetInvoice = useInvoiceStore((state) => state.reset);
  const clearVehicles = useCompanyVehicleDetails(
    (state) => state.clearAllItems
  );

  const {
    register,
    formState: { errors },
  } = useForm();

  const token = getAuth.token;

  const Logout = async () => {
    // let token = getAuth.token;
    let url = base + logout_cmris_agent;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        // console.log("Logged Out");
        resetUserDetails();
        resetVehicleDetails();
        resetProductDetails();
        resetOwnerDetails();
        resetFormStep();
        resetReportDetails();
        resetAuth();
        resetInvoice();
        clearVehicles();
        window.location = "/signup";
      });
  };

  useEffect(() => {
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
        // console.log(states);
      });
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   let cowUrl = base + change_of_ownership;
  //   let prodUrl = base + get_product_details + getProduct;
  //   let fileUrl = base + cow_file_upload;

  //   console.log(cowUrl);
  //   console.log(prodUrl);
  //   console.log(fileUrl);

  //   //get the product details first
  //   fetch(prodUrl, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((resp) => {
  //       let respData = resp.data;
  //       console.log(respData.price);

  //       let referenceNumber =
  //         respData.product_code + generate_trans_ref().toString();

  //       let data = {
  //         vehicle_id: vehicleDetails.id,
  //         new_owner_id: NewOwnerId,
  //         reference: referenceNumber,
  //         registered_by: getAgent.agent,
  //         agent_id: getAgent.id,
  //         amount: respData.price,
  //         payment_status: "Unpaid",
  //         request_state: request_state,
  //       };
  //       console.log(data);
  //       fetch(cowUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then((response) => response.json())
  //         .then((jsonResp) => {
  //           console.log(jsonResp);
  //           if (jsonResp.statusCode == "0") {
  //             let cowId = jsonResp.data.COWId;
  //             let responseData = jsonResp.data;
  //             let invoice = responseData.invoice;
  //             setInvoice({
  //               firstName: invoice.first_name,
  //               middleName: invoice.middle_name,
  //               lastName: invoice.last_name,
  //               address: invoice.address,
  //               email: invoice.email,
  //               phone: invoice.phone,
  //               narration: invoice.narration,
  //               amount: invoice.amount,
  //               transactionId: invoice.trans_ref,
  //               invoiceNumber: invoice.invoice_number,
  //               initiatedDate: formatDate(invoice.initiated_date),
  //               status: invoice.status,
  //               productCode: invoice.product_code,
  //               rrr: invoice.rrr,
  //               vin: invoice.vin,
  //               make: invoice.make,
  //               licenceNumber: invoice.license,
  //             });
  //             setStep(6);
  //             console.log(invoice.amount + ".00");
  //             const formData = new FormData();
  //             formData.append("id", cowId);
  //             formData.append("document_title", document_title);
  //             // formData.append("cow_document", document);
  //             const cow_document =
  //               window.document.getElementById("cow_document");
  //             formData.append("cow_document", cow_document?.files[0], document);
  //             fetch(fileUrl, {
  //               method: "POST",
  //               headers: {
  //                 Authorization: "Bearer " + token,
  //               },
  //               body: formData,
  //             })
  //               .then((resp) => {
  //                 console.log("File upload response: ", resp);
  //                 return resp.json();
  //               })
  //               .then((respJson) => {
  //                 // window.location = "/invoice";
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //               });
  //           } else if (jsonResp.statusCode == "5") {
  //             Swal.fire({
  //               title: "Error!",
  //               text: "You already made a request to change ownership of this vehicle",
  //               icon: "error",
  //               confirmButtonText: "Ok",
  //               confirmButtonColor: "#1C89DE",
  //             }).then(() => {
  //               window.location = "/requests";
  //             });
  //           }
  //         });
  //     });
  //   // fetch(url, {
  //   //   method: "POST",
  //   //   headers: {
  //   //     "Content-Type": "application/json",
  //   //     Authorization: "Bearer " + token,
  //   //   },
  //   //   body: JSON.stringify(accountDetails),
  //   // })
  //   //   .then((response) => {
  //   //     console.log(response);
  //   //     return response.json();
  //   //   })
  //   //   .then(({ data }) => {
  //   //     setAccountDetails();
  //   //     setStates();
  //   //     console.log(data);
  //   //     Swal.fire({
  //   //       title: "Success!",
  //   //       text: "Submitted",
  //   //       icon: "success",
  //   //       confirmButtonText: "Ok",
  //   //       confirmButtonColor: "#1C89DE",
  //   //     });
  //   //     setInvoice();
  //   //     setStep(6);
  //   //     window.location = "/invoice";
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //     let message;
  //   //     if (err && err.response) {
  //   //       const data = err.response;
  //   //       message = data.message;
  //   //     }
  //   //     Swal.fire(message || "Unable to complete request");
  //   //     setLoading(false);
  //   //   });
  //   return;
  // };

  //get owner Details
  // let token = getAuth.token;
  let url =
    base +
    get_new_owner_details +
    `?use=${accountDetails?.identificationType}&id=${accountDetails?.identificationValue}`;
  const fetchDetails = async () => {
    setLoading(true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(({ data }) => {
        // console.log(data);
        setResponse(data);
        SetNewOwnerId(data.id);
        // console.log(data);
        // setAccountDetails({ ...accountDetails, ["new_owner_id"]: data.id });
        setLoading(false);
      })
      .catch((err) => {
        let message;

        if (err && err.response) {
          const { data } = err.response;
          message = data.message;
        }
        // console.log(err);
        setLoading(false);
      });
  };

  //upload Image
  const uploadRef = useRef();
  const handleFile = (e) => {
    const file = uploadRef.current.files[0];
    let validated = validImage(
      file,
      ["image/jpeg", "image/png", "application/pdf"],
      250
    );
    // console.log(file);
    // console.log(validated);
    setImageDetails({
      size: validated.fileSize + " kb",
      validated: validated.valid,
      message: validated.valid ? "Passed" : "Failed",
    });

    if (validated.valid) {
      setDocument(URL.createObjectURL(file));
    } else {
      setDocument("");
      return;
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAccountDetails({ ...accountDetails, [name]: value });
  };

  const handleModal = (e) => {
    e.preventDefault();

    //Fire Token
    sendToken("sms", response.phone, response.email);
    setShowModal(!showModal);
  };

  if (getAuth.authenticated === false && getAuth.token === "") {
    window.location = "/signup";
    return;
  }
  return (
    <div className="bg-gray-200 h-screen">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="lg:pt-8 pt-4 bg-gray-200 pb-8">
        <div className="max-w-xl mx-auto items-center">
          <button
            onClick={() => {
              window.history.back();
            }}
            className={`ml-4 bg-blue-500 flex items-center rounded-lg mb-6 text-center cursor-pointer mt-3 text-gray-100 px-1 py-1`}
          >
            {"<"} Back
          </button>
          <form
            onSubmit={handleModal}
            className="px-10 mx-4 py-12 rounded-lg shadow-lg backdrop-blur-xl bg-white/50"
          >
            <div className="text-center text-xl pb-2 font-bold border-b-2">
              New Owner ID Verification
            </div>
            <label
              htmlFor="acc_type"
              className="font-semibold block mt-6 text-gray-900 text-md mb-1 font-sans"
            >
              Profile
              <span className="text-red-500 text-lg font-semibold"> *</span>
            </label>
            <label className="text-sm">
              <input
                onChange={handleChange}
                type="radio"
                className="mr-2 mb-4"
                name="accountType"
                id="existing"
                value="existing"
                required={true}
              />
              Existing Profile
            </label>

            <label className="text-sm">
              <input
                onChange={handleChange}
                type="radio"
                className="ml-8 mr-2 mb-4"
                name="accountType"
                id="new"
                value="new"
                required={true}
              />
              New Profile
            </label>
            {accountDetails?.accountType === "new" && (
              <div>
                Visit &nbsp;
                <span
                  onClick={Logout}
                  className="border-b border-blue-500 text-blue-500"
                >
                  https://cmris.npf.gov.ng/signup
                </span>
                &nbsp; to create a Profile.
              </div>
            )}
            {accountDetails?.accountType === "existing" && (
              <div>
                <div className="xl:flex-1">
                  <label
                    htmlFor="request_state"
                    className="font-semibold block mt-3 text-gray-900 text-xl mb-1 font-sans"
                  >
                    State Requesting From
                  </label>
                  <select
                    {...register("request_state", { required: true })}
                    // value={states.request_state}
                    onChange={(e) => setRequestState(e.target.value)}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-sm mt-1 hover:border-green-800 py-1 px-2 border border-gray-600"
                  >
                    <option value="">Select State</option>
                    {states.map((val, index) => {
                      return (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                  {errors.request_state?.type === "required" && (
                    <p role="alert" className="text-md text-red-500">
                      State of request is required
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="typeOfId"
                    className="block text-gray-900 text-md mt-3 font-semibold"
                  >
                    Search New Owner Details{" "}
                    <span className="text-red-500 text-lg font-semibold">
                      {" "}
                      *
                    </span>
                  </label>
                  <select
                    required={true}
                    name="identificationType"
                    value={accountDetails?.identificationType}
                    onChange={handleChange}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-sm mt-1 hover:border-green-800 py-1 px-2 border border-gray-600"
                  >
                    <option value="">Select Search ID</option>
                    <option value="phone">Phone Number</option>
                    <option value="email">Email</option>
                    <option value="nin">
                      National Identification Number (NIN)
                    </option>
                    <option value="tin">Tax Identification Number</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="idNumber"
                    className="block text-gray-900 text-md font-semibold"
                  >
                    {accountDetails?.identificationType === "phone" &&
                      "Enter Phone Number"}
                    {accountDetails?.identificationType === "tin" &&
                      "Enter Tax Identification Number"}
                    {accountDetails?.identificationType === "nin" &&
                      "Enter National Identification Number"}
                    {accountDetails?.identificationType === "email" &&
                      "Enter Email"}
                    <span
                      className={`${
                        accountDetails?.identificationType === "" && "hidden"
                      } text-red-500 text-lg font-semibold`}
                    >
                      {" "}
                      *
                    </span>
                  </label>
                  <input
                    required={true}
                    name="identificationValue"
                    value={accountDetails?.identificationValue}
                    onChange={handleChange}
                    onBlur={fetchDetails}
                    type="text"
                    className={`${
                      accountDetails?.identificationType === "" && "hidden"
                    } w-full bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600`}
                  />
                  <div
                    className={`${
                      accountDetails.identificationType === "" && "hidden"
                    } ${accountDetails.identificationValue === "" && "hidden"}
                    flex justify-start`}
                  >
                    {loading ? (
                      "Loading..."
                    ) : (
                      <div className="text-2xl font-semibold text-green-900">
                        <p
                          className={`${
                            response.firstname === undefined &&
                            response.last_name === undefined &&
                            response.last_name === undefined &&
                            "hidden"
                          }`}
                        >
                          {response.last_name +
                            " " +
                            response.first_name +
                            " " +
                            response.middle_name}{" "}
                          {response.gender}
                          {response.presemt_address || ""}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div>
                    <label
                      htmlFor="typeOfId"
                      className="block text-gray-900 text-md mt-3 font-semibold"
                    >
                      Document Type{" "}
                      <span className="text-red-500 text-lg font-semibold">
                        {" "}
                        *
                      </span>
                    </label>
                    <select
                      required={true}
                      name="documentTitle"
                      // value={accountDetails?.documentType}
                      onChange={(e) => setDocumentTitle(e.target.value)}
                      className="w-full mb-4 bg-white rounded-lg h-10 text-sm mt-1 hover:border-green-800 py-1 px-2 border border-gray-600"
                    >
                      <option value="">Select Document type</option>
                      <option value="affidavit">Affidavit</option>
                      <option value="agreement">Deed</option>
                      <option value="receipt">Receipt</option>
                      <option value="agreement">Sales Agreement</option>
                    </select>
                  </div>
                  {/* {document_title} */}
                </div>
                <div>
                  <label
                    htmlFor="idNumber"
                    className="block text-gray-900 text-md font-semibold"
                  >
                    Upload Document
                    <span className="text-red-500 text-lg font-semibold">
                      {" "}
                      *
                    </span>
                  </label>
                  <input
                    ref={uploadRef}
                    required={true}
                    name="document"
                    onChange={handleFile}
                    type="file"
                    id="cow_document"
                    accept="image/*, application/pdf"
                    className="mb-1 mt-1 text-gray-800 leading-loose"
                  />
                </div>
                <span
                  className={`${
                    imageDetails.validated ? "text-green-700" : "text-red-600"
                  }`}
                >
                  {imageDetails.size} {imageDetails?.message}{" "}
                  {imageDetails.size <= 250 && imageDetails.validated == false
                    ? "You can only upload jpg, png and pdf file"
                    : ""}
                </span>

                <button
                  type="submit"
                  className="bg-blue-500 w-full py-2 font-bold mt-4 rounded-lg text-white  hover:bg-blue-900 text-md"
                  disabled={loading}
                >
                  Continue
                </button>
              </div>
            )}
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
      {showModal && (
        <CowTokenVerification
          handleModal={handleModal}
          showModal={showModal}
          setShowModal={setShowModal}
          response={response}
          newOwner={NewOwnerId}
          requestState={request_state}
        />
      )}
    </div>
  );
}
