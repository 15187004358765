import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { base, get_valid_certificate } from "../constants/urls";
import { formatDateNew } from "../utils/date_format";
import Loader from "./Loader";

export default function CertificateCheckComponent() {
  const { id } = useParams();
  const { product } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const errorFunc = (message = "Unable to fetch certificate information") => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#1C89DE",
    });
  };

  const fetchRequest = async () => {
    // const url = `${base}${get_valid_certificate}+"/"+product${id}`;
    const url = base+get_valid_certificate+product+"/"+id
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data, statusCode } = await response.json();
      setData(data);
      setLoading(false);
      if (statusCode === 3) {
        errorFunc("Cerificate not found");
      }
    } catch (error) {
      errorFunc();
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  if (loading) {
    return <Loader />;
  } else if (!loading && !data.status) {
    return <div></div>;
  }

  return (
    <div className="h-[80vh] overflow-auto md:overflow-hidden">
      <div className="max-w-2xl mx-auto  p-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-900">
          Certificate Validation
        </h1>
      </div>
      <div className="px-6 pb-6">
        <div className="bg-white shadow-md rounded-md px-6 py-5 max-w-2xl mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-bold">Details - {data.cert_type}</h1>
            <span className="bg-green-500 text-white rounded-full py-1 px-2 text-sm">
              {data.status}
            </span>
          </div>

          <table className="max-w-lg w-full md:text-lg text-xs">
            <tbody>
              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Name of Owner:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.name || "N/A"}
                </td>
              </tr>
              {/* <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Address:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.owner_address || "N/A"}
                </td>
              </tr> */}
              {data.cert_type == "Motor Vehicle Change of Ownership" && (
                <>
                  <tr className="">
                    <th scope="row" class="py-1 px-2 font-normal text-left">
                      Previous Owner:
                    </th>
                    <td className="py-1 px-2 font-medium text-left">
                      {data.previous_owner || "N/A"}
                    </td>
                  </tr>
                </>
              )}

              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Licence Plate Number:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.license_plate || "N/A"}
                </td>
              </tr>
              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Make:
                </th>
                <td className="py-1 px-2 font-medium text-left">{data.make}</td>
              </tr>
              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Model:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.model}
                </td>
              </tr>

              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Type:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.vehicle_type || "N/A"}
                </td>
              </tr>

              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Colour:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.color}
                </td>
              </tr>

              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Certificate Number:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.cert_number || "N/A"}
                </td>
              </tr>
              <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Issue Date:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.issued_date ? formatDateNew(data.issued_date) : "N/A"}
                </td>
              </tr>
              {/* <tr className="">
                <th scope="row" class="py-1 px-2 font-normal text-left">
                  Renewal Date:
                </th>
                <td className="py-1 px-2 font-medium text-left">
                  {data.expiry_date ? formatDateNew(data.expiry_date) : "N/A"}
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
