import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { base, get_states_numbers } from "../constants/urls";
import Swal from "sweetalert2";
export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);

  const errorFunc = () => {
    Swal.fire({
      title: "Error!",
      text: "Unable to get states contacts",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#1C89DE",
    });
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  const fetchRequest = async () => {
    const url = base + get_states_numbers;
    setLoading(true);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setApiData(data?.data);
      setLoading(false);
      if (!data?.data) {
        errorFunc();
      }
    } catch (error) {
      errorFunc();
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto mt-12">
      {/* <div className="font-sans text-3xl text-gray-700">
        Get In Touch With Us
      </div> */}
      {/* <div className="xl:flex pb-10"> */}
      <div className="pb-10">
        <div className="xl:flex-1 rounded border border-gray-800 mt-12 max-h-72"></div>
        <div className="xl:flex-1 mx-12 mt-11">
          <div className=" text-xl font-sans text-gray-800 mb-5 text-center">
            CONTACT PHONE NUMBER FOR DIFFERENT STATES
          </div>

          <div className="relative overflow-x-auto">
            {loading ? (
              <div className="my-20 text-center ">
                <ClipLoader
                  color={"#166434"}
                  size={30}
                  aria-label="Loading Spinner"
                />
                <p className="mt-2 text-gray-500">Loading...</p>
              </div>
            ) : (
              <table className="max-w-full w-full md:text-lg text-xs border rounded">
                <thead>
                  <tr className="text-gray-600">
                    <th className="py-4 px-2 bg-blue-100 text-left">Centre</th>
                    <th className="py-4 px-2 bg-blue-100 text-left whitespace-nowrap">
                      Phone Number
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {apiData.map((centre, i) => {
                    return (
                      <tr className="even:bg-blue-100" key={i}>
                        <td className="py-4 px-2 font-normal text-base text-left">
                          {centre.name}
                        </td>
                        <td className="py-4 px-2 font-normal text-base text-left">
                          {centre.phone}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
