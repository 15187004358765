import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  showModal: false,
  setShowModal: (state) =>
    set(
      produce((temp) => {
        temp.showModal = state;
      })
    ),
  reset: (state) => set(state),
});
// store = devtools(store);
// store = persist(store, { name: "modal" });
export const useModal = create(store);
