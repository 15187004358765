import React from "react";
import Organization from "../components/Organization";
import OrganizationRepProfile from "../components/OrgRepProfile"

export default function OrganizationProfile() {
  return (
    <>
      <Organization />
    </>
  );
}
