import React from "react";
import ConfirmPhoneAndEmail from "../components/ConfirmPhoneAndEmail";

export default function PhoneAndEmailConfirmation() {
  return (
    <>
      <ConfirmPhoneAndEmail />
    </>
  );
}
