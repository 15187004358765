import { Link, useParams } from "react-router-dom";
import {
  base,
  edit_vehicle_by_id,
  update_vehicle,
  states as stateUrl,
  manufacturers as manufacturesUrl,
  models as modelsUrl,
} from "../constants/urls";
import React, { useEffect, useState } from "react";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import validator from "validator";
import { useVehicleDetails } from "../store/VehicleStore";
import { useForm } from "react-hook-form";

export default function EditRequest() {
  const getAuth = useAuth((state) => state.auth);
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [states, setStates] = useState([]);
  const { id } = useParams();
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  const setVehicleDetails = useVehicleDetails(
    (state) => state.setVehicleDetails
  );

  useEffect(() => {
    let token = getAuth.token;
    let url = base + edit_vehicle_by_id + `${id}`;
    const fetchRequest = async () => {
      setLoading(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          setRequest(data);
          setLoading(false);
          // console.log({data})
        })
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch Details");
          setLoading(false);
        });
    };
    fetchRequest();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setRequest((values) => ({ ...values, [name]: value || "" }));
  };

  const { register, reset } = useForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = getAuth.token;
    let url = base + update_vehicle + `${id}`;
    setLoading(true);
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        response.json();
        console.log(response);
        Swal.fire({
          title: "Success!",
          text: "Request Submitted",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        }).then(() => {
          window.location = "/requests";
        });
      })
      .then((data) => {
        setRequest(data);
        console.log(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        let message;

        if (err && err.response) {
          const data = err.response;
          message = data.message;
        }

        Swal.fire(message || "Unable to update vehicle Details");
        setLoading(false);
      });
  };

  useEffect(() => {
    setVehicleDetails({});
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  useEffect(() => {
    let url = base + manufacturesUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setManufacturers(resJson.data);
      });
  }, []);

  useEffect(() => {
    var max = new Date().getFullYear();
    var min = 1980;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    setYears(years);
  }, []);

  useEffect(() => {
    getModels(vehicleDetails.manufacturer);
  }, [vehicleDetails.manufacturer]);

  const getModels = async () => {
    const url = base + modelsUrl + vehicleDetails.manufacturer;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setModels(resJson.data);
        }
      });
  };
  return (
    <div className="bg-gray-100 mb-10">
      <div className="text-2xl pt-4 pb-4 flex justify-center print:hidden bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="max-w-5xl mx-auto mt-4 shadow-lg px-8 pt-10 pb-4 bg-white">
        <div className="mb-8 hidden print:block">
          <img src="/logodark.png" alt="Police Logo" className="h-100 w-100" />
        </div>
        {/* <p className="text-xl border-b pb-2 mb-4 font-bold">
          Request Information
        </p> */}
        <form onSubmit={handleSubmit}>
          <div className="mb-6 border-b pb-4">
            <h3 className="text-md py-2 font-semibold px-2 bg-blue-200">
              Review Vehicle Details
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 print:grid-cols-3 gap-4 py-4 px-4">
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="licence_plate"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Licence Plate
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="license_plate"
                      type="text"
                      defaultValue={request?.license_plate || ""}
                      placeholder={"Licence Plate"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="email"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Chasis number
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="chassis_number"
                      type="text"
                      defaultValue={request?.chassis_number || ""}
                      placeholder={"Chasis Number"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="engine_number"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Engine Number
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="engine_number"
                      type="text"
                      defaultValue={request?.engine_number || ""}
                      placeholder={"Engine Number"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="make"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Make
                    </label>
                    <select
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="manufacturer"
                      type="text"
                      defaultValue={request?.manufacturer || ""}
                      placeholder={"Make"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="model"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Model
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="model"
                      type="text"
                      defaultValue={request?.model || ""}
                      placeholder={"Model"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="type"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Type
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="vehicle_type"
                      type="text"
                      defaultValue={request?.vehicle_type || ""}
                      placeholder={"Vehicle Type"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="year"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Year
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="year"
                      type="text"
                      defaultValue={request?.year || ""}
                      placeholder={"Year"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="color"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Color
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="color"
                      type="text"
                      defaultValue={request?.color || ""}
                      placeholder={"Color"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="usage"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Vehicle Usage
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="vehicle_usage"
                      type="text"
                      defaultValue={request?.vehicle_usage || ""}
                      placeholder={"Vehicle Usage"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="source"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Vehicle Source
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                             
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="vehicle_source"
                      type="text"
                      defaultValue={request?.source_of_vehicle || ""}
                      placeholder={"Vehicle Source"}
                    />
                  </div>
                </dd>
              </div>
              <div className="flex space-x-3 items-center">
                <dd className="text-sm md:text-md text-gray-900 font-bold">
                  <div className="pb-4">
                    <label
                      htmlFor="licence_state"
                      className="font-semibold text-gray-700 block pb-1"
                    >
                      Licence State
                    </label>
                    <input
                      onChange={handleChange}
                      className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                      name="mla"
                      type="text"
                      defaultValue={request?.mla || ""}
                      placeholder={"Licence State"}
                    />
                  </div>
                </dd>
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-3">
            <Link
              className="print:hidden flex text-md justify-center"
              to="/requests"
            >
              <button className="flex print:hidden items-center mt-4 justify-center border px-4 py-1 text-white font-semibold border-blue-500 rounded bg-blue-500">
                Back
              </button>
            </Link>
            <button
              type="reset"
              className="flex print:hidden items-center mt-4 justify-center space-x-1 border px-3 py-1 rounded text-white bg-green-800"
            >
              <p>Reset</p>
            </button>
            <button
              type="submit"
              className="flex print:hidden items-center mt-4 justify-center space-x-1 border px-3 py-1 rounded text-white bg-green-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
