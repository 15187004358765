import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  repDetails: {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    idType: "",
    pulledEmail: "",
    pulledPhone: "",
    pulledAddress: "",
    dateOfBirth: "",
    email: "",
    phone: "",
    profileType: "",
    idNumber: "",
    verified: 0,
  },

  setRepDetails: (rep_details) =>
    set(
      produce((temp) => {
        //logic here
        temp.repDetails = {
          id: rep_details.id,
          firstName: rep_details.firstName,
          middleName: rep_details.middleName,
          lastName: rep_details.lastName,
          email: rep_details.email,
          phone: rep_details.phone,
          password: rep_details.password,
          profileType: rep_details.profileType,
          idType: rep_details.idType,
          idNumber: rep_details.idNumber,
          pulledAddress: rep_details.pulledAddress,
          verified: rep_details.verified,
          gender: rep_details.gender,
          dateOfBirth: rep_details.dateOfBirth,
          pulledPhone: rep_details.pulledPhone,
          pulledEmail: rep_details.pulledEmail,
        };
      })
    ),

  reset: (repDetails) => set(repDetails),
});
// store = devtools(store);
store = persist(store, { name: "repDetails" });
export const useOrgRepStore = create(store);
