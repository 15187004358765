import { create } from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";

let initialState = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  idType: "",
  pulledEmail: "",
  pulledPhone: "",
  pulledAddress: "",
  dateOfBirth: "",
  email: "",
  phone: "",
  address: "",
  profileType: "",
  idNumber: "",
  pulledState: "",
  pulledLG: "",
  currentState: "",
  currentLG: "",
  verified: 0,
};

let store = (set) => ({
  ownerDetails: initialState,

  setSingleOwnerDetails: (field, value) =>
    set(
      produce((draft) => {
        draft.ownerDetails[field] = value;
      })
    ),
  setMultipleOwnerDetails: (updatedDetails) =>
  set(
    produce((draft) => {
      // Update ownerDetails using spread operator
      draft.ownerDetails = { ...draft.ownerDetails, ...updatedDetails };
    })
  ),

    reset: () => {
      store.persist?.clearStorage(); // Use optional chaining
      set({ ownerDetails: initialState });
    }
});

// store = devtools(store); // Optional devtools middleware
store = persist(store, { name: "ownerDetails" });
export const useOwnerStore = create(store);
