// import OwnershipApprovedRequests from "../components/ApprovedRequestscoo";
import MySoldVehiclesTable from "../components/table/SoldVehiclesTable";

export default function MySoldVehicles() {
  return (
    <>
      <MySoldVehiclesTable />
    </>
  );
}
