import React, { useState } from "react";
import validator from "validator";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Loader from "./Loader";
import axios from "axios";
import { useUserStore } from "../store/UserStore";
import { useFormStep } from "../store/FormStepStore";
import { base, signin_cmris_agent } from "../constants/urls";
import { useOwnerStore } from "../store/OwnerStore";
import sendToken from "./sendToken";
import { useAuth } from "../store/Auth";
import EyeOpen from "./svg/eyeopen.svg";
import EyeClose from "./svg/eyeclose.svg";

export default function UserLogin() {
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);
  const setAuth = useAuth((state) => state.setAuth);
  const setStep = useFormStep((state) => state.setStep);
  const setOwnerInfo = useOwnerStore((state) => state.setOwnerDetails);
  const login_url = base + signin_cmris_agent;
  console.log(login_url);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const Login = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      password === undefined ||
      password === "" ||
      username === undefined ||
      username === ""
    ) {
      Swal.fire({
        title: "Error!",
        text: "Username and Password is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }

    const checkEmailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    let isEmail = checkEmailRegEx.test(username);
    let data = {
      username: username,
      password: password,
    };

    fetch(login_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(function (data) {
        // alert(JSON.stringify(data));
        let dataJson = data.data;
        // alert(dataJson.operation_state);
        // return;
        if (dataJson.statusCode === 0) {
          console.log(dataJson)
          setUserInfo({
            firstName: dataJson.first_name,
            middleName: dataJson.middle_name,
            lastName: dataJson.last_name,
            gender: dataJson.gender,
            idType: dataJson.id_type,
            address: dataJson.current_address,
            registeredAddress: dataJson.pulled_address,
            pulledEmail: dataJson.pulled_email,
            pulledPhone: dataJson.pulled_phone,
            email: dataJson.email,
            phone: dataJson.phone,
            profileType: dataJson.profile_type,
            idNumber: dataJson.id_number,
            currentResidentialState: dataJson.current_state,
            currentResidentialLGA: dataJson.current_lg,
            verified: dataJson.status === "Verified" ? 1 : 0,
            stateOfOperation: dataJson.operaton_state,
            accountTier: dataJson.account_type,
            superAgent: dataJson.super_agent
          });

          //set authentication
          setAuth({ token: dataJson.token, authenticated: true });

          //set step
          setStep(4);

          setIsLoading(false);
          if(dataJson.account_type=="1"){
            window.location = "/dashboard";
          }else{
            window.location = "/services";
          }
        } else if (dataJson.statusCode === 1) {
          Swal.fire({
            title: "Error!",
            text: "Wrong Username or Password",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
          setIsLoading(false);
          return;
        }
      });
  };

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="pt-8 bg-gray-200 h-screen">
        <div>
          <form className="flex justify-center items-center">
            <div className="px-10 py-6 rounded-lg shadow-lg backdrop-blur-xl bg-white/50">
              <div className="text-center text-2xl font-bold border-b-2 pb-3">
                Login
              </div>
              <label
                htmlFor="typeOfId"
                className="font-semibold block mt-6 text-gray-900 text-md mb-1 font-sans"
              >
                Username (Email or Phone Number){" "}
                <span className="text-red-500 text-md"> *</span>
              </label>
              <input
                type="text"
                {...register("username", { required: true })}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                className="w-full mb-4 rounded-lg h-10 text-sm mt-1 text-gray-800 leading-loose py-1 px-2 border border-gray-400 focus:border-1 focus:border-gray-300 focus:outline-none"
              />
              <label
                htmlFor="password"
                className="block text-gray-900 text-md mt-3 font-semibold"
              >
                Password
                <span className="text-red-500 text-lg font-semibold"> *</span>
              </label>
              <div className="flex justify-between w-full mb-4 rounded-lg h-10 text-sm mt-1 text-gray-800 leading-loose py-1 px-2 border border-gray-400">
                <input
                  type={showPassword ? "text" : "password"}
                  className="text-sm w-3/4 focus:border-1 focus:border-gray-300 focus:outline-none"
                  placeholder="Confirm your password"
                  {...register("password", { required: true })}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {showPassword ? (
                  <img
                    className="p-1"
                    src={EyeClose}
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                ) : (
                  <img
                    className="p-1"
                    src={EyeOpen}
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                )}
              </div>
              <button
                onClick={(e) => {
                  handleSubmit(Login(e));
                }}
                // onClick={Login()}
                className="bg-blue-500 w-full py-2 font-bold mt-4 rounded-lg text-white  hover:bg-blue-900 text-md"
              >
                Login
                {isLoading && <Loader />}
              </button>
              {/* <div className="mt-1 text-center text-sm">
                Don't have a profile?{" "}
                <a
                  href="/signup"
                  className={`font-semibold text-blue-800 cursor-pointer hover:text-gray-700`}
                >
                  Create profile
                </a>
              </div> */}
              <div className="text-center mt-3 text-sm">
                Forgot your password?{" "}
                <a
                  className="text-blue-800 cursor-pointer hover:text-gray-700"
                  href="/passwordreset"
                >
                  reset
                </a>
              </div>
            </div>
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
