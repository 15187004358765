import React, { useState } from "react";
import validator from "validator";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Loader from "./Loader";
import axios from "axios";
import { useUserStore } from "../store/UserStore";
import { useFormStep } from "../store/FormStepStore";
import {
  base,
  confirm_cmris_user_email,
  confirm_token,
  change_password,
} from "../constants/urls";
import { useAuth } from "../store/Auth";
import sendToken from "./sendToken";

export default function PasswordReset() {
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);
  const setAuth = useAuth((state) => state.setAuth);
  //   const setStep = useFormStep((state) => state.setStep);
  const [step, setStep] = useState(1);
  //   const setOwnerInfo = useOwnerStore((state) => state.setOwnerDetails);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [username, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setConfirmPassword] = useState("");

  const ConfirmToken = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let confirmTokenUrl = base + confirm_token + username + "/" + token;
    if (!validator.isURL(confirmTokenUrl)) {
      return;
    }
    axios({
      method: "get",
      url: confirmTokenUrl,
    }).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        if (response.data.statusCode === 0) {
          setIsLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Token Confirmed",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            setStep(3);
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Token Confirmation failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Error Confirming Token. Please Regenerate.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
      }
    });
  };

  const ChangePassword = (e) => {
    e.preventDefault();
    if (password !== c_password) {
      Swal.fire({
        title: "Error!",
        text: "Password must match.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }
    const url = base + change_password;
    let data = {
      password: password,
      c_password: c_password,
      email: username,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          Swal.fire({
            title: "Success!",
            text: "Password Set.",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            window.location = "/login";
          });
        }
      });
  };
  const ConfirmEmail = (e) => {
    e.preventDefault();
    let login_url = base + confirm_cmris_user_email + username;
    setIsLoading(true);
    if (username === undefined || username === "") {
      Swal.fire({
        title: "Error!",
        text: "Please input your email address",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }

    fetch(login_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(function (data) {
        if (data.statusCode === 0) {
          //send token then go to the next step
          sendToken("email", username);
          setStep(2);
          setIsLoading(false);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Profile with " + username + " not registered",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            setIsLoading(false);
          });
        }
      });
  };

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="lg:flex pt-8 bg-gray-100">
        <div className={`xl:flex-initial xl:w-2/6`}></div>
        <div className={`xl:flex-1 xl:px-1 xl:w-4/6 mx-4`}>
          <form>
            <div>
              <div className="text-center text-2xl font-bold">
                Reset/Create Password
              </div>
              {step === 1 && (
                <>
                  <label
                    htmlFor="email"
                    className="block text-gray-900 text-lg mt-3"
                  >
                    Email Address
                    <span className="text-red-500 text-lg"> *</span>
                  </label>

                  <input
                    type="text"
                    {...register("username", { required: true })}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                  />
                  <button
                    onClick={(e) => {
                      handleSubmit(ConfirmEmail(e));
                    }}
                    className="bg-blue-500 w-full py-2 font-bold mt-2 rounded-lg text-gray-100 text-lg"
                  >
                    Continue
                    {isLoading && <Loader />}
                  </button>
                </>
              )}

              {step === 2 && (
                <>
                  A token was sent to {username}
                  <label
                    htmlFor="token"
                    className="block text-gray-900 text-lg mt-3"
                  >
                    Token
                    <span className="text-red-500 text-lg"> *</span>
                  </label>
                  <input
                    type="text"
                    {...register("token", { required: true })}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                  />
                  <button
                    onClick={(e) => {
                      handleSubmit(ConfirmToken(e));
                    }}
                    className="bg-blue-500 w-full py-2 font-bold mt-2 rounded-lg text-gray-100 text-lg"
                  >
                    Confirm Token
                    {isLoading && <Loader />}
                  </button>
                </>
              )}

              {step === 3 && (
                <>
                  <label
                    htmlFor="password"
                    className="block text-gray-900 text-lg mt-3"
                  >
                    Password
                    <span className="text-red-500 text-lg"> *</span>
                  </label>
                  <input
                    type="password"
                    {...register("password", { required: true })}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                  />

                  <label
                    htmlFor="confirm_password"
                    className="block text-gray-900 text-lg"
                  >
                    Confirm Password
                    <span className="text-red-500 text-lg"> *</span>
                  </label>
                  <input
                    type="password"
                    {...register("confirm_password", { required: true })}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                  />
                  <button
                    onClick={(e) => {
                      handleSubmit(ChangePassword(e));
                    }}
                    className="bg-blue-500 w-full py-2 font-bold mt-2 rounded-lg text-gray-100 text-lg"
                  >
                    Change
                    {isLoading && <Loader />}
                  </button>
                </>
              )}

              {/* <label htmlFor="password" className="block text-gray-900 text-lg">
                Password
                <span className="text-red-500 text-lg"> *</span>
              </label>
              <input
                name="r_id_number"
                type="password"
                {...register("password", { required: true })}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
              /> */}
            </div>
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
