import React, { useEffect } from "react";
import ServiceSelection from "../components/ServiceSelection";
import { checkToken } from "../components/CheckToken";

export default function SelectServices() {
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <ServiceSelection />
    </>
  );
}
