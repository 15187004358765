import React, { useState } from "react";
import { useUserStore } from "../store/UserStore";
import { ClipLoader } from "react-spinners";
import RequestNameChange from "./RequestNameChange";
import RequestAddressChange from "./RequestAddressChange";
import { base, changepassword } from "../constants/urls";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
export default function ChangePassword() {
  const userDetails = useUserStore((state) => state.userDetails);
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  // const [showChangeOfName, setShowChangeOfName] = useState(false);
  // const [showChangeOfAddress, setShowChangeOfAddress] = useState(false);
  const [passcode, setPasscode] = useState("");
  const { password } = useParams();
  const [menu, setMenu]=useState("")
  const [inputs, setInputs] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });


  const ChangPassword = async (e) => {
    e.preventDefault()
    setLoading(true)
    const token = getAuth.token;
    if(inputs.new_password != inputs.confirm_password){
      Swal.fire({
        title: "Error!!!",
        text: "Password mismatch",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return
    }

    if(inputs.new_password == ""){
      Swal.fire({
        title: "Error!!!",
        text: "Password cannot be empty",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return
    }
    const url = `${base}${changepassword}`;
    const Method="PATCH"

    let data = {
      current_password: inputs.current_password,
      new_password: inputs.new_password,
      confirm_password: inputs.confirm_password
    };
    
    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonResponse) => {
        if(jsonResponse.statusCode == 0){
          Swal.fire({
            title: "Success!!!",
            text: jsonResponse.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(()=>{
            window.location.href = "/login"
          });
        }else{
          Swal.fire({
            title: "Error!!!",
            text: jsonResponse.message,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(()=>{
            setLoading(false)
          });
        }
      })
  }
  let data = {
    profile_type: userDetails.profileType,
    first_name: userDetails.firstName,
    middle_name: userDetails.middleName,
    last_name: userDetails.lastName,
    email: userDetails.email,
    phone: userDetails.phone,
    pulled_email: userDetails.pulledEmail,
    pulled_phone: userDetails.pulledPhone,
    pulled_address: userDetails.registeredAddress,
    current_state: userDetails.currentResidentialState,
    current_lg: userDetails.currentResidentialLGA,
  };

  
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmit = async (e) => {
  //   let token = getAuth.token;
  //   e.preventDefault();
  //   try {
  //     fetch(base + changepassword, {
  //       method: "PATCH",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify(passcode),
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then(({ data }) => {
  //         setPasscode(data);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     Swal.fire("unable to update password");
  //   }
  // };

  return (
    <div>
      {" "}
      
      <div className="pt-4">
        <div>
          <div>
            <div className="rounded-lg shadow bg-white">
              {/* <div className="flex justify-between items-center py-4 font-semibold mb-2">
                <h3 className="text-lg">CMRIS Assistant Profile</h3>
              </div> */}
              <div className="">
                {loading ? (
                  <div className="my-20 text-center">
                    <ClipLoader
                      color={"#166434"}
                      size={30}
                      aria-label="Loading Spinner"
                    />
                    <p className="mt-2 text-gray-500">Loading...</p>
                  </div>
                ) : (
                  <form>
                    <div className="pb-6">
                      <label
                        htmlFor="fullname"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Current Password
                      </label>
                      <input
                        className="
                      block           
                      w-full
                      lg:w-1/2
                      h-auto
                      p-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                        name="current_password"
                        type="password"
                        onChange={(e)=>{handleChange(e)}}
                      />
                    </div>
                    <div className="pb-4">
                      <label
                        htmlFor="phonenumber"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        New Password
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    lg:w-1/2
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="new_password"
                        type="password"
                        onChange={(e)=>{handleChange(e)}}
                      />
                    </div>
                    <div className="pb-4">
                      <label
                        htmlFor="email"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Confirm New Password
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    lg:w-1/2
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="confirm_password"
                        type="password"
                        onChange={(e)=>{handleChange(e)}}
                      />
                    </div>
                    {/* <div className="pb-4">
                      <label
                        htmlFor="Password"
                        className="font-semibold text-gray-700 block pb-1"
                      >
                        Password
                      </label>
                      <input
                        className="
                                    block           
                                    w-full
                                    h-auto
                                    p-2
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                    "
                        name="password"
                        type="password"
                        value={data?.passcode}
                        placeholder={"Enter new password"}
                      />
                    </div> */}
                    <button className="bg-green-600 rounded-lg p-2 text-gray-100 mb-3 w-full lg:w-16" 
                    onClick={(e)=>{
                      ChangPassword(e)
                      }}>Save</button>
                    <div className="mt-4">
                      {/* <input onClick={handleSubmit}
                  className="w-full text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  type="submit"
                  value="Update Profile"
                  disabled
                /> */}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
