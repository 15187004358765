import React from "react";
import ConfirmToken from "../components/ConfirmToken";

export default function TokenConfirmation() {
  return (
    <>
      <ConfirmToken />
    </>
  );
}
