import { base, send_token } from "../constants/urls";
import validator from "validator";
import axios from "axios";

export default function sendToken(channel, destination, auxiliary) {
  let tokenUrl =
    base + send_token + channel + "/" + destination + "/" + auxiliary;
  if (!validator.isURL(tokenUrl)) {
    return;
  }
  axios({
    method: "get",
    url: tokenUrl,
  }).then((response) => {
    if (response.status === 200) {
      // console.log("sent");
      return true;
    } else {
      // console.log("Not sent");
      return false;
    }
  });
}
