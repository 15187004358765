import React from "react";
import CertificateCheckComponent from "../components/CertificateCheckComponent";

export default function CertificateCheck() {
  return (
    <>
      <CertificateCheckComponent />
    </>
  );
}
