import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { COLUMNS } from "./Column";
import { useAuth } from "../../store/Auth";
import Swal from "sweetalert2";
import EmptyList from "../../components/empty-list-2.webp";
import { base, get_agent_cmr_requests } from "../../constants/urls";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { AiOutlinePlus, AiOutlineWarning } from "react-icons/ai";

export default function Table() {
  const { reference } = useParams();
  const [result, setResult] = useState([]);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => result, [result]);
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentRecords, setCurrentRecords] = useState(0)
  const [searchParams, setSearchParams] = useState();

  const navigate = useNavigate();

  const handleCellClick = (cell) => {
    if (
      cell.column.Header === "Action" &&
      cell.row.original.request_status === "Approved" &&
      cell.row.original.license_plate !== ""
    ) {
      if(cell.row.original.request_type === "Motor Vehicle Information") {
        navigate("/cert/" + cell.row.original.request_id);
      } else if(cell.row.original.request_type === "Motor Vehicle Change of Ownership"){
        navigate("/cowcert/" + cell.row.original.request_id);
      }else {}
      // cell.row.original.request_type === "Motor Vehicle Information"
      //   ? navigate("/certificate/" + cell.row.original.reference)
      //   : navigate("/cowcertificate/" + cell.row.original.reference);
    }
  };

  const goToNextPage = (page) => {
      setLoading(true);
      let token = getAuth.token;
      let url = base + get_agent_cmr_requests;
      fetch(url+"?page="+page, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          Array.isArray(data.records) ? setResult(data.records) : setResult([]);
          setTotalRecords(data.totalRecords)
          setTotalPages(data.totalPage);
          setCurrentPage(data.currentPage);
          setLoading(false);
        })
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch request");
          setLoading(false);
        });
  }

  const goToPreviousPage = (page) => {
    setLoading(true);
      let token = getAuth.token;
      let url = base + get_agent_cmr_requests;
      fetch(url+"?page="+page, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          
          Array.isArray(data.records) ? setResult(data.records) : setResult([]);
          setTotalRecords(data.totalRecords)
          setTotalPages(data.totalPage);
          setCurrentPage(data.currentPage);
          setLoading(false);
        })
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch request");
          setLoading(false);
        });
  }
  useEffect(() => {
    let token = getAuth.token;
    let url = base + get_agent_cmr_requests;
    const fetchRequest = async () => {
      setLoading(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          console.log(data);
          Array.isArray(data.records) ? setResult(data.records) : setResult([]);
          setTotalRecords(data.totalRecords)
          setTotalPages(data.totalPage);
          setCurrentPage(data.currentPage);
          setTotalRecords(data.totalRecords)
          setCurrentRecords(data.currentRecords)
          setLoading(false);
        })
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch request");
          setLoading(false);
        });
    };
    fetchRequest();
  }, []);

  result.forEach((results, index) => {
    results.serial = index + 1;
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    canNextPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  const handleSearch = ((e)=>{
    
  })

  useEffect(() => {
    setPageSize(50)
  }, [])

  return (
    <div className="bg-gray-100">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="mt-8 px-4 lg:px-16 bg-gray-100 shadow-xs">
        <div className="flex">
          <div className="flex-1 text-xl mt-2 font-semibold">Requests</div>
          <div className="flex justify-end items-center">
            <input type="text" 
              className="rounded-lg h-10 px-2 mr-2" 
              onChange={(e)=>{setSearchParams(e.target.value)}}
              onBlur={handleSearch}
            />
        
            {/* <Link
              to="/mysoldvehicles"
              className="cursor-pointer font-semibold text-sky-400 hover:text-sky-600 text-md mt-2 flex justify-between items-center mr-6 bg-gray-300 rounded-lg px-2 py-1"
            >
              <AiOutlineWarning className="mr-1" />
              My Sold Vehicles
            </Link> */}

            <Link
              to="/services"
              className="cursor-pointer font-semibold text-sky-400 hover:text-sky-600 text-md mt-2 flex justify-between items-center"
            >
              <AiOutlinePlus className="mr-1" />
              New Request
            </Link>
          </div>
        </div>
        <div className="py-3">
          <div className="mt-2 text-gray-500 space-y-5 overflow-x-scroll">
            <table
              {...getTableProps()}
              className="w-full border overflow-x-scroll"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="text-gray-600"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="py-2 px-2 text-md font-semibold text-left bg-blue-100"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loading ? (
                <tbody className="my-20 text-center">
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <ClipLoader
                        color={"#166434"}
                        size={30}
                        aria-label="Loading Spinner"
                      />
                      <p className="mt-2 text-gray-500">Loading...</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  {result.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          <p className="flex items-center justify-center flex-col">
                            <span className="text-center text-gray-500 my-8">
                              You do not have any request yet
                            </span>
                            <span className="py-4">
                              <img alt="empty" src={EmptyList} />
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody {...getTableBodyProps()} className="">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="even:bg-blue-100"
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="py-4 px-2 font-normal text-md"
                                  onClick={() => handleCellClick(cell)}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </>
              )}
            </table>
            <div className="flex justify-center sm:justify-between items-center flex-wrap">
              <div className="mb-2">
                <span>
                  Go to page:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    className="md:w-32 w-12 border border-gray-200 rounded mr-4  px-4 py-1"
                  />
                </span>
              </div>
              <div className="mb-2 flex justify-between items-center space-x-1">
              <button
                  className="disabled:bg-gray-100 disabled:text-gray-400 px-2 py-1 md:px-4 md:py-2 inline-flex items-center text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  onClick={() => goToPreviousPage(1)}
                  disabled={currentPage == 1 ? true:false}
                >
                  First
                </button>
                <button
                  className="disabled:bg-gray-100 disabled:text-gray-400 px-2 py-1 md:px-4 md:py-2 inline-flex items-center text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  onClick={() => goToPreviousPage(currentPage-1)}
                  disabled={currentPage == 1 ? true:false}
                >
                  Prev
                </button>
                <span className="">
                  Page {currentPage} of {totalPages}
                </span>

                <button
                  className="disabled:bg-gray-50 disabled:text-gray-400 inline-flex items-center px-2 py-1 md:px-4 md:py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  onClick={() => goToNextPage(currentPage+1)}
                  disabled={currentPage == totalPages ? true:false}
                >
                  Next
                </button>

                <button
                  className="disabled:bg-gray-50 disabled:text-gray-400 inline-flex items-center px-2 py-1 md:px-4 md:py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  onClick={() => goToNextPage(totalPages)}
                  disabled={currentPage == totalPages ? true:false}
                >
                  Last
                </button>
              </div>
              <div className="text-center mb-2">
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="w-32 border border-gray-200 rounded mr-4  px-4 py-1"
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option value={pageSize} key={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
