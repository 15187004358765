import React, { useEffect, useState } from "react";
import { useAuth } from "../store/Auth";
import {base, t1_current_balance, t1_total_earnings, t2_total_generated} from "../constants/urls";
import { number_format } from "../utils/number_format";

export default function Dashboard() {
  const [currentBalance, setCurrentBalance] =useState(0)
  const [totalGenerated, setTotalGenerated] = useState(0)
  const getAuth = useAuth((state) => state.auth);

  const getCurrentBalance = () => {
    const url = base + t1_current_balance;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
    }).then((response)=>{
      return response.json();
    }).then((jsonResp)=>{
      console.log("Current Balance: "+jsonResp.data)
      setCurrentBalance(jsonResp.data)
    })
  }

  const getEarnings = () => {
    const url = base + t1_total_earnings;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
    }).then((response)=>{
      return response.json();
    }).then((jsonResp)=>{
      console.log("Total Generated: "+jsonResp.data)
      setTotalGenerated(jsonResp.data)
    })
  }
  useEffect(() => {
    getCurrentBalance();
    getEarnings();

  })

  return (
    <div className="bg-gray-100">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="flex mx-6 mt-4">
        <div className="flex-1 w-full bg-blue-400 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Total Collection</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(totalGenerated, 2)}</div>
        </div>
        
        <div className="flex-1 w-full"></div>

        <div className="flex-1 w-full bg-yellow-900 text-gray-10 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Total Received</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(totalGenerated-currentBalance, 2)}</div>
        </div>
        
        <div className="flex-1 w-full"></div>
        
        <div className="flex-1 w-full bg-green-400 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Current Balance</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(currentBalance, 2)}</div>
        </div>
        
      </div>

      {/* <table width="97%" className="table mx-6 mt-3 border-collapse border border-slate-400">
        <thead className="bg-gray-200 text-gray-700 text-left">
          <tr>
            <th className="border border-slate-300">S/No.</th>
            <th className="border border-slate-300">Location</th>
            <th className="border border-slate-300">Number of Requests</th>
            <th className="border border-slate-300">Amount Earned</th>
          </tr>
        </thead>
        <tbody className="text-left">
        <tr>
            <td className="border border-slate-300">1</td>
            <td className="border border-slate-300">MLA Ikeja</td>
            <td className="border border-slate-300">5000</td>
            <td className="border border-slate-300">2,500,000.00</td>
          </tr>

          <tr>
            <td className="border border-slate-300">2</td>
            <td className="border border-slate-300">MLA Shomolu</td>
            <td className="border border-slate-300">3000</td>
            <td className="border border-slate-300">1,500,000.00</td>
          </tr>

          <tr>
            <td className="border border-slate-300">3</td>
            <td className="border border-slate-300">MLA Ojota</td>
            <td className="border border-slate-300">3000</td>
            <td className="border border-slate-300">1,500,000.00</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}
