import React, { useState, useEffect } from "react";
import validator from "validator";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import {
  states as stateUrl,
  base,
  edit_vehicle_by_id,
} from "../constants/urls";
import { useAuth } from "../store/Auth";
import { usenewPlateNumber } from "../store/changePlateNumber";
import { useVehicleDetails } from "../store/VehicleStore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ChangeofPlate() {
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const setVehicleDetails = useVehicleDetails(
    (state) => state.setVehicleDetails
  );
  const vehicleDetails = useVehicleDetails((state) => state.vehicleDetails);
  const setnewPlateNumber = usenewPlateNumber(
    (state) => state.setnewPlateNumber
  );
  const newPlateNumber = usenewPlateNumber((state) => state.newPlateNumber);

  useEffect(() => {
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="lg:pt-8 pt-4 bg-gray-200 h-screen">
        <div className="max-w-lg mx-auto items-center">
          <form className="px-10 mx-4 py-12 rounded-lg shadow-lg backdrop-blur-xl bg-white/50">
            <div className="text-center text-xl pb-2 font-bold border-b-2">
              Change Motor Vehicle Licence Plate Number
            </div>
            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              Enter the New Licence plate number
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <input
              value={newPlateNumber}
              onChange={(e) => {
                setnewPlateNumber(e.target.value.toUpperCase());
              }}
              required={true}
              className="w-full mb-4 bg-white rounded-lg text-sm mt-1 leading-loose hover:border-green-800 py-2 px-2 border border-gray-400"
            ></input>
            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              Select Request State
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <select
              value={vehicleDetails.requestState}
              onChange={(e) =>
                setVehicleDetails({
                  ...vehicleDetails,
                  requestState: e.target.value,
                })
              }
              className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
            >
              <option value="">Select State</option>
              {states.map((val, index) => {
                return (
                  <option key={index} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (newPlateNumber == "") {
                  toast.error("Please Enter the New Vehicle Plate Number");
                  return;
                } else if (!vehicleDetails.requestState) {
                  toast.error("Please Select a Request State");
                  return;
                }
                window.location = "/changeofplate/confirmation";
              }}
              className="bg-blue-500 w-full py-2 font-bold mt-8 rounded-lg text-gray-100 text-md"
            >
              {/* {!loading && "Continue"}
              {loading && <Loader />} */}
              Continue
            </button>
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
