import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormStep } from "../store/FormStepStore";
import { useUserStore } from "../store/UserStore";
import { mask_number } from "../utils/mask_number";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
import Swal from "sweetalert2";
import sendToken from "./sendToken";
import { base, confirm_token } from "../constants/urls";
import validator from "validator";
import axios from "axios";
import { useRepStore } from "../store/RepresentativeStore";

export default function ConfirmToken() {
  const { source } = useParams();
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const userDetail = useUserStore((state) => state.userDetails);
  const repDetails = useRepStore((state) => state.repDetails);
  const setUserInfos = useUserStore((state) => state.setUserDetails);
  const setRepInfo = useRepStore((state) => state.setRepDetails);
  const [token, setToken] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  // const setOtpChannel = useUserStore((state) => state.setOtpChannel);
  const getOtpChannel = useUserStore((state) => state.otpChannel);

  const userDetails = source === "rep" ? repDetails : userDetail;
  const setUserInfo = source === "rep" ? setRepInfo : setUserInfos;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onConfirmToken = (e) => {
    e.preventDefault();
    setIsVerifying(true);
    let confirmTokenUrl =
      base + confirm_token + userDetails.phone + "/" + token;
    if (!validator.isURL(confirmTokenUrl)) {
      return;
    }
    axios({
      method: "get",
      url: confirmTokenUrl,
    }).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        if (response.data.statusCode === 0) {
          setIsVerifying(false);
          Swal.fire({
            title: "",
            text: "Token Confirmed",
            icon: "success",
            confirmButtonText: "Continue",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            if (getOtpChannel === "sms" || getOtpChannel === "voice") {
              setStep(3);

              window.location = "/agent";
            } else {
              setStep(6);
            }
          });
        } else {
          setIsVerifying(false);
          Swal.fire({
            title: "Error!",
            text: "Token Confirmation failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      } else {
        setIsVerifying(false);
        Swal.fire({
          title: "Error!",
          text: "Error Confirming Token. Please Regenerate.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
      }
    });
  };

  if (step < 2) {
    window.location = "/signup";
  }
  return (
    <div>
      <div>
        <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
          CMR ASSISTANT PORTAL
        </div>

        <div className="flex justify-center text-center items-center pt-8 bg-gray-100 px-4">
          <div>
            <div>
              <p className="mt-5 text-gray-800 text-3xl mb-4 font-bold">
                Confirm Identity
              </p>
              {/* <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Confirm your Identity
                </p> */}
              <p className="text-center mt-6 text-red-600 text-lg">
                Verification token sent to
                {" " +
                  mask_number(userDetails.phone) +
                  " and " +
                  userDetails.email}
              </p>
              <label
                htmlFor="token"
                className="block text-gray-900 mt-3 text-lg"
              >
                Enter verification token
              </label>
              <input
                name="token"
                type="text"
                // {...register("token", { required: true })}
                onChange={(e) => setToken(e.target.value)}
                // {...register("idNumber", { required: true })}
                // className="w-5/6 h-12 text-lg bg-gray-100 mb-4 rounded-lg border-2 hover:border-green-800 py-1 px-2"
                className=" bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
              />
              <div className="text-center text-md mt-4">
                I did not receive a token
                <span
                  onClick={() => {
                    sendToken("voice", userDetails.phone);
                    Swal.fire({
                      title: "Successful!",
                      text: "Call initiated. Listen for the token",
                      icon: "success",
                      confirmButtonText: "Continue",
                      confirmButtonColor: "#1C89DE",
                    });
                  }}
                  className="ml-2 bg-blue-500 cursor-pointer text-gray-100 px-5 py-1 rounded-lg"
                >
                  {getOtpChannel === "sms" ? "Call me" : "Resend"}
                </span>
              </div>
              <button
                onClick={(e) => {
                  onConfirmToken(e);
                }}
                // className="w-full md:ml-12 uppercase rounded-lg bg-black text-white h-10"
                className="bg-blue-500 text-lg mt-4 w-full py-2 rounded-lg text-gray-100"
              >
                {!isVerifying && "Confirm Token"}
                {isVerifying && <Loader />}
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
