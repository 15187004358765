export const base = process.env.REACT_APP_SERVER_URL;
export const states = "/api/states";
export const lgs = "/api/lgs/";
export const manufacturers = "/api/manufacturers";
export const models = "/api/models/";
export const report = "/api/stolen/report";
export const upload = "/api/stolen/upload";
export const verify = "/api/id/verification/agent";
export const verify_cmris_user = "/api/id/verification/cmris";
export const send_token = "/api/otp/";
export const confirm_token = "/api/otp_confirm/";
export const reporter = "/api/reporter";
export const reporter_login = "/api/reporter/login/";
export const get_formations = "/api/formations/";
export const reporter_by_id = "/api/get_reporter/";
export const app_base = process.env.REACT_APP_CLIENT_URL;
export const register_cmris_agent = "/api/agent/register";
export const logout_cmris_agent = "/api/agent/logout";
export const signin_cmris_agent = "/api/agent/login";
export const confirm_cmris_user_email = "/api/cmris/user/";
export const change_password = "/api/cmris/password";
export const cmr_pre_entry = "/api/agent/precmrbyagent";
export const cmr_change_plate = "/api/cmris/changeofplate";
export const cmr_multiple_pre_entry = "/api/cmris/precmrs";
export const agent_nultiple_cmrinfo_requests = "/api/agent/precmrsbyagent";
export const services_pull = "/api/agent/products/";
export const get_product_details = "/api/agent/getproduct/";
export const get_invoice = "/api/agent/trans/invoice/";
export const get_receipt = "/api/agent/trans/receipt/";
export const confirm_payment = "/api/agent/verifytransaction/";
export const get_agent_cmr_requests = "/api/agent/agent_cmr_requests";
export const get_certificate = "/api/cmris/certificates";
export const get_cert = "/api/cmris/certificates_";
export const edit_password = "/api/cmris/changepassword/";
export const get_request_details = "/api/agent/showrequest";
export const get_states_numbers = "/api/locationcontacts";
export const get_valid_certificate = "/api/checkcert/";
export const edit_vehicle_by_id = "/api/cmris/getvehicle/";
export const agent_search_vehicle = "/api/agent/retrievevehicle";
export const update_vehicle = "/api/cmris/updatevehicle/";
export const get_approved_requests = "/api/cmris/myvehicles";
export const get_my_vehicles = "/api/cmris/myvehicles";
export const get_new_owner_details = "/api/agent/getcmrisuser";
export const change_of_ownership = "/api/cmris/changeofownership";
export const cow_file_upload = "/api/cmris/cow/upload";
export const remita_inline_url = process.env.REACT_APP_REMITA_INLINE_URL;
export const checktoken = "/api/agent/checktoken";
export const my_sold_vehicles = "/api/cmris/mysoldvehicles";
export const vehicle_types = "/api/vehicle_types/";
export const regenerate = "/api/agent/regenerate/";
export const t1_current_balance = "/api/agent/t1overalbalance/";
export const t1_total_earnings = "/api/agent/t1overalearnings/";
export const changepassword = "/api/agent/changepassword";
export const validate_user = "/api/agent/validateuser";