import React, { useState, useEffect } from "react";
import { useFormStep } from "../store/FormStepStore";
import { useUserStore } from "../store/UserStore";
import { mask_number } from "../utils/mask_number";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
import Swal from "sweetalert2";

export default function ConfirmPhoneAndEmail() {
  const userDetails = useUserStore((state) => state.userDetails);
  const setUserInfo = useUserStore((state) => state.setUserDetails);
  const [isCurrentPhoneNumber, setIsCurrentPhoneNumber] = useState("");
  const [isValidEmail, setIsValidEmail] = useState("");
  const [isValidAddress, setIsValidAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    // if (userDetails.pulledEmail === undefined) {
    setIsValidEmail("");
    // }

    // if (userDetails.pulledPhone === undefined) {
    setIsCurrentPhoneNumber("");
    // }
    setIsValidAddress("");
  }, []);

  useEffect(() => {
    if (isValidEmail === "yes") {
      setUserInfo({ ...userDetails, email: userDetails.pulledEmail });
      // console.log(userDetails);
      // alert(isValidEmail);
    }
    if (isCurrentPhoneNumber === "yes") {
      // alert(isValidEmail);
      setUserInfo({ ...userDetails, phone: userDetails.pulledPhone });
    }
    if (isValidAddress === "yes") {
      // alert(isValidEmail);
      setUserInfo({ ...userDetails, address: userDetails.registeredAddress });
    }
  }, [isCurrentPhoneNumber, isValidEmail, isValidAddress]);

  const handleChange = () => {};

  const onConfirmPhoneNumber = (data) => {
    setIsLoading(true);
    if (
      isCurrentPhoneNumber === "" ||
      isCurrentPhoneNumber === undefined ||
      isValidEmail === undefined ||
      isValidEmail === "" ||
      isValidAddress === undefined ||
      isValidAddress === ""
    ) {
      Swal.fire({
        title: "Select options to confirm Email and Phone Number",
        text: " ",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }
    if (userDetails.phone === undefined) {
      Swal.fire({
        title: "Error!",
        text: "User phone must be provided",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }
    if (userDetails.email === undefined) {
      Swal.fire({
        title: "Error!",
        text: "User Email must be provided",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }
    if (userDetails.registeredAddress === undefined) {
      Swal.fire({
        title: "Error!",
        text: "User Address must be provided",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsLoading(false);
      return;
    }
    window.location = "/representative";
  };

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>

      <div className="flex justify-center items-center pt-8 text-center px-4 bg-gray-100">
        <div>
          <p className="mt-5 text-gray-800 text-2xl mb-4 font-bold">
            Confirm Details
          </p>
          {/* <div>Phone confirmation</div> */}
          <div
            className={`${isCurrentPhoneNumber === "no" ? "hidden" : "block"}`}
          >
            <label htmlFor="token" className="block text-gray-900 mt-3 text-lg">
              Is this the current Official Phone Number{" "}
              <span className="text-red-600">
                {mask_number(userDetails.pulledPhone)} ?
              </span>
              <div
                onChange={(e) => {
                  setIsCurrentPhoneNumber(e.target.value);
                }}
                className="ml-5 text-center"
              >
                <label>
                  <input
                    type="radio"
                    className="mr-2"
                    name="current_number"
                    value="yes"
                    required
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    className="ml-8 mr-2 mb-4"
                    name="current_number"
                    value="no"
                    required
                  />
                  No
                </label>
              </div>
            </label>
          </div>
          {isCurrentPhoneNumber === "no" && (
            <>
              <label
                htmlFor="token"
                className=" text-left block text-gray-900 mt-3 text-lg"
              >
                Current Phone Number{" "}
              </label>
              <div className="flex">
                <div className="flex-1">
                  <input
                    name="r_phone"
                    type="text"
                    {...register("r_phone", { required: true })}
                    onChange={(e) => {
                      setUserInfo({ ...userDetails, phone: e.target.value });
                    }}
                    className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                  />
                </div>
                <div
                  className="pt-1 hover:bg-black px-1 my-2 ml-1 cursor-pointer bg-blue-500 rounded text-gray-100"
                  onClick={() => {
                    setIsCurrentPhoneNumber("");
                  }}
                >
                  Back
                </div>
              </div>
            </>
          )}

          {/* <div>Email Confirmation</div> */}
          {isValidEmail === "no"}
          <div className={`${isValidEmail === "no" ? "hidden" : "block"}`}>
            <label
              htmlFor="token"
              className="block text-gray-900 mt-3 text-center text-lg"
            >
              Is this the Official Email Address{" "}
              <span className="text-red-600 lowercase">
                {userDetails.pulledEmail}
              </span>
              <div
                onChange={(e) => {
                  setIsValidEmail(e.target.value);
                }}
                className="ml-5 text-center"
              >
                <label>
                  <input
                    type="radio"
                    className="mr-2"
                    name="valid_email"
                    value="yes"
                    required={true}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    className="ml-8 mr-2 mb-4"
                    name="valid_email"
                    value="no"
                    required={true}
                  />
                  No
                </label>
              </div>
            </label>
          </div>

          {isValidEmail === "no" && (
            <>
              <label
                htmlFor="token"
                className="block text-gray-900 mt-3 text-left text-lg"
              >
                Valid Email Address<span className="text-red-600">{" *"}</span>
              </label>
              <div className="flex">
                <div className="flex-1">
                  <input
                    name="r_email"
                    type="email"
                    {...register("r_email", { required: true })}
                    aria-invalid={errors.r_email ? "true" : "false"}
                    onChange={(e) =>
                      setUserInfo({
                        ...userDetails,
                        email: e.target.value,
                      })
                    }
                    className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                  />
                </div>
                <div
                  className="pt-1 hover:bg-black px-1 my-2 text-left ml-1 cursor-pointer bg-blue-500 rounded text-gray-100"
                  onClick={() => setIsValidEmail("")}
                >
                  Back
                </div>
              </div>

              {errors.r_email?.type === "required" && (
                <p role="alert" className="text-sm text-red-500">
                  Email is required
                </p>
              )}
            </>
          )}

          {/* <div>Address confirmation</div> */}
          <div className={`${isValidAddress === "no" ? "hidden" : "block"}`}>
            <label htmlFor="token" className="block text-gray-900 mt-3 text-lg">
              Is this the current Office Address{" "}
              <span className="text-red-600">
                {userDetails.registeredAddress || "-----------"} ?
              </span>
              <div
                onChange={(e) => {
                  setIsValidAddress(e.target.value);
                }}
                className="ml-5 text-center"
              >
                <label>
                  <input
                    type="radio"
                    className="mr-2"
                    name="current_address"
                    value="yes"
                    required
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    className="ml-8 mr-2 mb-4"
                    name="current_address"
                    value="no"
                    required
                  />
                  No
                </label>
              </div>
            </label>
          </div>
          {isValidAddress === "no" && (
            <>
              <label
                htmlFor="token"
                className=" text-left block text-gray-900 mt-3 text-lg"
              >
                Current Address{" "}
              </label>
              <div className="flex">
                <div className="flex-1">
                  <input
                    name="r_address"
                    type="text"
                    {...register("r_address", { required: true })}
                    onChange={(e) => {
                      setUserInfo({ ...userDetails, address: e.target.value });
                    }}
                    className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                  />
                </div>
                <div
                  className="pt-1 hover:bg-black px-1 my-2 ml-1 cursor-pointer bg-blue-500 rounded text-gray-100"
                  onClick={() => {
                    setIsValidAddress("");
                  }}
                >
                  Back
                </div>
              </div>
            </>
          )}

          <button
            className="bg-blue-500 text-lg mt-4 w-full py-2 rounded-lg text-gray-100"
            onClick={handleSubmit(onConfirmPhoneNumber)}
          >
            {!isLoading && "Confirm"}
            {isLoading && <Loader />}
          </button>
        </div>
      </div>
    </div>
  );
}
