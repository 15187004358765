export const dateMonthYear = (date) => {
  const newDate = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return newDate.toLocaleString("en-US", options);
};

export const formatDateNew = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  // return [year, month, day].join("-");
  return [day, month, year].join("-");
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateTime = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-") + " " + hours + ":" + minutes;
};

export const addOneYearToCurrentDate = () => {
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();
  var plusOneYear = new Date(year + 1, month, day);
  return plusOneYear;
};
// export default { formatDate, formatDateTime };
