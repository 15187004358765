import { create } from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  vehicleDetails: {
    id: "",
    licencePlate: "",
    chassisNumber: "",
    engineNumber: "",
    model: "",
    manufacturer: "",
    year: "",
    vehicleType: "",
    color: "",
    vehicleUsage: "",
    vehicleStatus: "",
    mla: "",
    mlaAddress: "",
    sourceOfVehicle: "",
    requestState: "Abuja",
  },

  setVehicleDetails: (vehicle_details) =>
    set(
      produce((temp) => {
        //logic here
        temp.vehicleDetails = {
          id: vehicle_details.id,
          ownerId: vehicle_details.ownerId,
          licencePlate: vehicle_details.licencePlate,
          chassisNumber: vehicle_details.chassisNumber,
          engineNumber: vehicle_details.engineNumber,
          model: vehicle_details.model,
          year: vehicle_details.year,
          vehicleType: vehicle_details.vehicleType,
          color: vehicle_details.color,
          vehicleUsage: vehicle_details.vehicleUsage,
          vehicleStatus: vehicle_details.vehicleStatus,
          manufacturer: vehicle_details.manufacturer,
          mla: vehicle_details.mla,
          mlaAddress: vehicle_details.mlaAddress,
          sourceOfVehicle: vehicle_details.sourceOfVehicle,
          requestState: vehicle_details.requestState,
          registered_by: "",
          agent_Id: "",
        };
      })
    ),

  reset: (vehicleDetails) => set(vehicleDetails),
});
// store = devtools(store);
store = persist(store, { name: "vehicleDetails" });
export const useVehicleDetails = create(store);
