import React from "react";
import OwnerInfo from "../components/Owner";

export default function Owner() {
  return (
    <>
      <OwnerInfo />
    </>
  );
}
