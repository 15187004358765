import { create } from "zustand"
import produce from "immer";
import { persist } from "zustand/middleware";
// import devtools from "zustand/middleware";

let store = (set) => ({
  product: "",
  selectProduct: (id) =>
    set(
      produce((temp) => {
        temp.product = id;
      })
    ),
  reset: (product) => set(product),
});
// store = devtools(store);
store = persist(store, { name: "productSelected" });
export const useProductStore = create(store);
