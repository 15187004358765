import { Link, useParams } from "react-router-dom";
import { base, get_request_details } from "../constants/urls";
import React, { useEffect, useState } from "react";
import { useAuth } from "../store/Auth";
import Swal from "sweetalert2";
import { RiPrinterLine } from "react-icons/ri";
import { formatDateNew } from "../utils/date_format";

export default function Request() {
  const getAuth = useAuth((state) => state.auth);
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const { reference } = useParams();

  useEffect(() => {
    let token = getAuth.token;
    let url = base + get_request_details + `/${reference}`;
    // console.log(url);
    const fetchRequest = async () => {
      setLoading(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          setRequest(data);
          setLoading(false);
          console.log(data);
        })
        .catch((err) => {
          let message;

          if (err && err.response) {
            const { data } = err.response;
            message = data.message;
          }

          Swal.fire(message || "Unable to fetch Details");
          setLoading(false);
        });
    };
    fetchRequest();
  }, []);
  return (
    <div className="bg-gray-100 mb-10">
      <div className="text-2xl pt-4 pb-4 flex justify-center print:hidden bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="max-w-5xl mx-auto mt-4 shadow-lg px-8 pt-10 pb-4 bg-white">
        <div className="mb-8 hidden print:block">
          <img src="/logodark.png" alt="Police Logo" className="h-100 w-100" />
        </div>
        <p className="text-xl border-b pb-2 mb-4 font-bold">
          {request.request_status === "Approved" &&
          request.license_plate === "" ? (
            <div className="flex flex-col">
              <div className="print:hidden">
                <img
                  src="/logodark.png"
                  alt="Police Logo"
                  className="h-100 w-100"
                />
              </div>
              <div className="mt-5 xl:text-3xl md:text-2xl text-md font-normal">
                NOTIFICATION - Clearance For New Motor Vehicle Registration
              </div>
            </div>
          ) : (
            "View Request Information"
          )}
        </p>
        <dl>
          <div className="mb-6 border-b print:border-none pb-4">
            <h3 className="text-md py-2 bg-blue-200 px-2 font-semibold">
              Owner's Details
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 print:grid-cols-3 gap-4 py-4 px-4">
              <div class="flex space-x-3 items-center">
                <dt class="">Last Name:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.last_name}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">First Name:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.first_name}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Other Names:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.middle_name}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Profile Type:</dt>
                <dd class="text-md text-gray-900 font-bold ">
                  {request?.profile_type === "individual"
                    ? "Personal"
                    : request?.profile_type}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Gender:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.gender}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Email:</dt>
                <dd class="text-sm md:text-md text-gray-900 break-all  font-bold">
                  {request?.email}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Phone:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.phone}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">State:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.state}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">LGA:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.local_govt}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Address:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.address}
                </dd>
              </div>
            </div>
            <h3 className="text-md py-2 font-semibold px-2 bg-blue-200">
              Vehicle Details
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 print:grid-cols-3 gap-4 py-4 px-4">
              <div class="flex space-x-3 items-center">
                <dt class="">Licence Number:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.license_plate}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Chassis No:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.chassis_number}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Engine No:</dt>
                <dd class="text-gray-900 font-bold">
                  {request?.engine_number}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Make:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.make}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Model:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.model}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Type:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.vehicle_type}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Year:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.year}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Color:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.color}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Vehicle Usage:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.vehicle_usage}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Vehicle Source:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.source_of_vehicle}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Licence State:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.license_state}
                </dd>
              </div>
            </div>
            <h3 className="text-md py-2 bg-blue-200 px-2 font-semibold">
              Request Details
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 print:grid-cols-3 gap-4 py-4 px-4">
              <div class="flex space-x-3 items-center">
                <dt class="">Request Date:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {formatDateNew(request.date)}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Request No:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.request_number}
                </dd>
              </div>
              <div class="flex space-x-3 items-center">
                <dt class="">Request Status:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.request_status}
                </dd>
              </div>
              {/* <div class="flex space-x-3 items-center">
                <dt class="">
                  Receipt No:
                </dt>
                <dd class="text-md text-gray-900 font-bold">
                  {request?.receipt_number}
                </dd>
              </div> */}
              <div class="flex space-x-3 items-center">
                <dt class="">State Requesting From:</dt>
                <dd class="text-sm md:text-md text-gray-900 font-bold">
                  {request?.state_requesting_from}
                </dd>
              </div>
            </div>
            <hr
              className={`${
                request.payment_status === "Paid" &&
                request.license_plate === ""
                  ? "block"
                  : "hidden"
              }`}
            />
            <p
              className={`${
                request.payment_status === "Paid" &&
                request.license_plate === ""
                  ? "block"
                  : "hidden"
              } mt-5 px-2 lg:px-4 font-bold text-xl`}
            >
              The processing of your request for Motor Vehicle Information
              Certificate is ongoing. You are requested to proceed to the Motor
              Vehicle Licencing Authority with this Notification and obtain the
              Licence Plate Number to enable the Police complete the process and
              issue the Certificate.
            </p>
          </div>
          <div className="flex justify-center space-x-3">
            <Link
              className="print:hidden flex text-md justify-center"
              to="/requests"
            >
              <button className="flex print:hidden items-center mt-4 justify-center border px-4 py-1 text-white font-semibold border-blue-500 rounded bg-blue-500">
                Back
              </button>
            </Link>
            <button
              className="flex print:hidden items-center mt-4 justify-center space-x-1 border px-3 py-1 rounded border-red-500"
              onClick={() => {
                window.print();
              }}
            >
              <RiPrinterLine />
              <p>Print</p>
            </button>
          </div>
        </dl>
      </div>
    </div>
  );
}
