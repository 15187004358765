import React, { useState, useEffect } from "react";
import { useAuth } from "../store/Auth";
import Loader from "./Loader";
import { useProductStore } from "../store/ProductStore";
import { useFormStep } from "../store/FormStepStore";
import { base, services_pull } from "../constants/urls";
import Swal from "sweetalert2";
import { useAgent } from "../store/AgentStore";
import { useUserStore } from "../store/UserStore";

export default function ServiceSelection() {
  const userDetails = useUserStore((state) => state.userDetails);
  const step = useFormStep((state) => state.step);
  const setStep = useFormStep((state) => state.setStep);
  const [subServices, setSubServices] = useState([]);
  const getAuth = useAuth((state) => state.auth);
  const getProduct = useProductStore((state) => state.product);
  const selectProduct = useProductStore((state) => state.selectProduct);
  const [loading, setIsloading] = useState(false);
  const resetProduct = useProductStore((state) => state.reset);
  const [subProducts, setSubProducts] = useState([]);
  const [category, setCategory] = useState("");
  const [service, setService] = useState("");
  const setAgent = useAgent((state) => state?.SetAgentData);
  const agentData = useAgent((state) => state.agentData);
  // const resetAgentData = useAgent((state) => state.reset);

  const selectVehicleNo = (value) => {
    localStorage.setItem("vehicleCount", value);
  };

  useEffect(() => {
    localStorage.removeItem("vehicleCount");
    localStorage.removeItem("agent");
  }, []);

  const getProductsByCategory = (category) => {
    let url = base + services_pull + category;
    let token = getAuth.token;
    // resetAgentData();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        if (resp.statusCode === 0) {
          setSubProducts(resp.data);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Error getting requests",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      });
  };

  const onContinue = (e) => {
    setIsloading(true);
    // setHandler(e);
    // setAgentId(e);
    e.preventDefault();
    if (
      category === "" ||
      category === undefined ||
      service === "" ||
      service === undefined
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please select a request to continue",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsloading(false);
      return;
    }
    if (
      localStorage.getItem("vehicleCount") === null &&
      service === "cmrinfo1"
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please select a mode to continue",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsloading(false);
      return;
    }

    // if (agentData.agent == "" || agentData.agent == undefined) {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "Please select request by",
    //     icon: "error",
    //     confirmButtonText: "Ok",
    //     confirmButtonColor: "#1C89DE",
    //   });
    //   setIsloading(false);
    //   return;
    // }
    setIsloading(true);

    setStep(5);

    switch (getProduct) {
      case "cmrinfo1":
        window.location = "/owner";
        break;
      case "cow":
      case "cop":
      case "coch":
      case "coc":
      case "tint":
        window.location = "/agentvehiclesearch";
        break;
      // case "idp":
      //   if (userDetails.profileType == "individual") {
      //     window.location = "/idp";
      //   } else {
      //     Swal.fire({
      //       title: "Error!",
      //       text: "This request is not available for Organization",
      //       icon: "error",
      //       confirmButtonText: "Ok",
      //       confirmButtonColor: "#1C89DE",
      //     });
      //   }
      //   break;
      default:
        setIsloading(true);
    }
    setIsloading(false);
  };

  // if (getAuth.authenticated === false && getAuth.token === "") {
  //   window.location = "/signup";
  //   return;
  // }

  if (
    getAuth.token === undefined ||
    getAuth.token === "" ||
    getAuth.authenticated === false
  ) {
    window.location = "/login";
    return;
  }

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="lg:pt-8 pt-4 bg-gray-200 h-screen">
        <div className="max-w-lg mx-auto items-center">
          <form className="px-10 mx-4 py-12 rounded-lg shadow-lg backdrop-blur-xl bg-white/50">
            <div className="text-center text-xl pb-2 font-bold border-b-2">
              Choose CMR Request
            </div>
            <label
              htmlFor="services"
              className="block text-gray-900 text-md mt-6"
            >
              CMR Request <span className="text-red-500 text-lg"> *</span>
            </label>
            <select
              // name="r_id_type"
              onChange={(e) => {
                getProductsByCategory(e.target.value);
                setCategory(e.target.value);
              }}
              className="w-full mb-4 bg-white rounded-lg text-sm mt-1 leading-loose hover:border-green-800 py-2 px-2 border border-gray-400"
            >
              <option value="">Select a Request</option>
              <option value="cmris">CMR Information</option>
              <option value="others">Other Requests</option>
            </select>

            <label
              htmlFor="sub_services"
              className="block text-gray-900 text-md mt-3"
            >
              CMR Sub Request
              <span className="text-red-500 text-lg"> *</span>
            </label>
            <select
              onChange={(e) => {
                selectProduct(e.target.value);
                setService(e.target.value);
              }}
              required={true}
              className="w-full mb-4 bg-white rounded-lg text-sm mt-1 leading-loose hover:border-green-800 py-2 px-2 border border-gray-400"
            >
              <option value="">Select Sub Request</option>
              {subProducts?.map((obj, index) => {
                return (
                  <option key={index} value={obj.short_name}>
                    {obj.name}
                  </option>
                );
              })}
            </select>
            <div className={`${service !== "cmrinfo1" && "hidden"}`}>
              <label>
                Select Number of Request
                <span className="text-red-500 text-lg"> *</span>
              </label>
              <select
                onChange={(e) => {
                  selectVehicleNo(e.target.value);
                }}
                className="w-full mb-4 bg-white rounded-lg text-sm mt-1 leading-loose hover:border-green-800 py-2 px-2 border border-gray-400"
              >
                <option value="">Select an option</option>
                <option value="single">Single</option>
                <option value="multiple">Fleet</option>
              </select>
            </div>
            {/* <div>
              <label
                className="block text-gray-900 text-md mt-2"
                htmlFor="registered_by"
              >
                Request By
                <span className="text-red-500 text-lg font-semibold"> *</span>
              </label>
              <label className="text-sm">
                <input
                  onChange={(e) => {
                    setAgent({ ...agentData, agent: e.target.value });
                  }}
                  type="radio"
                  className="mr-2 mb-2"
                  name="registered_by"
                  value="self"
                  required={true}
                />
                Self
              </label>
              <label className="text-sm">
                <input
                  onChange={(e) => {
                    setAgent({ ...agentData, agent: e.target.value });
                  }}
                  type="radio"
                  className="ml-8 mr-2 mb-2"
                  name="registered_by"
                  value="agent"
                  required={true}
                />
                Agent
              </label>
              {agentData.agent === "agent" ? (
                <>
                  <label
                    htmlFor="typeOfId"
                    className="block text-gray-900 text-md mt-2"
                  >
                    Agent ID
                  </label>
                  <input
                    onChange={(e) => {
                      setAgent({ ...agentData, id: e.target.value });
                    }}
                    type="text"
                    // placeholder="Agent ID"
                    name="agent_id"
                    defaultValue=""
                    required={true}
                    className="w-full mb-2 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                  />
                </>
              ) : null}
            </div> */}
            <button
              onClick={(e) => {
                onContinue(e);
              }}
              className="bg-blue-500 w-full py-2 font-bold mt-8 rounded-lg text-gray-100 text-md"
            >
              {!loading && "Continue"}
              {loading && <Loader />}
            </button>
          </form>
        </div>
        <div className={`xl:flex-initial xl:w-2/6`}></div>
      </div>
    </div>
  );
}
