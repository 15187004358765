import React from "react";
import Vehicle from "../components/Vehicle";

export default function InputVehicleDetails() {
  return (
    <>
      <Vehicle />
    </>
  );
}
