import React, { useState } from "react";
import { useModal } from "../store/ModalState";
import { useUserStore } from "../store/UserStore";
import { useVehicleDetails } from "../store/VehicleStore";
import { useFormStep } from "../store/FormStepStore";
import Loader from "./Loader";
import { useAuth } from "../store/Auth";
import { base, cmr_pre_entry, get_product_details } from "../constants/urls";
import { useProductStore } from "../store/ProductStore";
import { generate_trans_ref } from "../utils/generate_trans_ref";
import { useInvoiceStore } from "../store/InvoiceStore";
import { RiPrinterLine } from "react-icons/ri";
import Swal from "sweetalert2";
import {
  formatDate,
  formatDateTime,
  addOneYearToCurrentDate,
} from "../utils/date_format";
import { useOwnerStore } from "../store/OwnerStore";

export default function VehicleInformationPreviewModal({ title }) {
  const showModal = useModal((state) => state.showModal);
  const setShowModal = useModal((state) => state.setShowModal);
  const owner = useOwnerStore((state) => state.ownerDetails);
  const vehicle = useVehicleDetails((state) => state.vehicleDetails);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const setStep = useFormStep((state) => state.setStep);
  const step = useFormStep((state) => state.step);
  const [isLoading, setIsLoading] = useState(false);
  const getAuth = useAuth((state) => state.auth);
  const getProduct = useProductStore((state) => state.product);
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);

  const submit = () => {
    setIsLoading(true);
    //get product details
    let prodUrl = base + get_product_details + getProduct;
    let token = getAuth.token;
    fetch(prodUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        let respData = resp.data;
        // console.log(respData);

        let referenceNumber =
          respData.product_code + generate_trans_ref().toString();

        let data = {
          user: {
            first_name: owner.firstName,
            middle_name: owner.middleName,
            last_name: owner.lastName,
            profile_type: owner.profileType,
            gender: owner.gender,
            email: owner.email,
            pulled_email: owner.pulledEmail,
            pulled_phone: owner.pulledPhone,
            current_state: owner.currentState,
            current_lg: owner.currentLG,
            phone: owner.phone,
            alternate_phone: "",
            state: owner.currentState,
            lga: owner.currentLG,
            pulled_address: owner.pulledAddress,
            current_address: owner.address,
            id_type: owner.idType,
            id_number: owner.idNumber,
            status: "Not Verified",
            password: owner.phone,
            confirm_password: owner.phone,
          },
          owner: {
            first_name: owner.firstName,
            middle_name: owner.middleName,
            last_name: owner.lastName,
            type: owner.profileType,
            gender: owner.gender,
            email: owner.email,
            phone: owner.phone,
            alternate_phone: "",
            state: owner.currentState,
            lga: owner.currentLG,
            registered_address: owner.pulledAddress,
            present_address: owner.address,
            id_type: owner.idType,
            id_number: owner.idNumber,
            status: "Not Verified",
          },
          vehicle: {
            license_plate: vehicle.licencePlate,
            chassis_number: vehicle.chassisNumber,
            engine_number: vehicle.engineNumber || "NA",
            manufacturer: vehicle.manufacturer,
            model: vehicle.model,
            year: vehicle.year,
            vehicle_type: vehicle.vehicleType,
            color: vehicle.color,
            vehicle_usage: vehicle.vehicleUsage,
            vehicle_status: "Valid",
            mla: vehicle.mla,
            mla_address: vehicle.mlaAddress,
            vehicle_source: vehicle.sourceOfVehicle,
          },
          pre_cmr: {
            reference: referenceNumber,
            entry_date: formatDate(new Date()),
            expiry_date: formatDate(addOneYearToCurrentDate()),
            amount: respData.price,
            payment_status: "Unpaid",
            status: "Pending",
            request_state: vehicle.requestState,
          },
        };
        let url = base + cmr_pre_entry;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.json();
          })
          .then((resp) => {
            // console.log(resp);
            if (resp.data === "vehicle exists") {
              Swal.fire({
                title: "Vehicle already registered by you",
                text: " ",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1C89DE",
              }).then(() => {
                window.location = "/requests";
              });
              return;
            }
            let responseData = resp.data;
            let invoice = responseData.invoice;
            setInvoice({
              firstName: invoice.first_name,
              middleName: invoice.middle_name,
              lastName: invoice.last_name,
              address: invoice.address,
              email: invoice.email,
              phone: invoice.phone,
              narration: invoice.narration,
              amount: invoice.amount,
              transactionId: invoice.trans_ref,
              invoiceNumber: invoice.invoice_number,
              initiatedDate: formatDate(invoice.initiated_date),
              status: invoice.status,
              productCode: invoice.product_code,
              rrr: invoice.rrr,
              vin: invoice.vin,
              make: invoice.make,
              licenceNumber: invoice.license,
            });
            setStep(6);
            // console.log(invoice.amount + ".00");
            window.location = "/invoice";
          });
      });
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="print:text-sm backdrop-blur-xl bg-white/30  justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-1 xl:my-6 mx-auto xl:w-2/5 w-full">
              {/*content*/}
              <div className="px-1 xl:px-20 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div className="flex">
                    <div className="flex-none w-16 pt-2">
                      <img
                        src="/logo.png"
                        alt="Police Logo"
                        className="h-16 w-14"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-2xl font-semibold text-left mt-3">
                        Nigeria Police Force
                      </h3>
                      <h3 className="text-xl font-semibold text-left">
                        Central Motor Registry
                      </h3>
                    </div>
                  </div>

                  {/* <h3 className="xl:text-lg text-xl font-bold text-left mt-3">
                    {title}
                  </h3> */}
                </div>
                <div className="relative px-4 flex-auto mb-4">
                  <p className="text-slate-900 font-semibold mb-2 text-lg">
                    Applicant Details
                  </p>
                  <div className="flex text-slate-900">
                    <div className="flex w-24">Name</div>
                    <div className="flex-1 font-bold">
                      {owner.firstName} {owner.middleName} {owner.lastName}
                    </div>
                  </div>

                  <div className="flex text-slate-900">
                    <div className="flex w-24">Address</div>
                    <div className="flex-1 font-bold">{owner.address}</div>
                  </div>
                </div>

                <hr />

                <div className="xl:leading-loose relative px-4 flex-auto">
                  <p className="font-semibold text-slate-900 text-lg leading-relaxed">
                    Vehicle Details
                  </p>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Licence Number</div>
                    <div className="flex-1 font-bold">
                      {vehicle.licencePlate || "Not Yet Issued"}
                    </div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Chassis Number</div>
                    <div className="flex-1 font-bold">
                      {vehicle.chassisNumber}
                    </div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Engine Number</div>
                    <div className="flex-1 font-bold">
                      {vehicle.engineNumber || "NA"}
                    </div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Vehicle Make</div>
                    <div className="flex-1 font-bold">
                      {vehicle.manufacturer}
                    </div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Vehicle Model</div>
                    <div className="flex-1 font-bold">{vehicle.model}</div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Vehicle Type</div>
                    <div className="flex-1 font-bold">
                      {vehicle.vehicleType}
                    </div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Year of Manufacture</div>
                    <div className="flex-1 font-bold">{vehicle.year}</div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Color</div>
                    <div className="flex-1 font-bold">{vehicle.color}</div>
                  </div>
                  <div className="flex text-slate-900">
                    <div className="flex-1">Usage</div>
                    <div className="flex-1 font-bold">
                      {vehicle.vehicleUsage}
                    </div>
                  </div>

                  <div className="flex text-slate-900">
                    <div className="flex-1">MVLA</div>
                    <div className="flex-1 font-bold">{vehicle.mla}</div>
                  </div>

                  <div className="flex text-slate-900">
                    <div className="flex-1">Request State</div>
                    <div className="flex-1 font-bold">
                      {vehicle.requestState}
                    </div>
                  </div>

                  <div className="print:hidden text-slate-900 mt-3 pb-2 border-b border-solid border-slate-200">
                    <div className="text-red-600 text-justify mb-2 font-sans font-bold text-xs">
                      Ensure that the above information is accurate before
                      confirming and proceeding to make payment. The payment is
                      non-refundable.
                    </div>
                    {/* <label> */}
                    <input
                      type="checkbox"
                      name="confirmation"
                      value={true}
                      onChange={(e) => {
                        setIsConfirmed(e.target.checked);
                      }}
                    />{" "}
                    <span className="text-sm text-justify">
                      I hereby confirm that the above information is accurate
                      and genuine.
                    </span>
                    {/* </label> */}
                  </div>
                </div>

                {/*footer*/}
                <div className="flex mt-4 items-center justify-end px-6 pb-6  rounded-b">
                  <div className="flex-1">
                    <button
                      className={`bg-blue-500 cursor-pointer rounded h-8 text-center w-24 text-gray-100 px-3 py-1`}
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Back
                    </button>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      className="flex space-x-2 items-center border px-3 py-1 rounded border-red-500"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <RiPrinterLine />
                      <p>Print</p>
                    </button>
                    <button
                      className={`${
                        isConfirmed === false
                          ? "bg-gray-300"
                          : "bg-blue-500 cursor-pointer"
                      }  rounded text-center w-32 text-gray-100 px-3 py-1 border border-gray-200`}
                      type="button"
                      disabled={isConfirmed === false ? true : false}
                      onClick={() => submit()}
                    >
                      {!isLoading && "Proceed"} {isLoading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
