import React from "react";
import Table from "./table/Table";
import { useAuth } from "../store/Auth";

export default function UserCMRRequestsList() {
  const getAuth = useAuth((state) => state.auth);

  if (
    getAuth.authenticated !== true ||
    getAuth.token === "" ||
    getAuth.token === ""
  ) {
    window.location = "/login";
  }

  return (
    <div>
      <Table />
      {/* <Table columns={columns} data={data}/> */}

      {/* <Table /> */}
    </div>
  );
}
